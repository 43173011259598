import { DateCalendar } from "@mui/x-date-pickers";
import { useBookingFormStateContext } from "./BookOfferingState";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { getBookingServiceProxy } from "../services/BookingServiceProxy";
import { useSocketIO } from "../components/SocketIO";
import { useVendorScope } from "../components/VendorScopeProvider";
import { Box, Skeleton } from "@mui/material";
import { BookingFormSelectSlot } from "./BookingFormSelectSlot";
import { offering } from "@anything-pet/grpc-lib";

export function BookingFormSelectDate(
    props: { offering: offering.Offering }
) {
    const { id : vendorId } = useVendorScope();
    const context = useBookingFormStateContext();
    const socket = useSocketIO();

    const today = dayjs();
    const [currentMonth, setCurrentMonth] = useState(today);
    const [availableDays, setAvailableDays] = useState<{
        [k: string]: boolean | undefined;
    }>();

    useEffect(() => {
        (async () => {
            const startDate = currentMonth.startOf("month");
            const endDate = dayjs(startDate).endOf("month").toDate();

            const availableDaysValue: { [k: string]: boolean } = {};

            const service = getBookingServiceProxy(socket);

            const { day: availableDayEntries } =
                await service.getAvailableBookingDays({
                    vendorId: vendorId,
                    startDateTime: startDate.toISOString(),
                    endDateTime: endDate.toISOString(),
                    offeringId: context.offeringId,
                });

            for (const entry of availableDayEntries) {
                availableDaysValue[entry.date] = entry.isAvailable;
            }

            setAvailableDays(availableDaysValue);
        })();
    }, [currentMonth]);

    if (!availableDays) {
        return (
            <>
                <Box padding={"15px"}>
                    <Skeleton variant="rectangular" height={380} />
                </Box>
            </>
        );
    }

    if (context.state.bookingDate) {
        //  Check if date is available
        const bookingDateDayJs = dayjs(context.state.bookingDate);

        const bookingDateValue = bookingDateDayJs.format('YYYY-MM-DD');

        const available = availableDays[bookingDateValue];

        if (available) {
            //  Move on to next step
            return <BookingFormSelectSlot offering={props.offering} />
        } else {
            //  Date is invalid.  Fix the url
            context.state.bookingDate = undefined;
            context.navigateToState();
        }
    }

    const minDate = today;
    const maxDate = minDate.add(3, "month");

    const shouldDisableDate = (date: Dayjs) => {
        const formattedDate = date.format("YYYY-MM-DD");
        return availableDays ? !availableDays[formattedDate] : false;
    };

    const onMonthChange = (date: Dayjs | null) => {
        if (date) {
            setCurrentMonth(date);
        }
    };

    const onSelectDate = (selectedDate : Dayjs) => {
        context.state.bookingDate = selectedDate;
        context.navigateToState();
    }
 
    return (
        <DateCalendar
            onChange={onSelectDate}
            onMonthChange={onMonthChange}
            value={context.state.bookingDate}
            minDate={minDate}
            maxDate={maxDate}
            shouldDisableDate={shouldDisableDate}
        />
    )
}