import { useAuthManager } from "../components/Auth";
import { LinearProgress } from "@mui/material";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";

/**
 * 
 * @returns 
 */
export default function LogoutPage() {
    const authService = useAuthManager();
    const isLoading = (! authService);

    useEffect(() => {
        (async () => {
            if (! isLoading) {
                if (authService.isAuthenticated) {
                    try {
                        await authService.logout();
                    } catch (err) {
                        console.log(err);
                    }
                }
            }
        })();
    }, []);

    if (isLoading || authService.isAuthenticated) {
        return <LinearProgress />;
    } else {
        return <Navigate to='/' />
    }
}