/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "anythingpet.vendorAdmin";

export interface OfficeDayHours {
  startTime: string;
  endTime: string;
}

export interface OfficeHours {
  sunday: OfficeDayHours | undefined;
  monday: OfficeDayHours | undefined;
  tuesday: OfficeDayHours | undefined;
  wednesday: OfficeDayHours | undefined;
  thursday: OfficeDayHours | undefined;
  friday: OfficeDayHours | undefined;
  saturday: OfficeDayHours | undefined;
}

export interface VendorImages {
  name: string;
  url: string;
}

export interface VendorAdmin {
  id: string;
  name: string;
  code: string;
  officeHours: OfficeHours | undefined;
  timeZone: string;
  location: string;
  address: string;
  about: string;
  additionalInfo: string;
  googleMapSrc: string;
  bookingCancellationPolicy: string;
  additionalBookingNotes: string;
  images: VendorImages[];
}

export interface VendorAdminForUpdate {
  id: string;
  name?: string | undefined;
  code?: string | undefined;
  officeHours?: OfficeHours | undefined;
  timeZone?: string | undefined;
  location?: string | undefined;
  address?: string | undefined;
  about?: string | undefined;
  additionalInfo?: string | undefined;
  googleMapSrc?: string | undefined;
  bookingCancellationPolicy?: string | undefined;
  additionalBookingNotes?: string | undefined;
  images: VendorImages[];
}

export interface CheckAccessRequest {
  vendorId: string;
  emailAddress: string;
}

export interface CheckAccessResponse {
  access: string;
}

export interface CreateVendorAdminRequest {
  vendor: VendorAdmin | undefined;
}

export interface CreateVendorAdminResponse {
  vendor: VendorAdmin | undefined;
}

export interface GetVendorAdminsRequest {
}

export interface GetVendorAdminsResponse {
  vendor: VendorAdmin[];
}

export interface GetVendorAdminRequest {
  id: string;
}

export interface GetVendorAdminResponse {
  vendor: VendorAdmin | undefined;
}

export interface UpdateVendorAdminRequest {
  vendor: VendorAdminForUpdate | undefined;
}

export interface UpdateVendorAdminResponse {
  status: string;
}

function createBaseOfficeDayHours(): OfficeDayHours {
  return { startTime: "", endTime: "" };
}

export const OfficeDayHours = {
  encode(message: OfficeDayHours, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.startTime !== "") {
      writer.uint32(10).string(message.startTime);
    }
    if (message.endTime !== "") {
      writer.uint32(18).string(message.endTime);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OfficeDayHours {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOfficeDayHours();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.startTime = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.endTime = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OfficeDayHours {
    return {
      startTime: isSet(object.startTime) ? globalThis.String(object.startTime) : "",
      endTime: isSet(object.endTime) ? globalThis.String(object.endTime) : "",
    };
  },

  toJSON(message: OfficeDayHours): unknown {
    const obj: any = {};
    if (message.startTime !== "") {
      obj.startTime = message.startTime;
    }
    if (message.endTime !== "") {
      obj.endTime = message.endTime;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OfficeDayHours>, I>>(base?: I): OfficeDayHours {
    return OfficeDayHours.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OfficeDayHours>, I>>(object: I): OfficeDayHours {
    const message = createBaseOfficeDayHours();
    message.startTime = object.startTime ?? "";
    message.endTime = object.endTime ?? "";
    return message;
  },
};

function createBaseOfficeHours(): OfficeHours {
  return {
    sunday: undefined,
    monday: undefined,
    tuesday: undefined,
    wednesday: undefined,
    thursday: undefined,
    friday: undefined,
    saturday: undefined,
  };
}

export const OfficeHours = {
  encode(message: OfficeHours, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.sunday !== undefined) {
      OfficeDayHours.encode(message.sunday, writer.uint32(10).fork()).ldelim();
    }
    if (message.monday !== undefined) {
      OfficeDayHours.encode(message.monday, writer.uint32(18).fork()).ldelim();
    }
    if (message.tuesday !== undefined) {
      OfficeDayHours.encode(message.tuesday, writer.uint32(26).fork()).ldelim();
    }
    if (message.wednesday !== undefined) {
      OfficeDayHours.encode(message.wednesday, writer.uint32(34).fork()).ldelim();
    }
    if (message.thursday !== undefined) {
      OfficeDayHours.encode(message.thursday, writer.uint32(42).fork()).ldelim();
    }
    if (message.friday !== undefined) {
      OfficeDayHours.encode(message.friday, writer.uint32(50).fork()).ldelim();
    }
    if (message.saturday !== undefined) {
      OfficeDayHours.encode(message.saturday, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OfficeHours {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOfficeHours();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.sunday = OfficeDayHours.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.monday = OfficeDayHours.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.tuesday = OfficeDayHours.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.wednesday = OfficeDayHours.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.thursday = OfficeDayHours.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.friday = OfficeDayHours.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.saturday = OfficeDayHours.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OfficeHours {
    return {
      sunday: isSet(object.sunday) ? OfficeDayHours.fromJSON(object.sunday) : undefined,
      monday: isSet(object.monday) ? OfficeDayHours.fromJSON(object.monday) : undefined,
      tuesday: isSet(object.tuesday) ? OfficeDayHours.fromJSON(object.tuesday) : undefined,
      wednesday: isSet(object.wednesday) ? OfficeDayHours.fromJSON(object.wednesday) : undefined,
      thursday: isSet(object.thursday) ? OfficeDayHours.fromJSON(object.thursday) : undefined,
      friday: isSet(object.friday) ? OfficeDayHours.fromJSON(object.friday) : undefined,
      saturday: isSet(object.saturday) ? OfficeDayHours.fromJSON(object.saturday) : undefined,
    };
  },

  toJSON(message: OfficeHours): unknown {
    const obj: any = {};
    if (message.sunday !== undefined) {
      obj.sunday = OfficeDayHours.toJSON(message.sunday);
    }
    if (message.monday !== undefined) {
      obj.monday = OfficeDayHours.toJSON(message.monday);
    }
    if (message.tuesday !== undefined) {
      obj.tuesday = OfficeDayHours.toJSON(message.tuesday);
    }
    if (message.wednesday !== undefined) {
      obj.wednesday = OfficeDayHours.toJSON(message.wednesday);
    }
    if (message.thursday !== undefined) {
      obj.thursday = OfficeDayHours.toJSON(message.thursday);
    }
    if (message.friday !== undefined) {
      obj.friday = OfficeDayHours.toJSON(message.friday);
    }
    if (message.saturday !== undefined) {
      obj.saturday = OfficeDayHours.toJSON(message.saturday);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OfficeHours>, I>>(base?: I): OfficeHours {
    return OfficeHours.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OfficeHours>, I>>(object: I): OfficeHours {
    const message = createBaseOfficeHours();
    message.sunday = (object.sunday !== undefined && object.sunday !== null)
      ? OfficeDayHours.fromPartial(object.sunday)
      : undefined;
    message.monday = (object.monday !== undefined && object.monday !== null)
      ? OfficeDayHours.fromPartial(object.monday)
      : undefined;
    message.tuesday = (object.tuesday !== undefined && object.tuesday !== null)
      ? OfficeDayHours.fromPartial(object.tuesday)
      : undefined;
    message.wednesday = (object.wednesday !== undefined && object.wednesday !== null)
      ? OfficeDayHours.fromPartial(object.wednesday)
      : undefined;
    message.thursday = (object.thursday !== undefined && object.thursday !== null)
      ? OfficeDayHours.fromPartial(object.thursday)
      : undefined;
    message.friday = (object.friday !== undefined && object.friday !== null)
      ? OfficeDayHours.fromPartial(object.friday)
      : undefined;
    message.saturday = (object.saturday !== undefined && object.saturday !== null)
      ? OfficeDayHours.fromPartial(object.saturday)
      : undefined;
    return message;
  },
};

function createBaseVendorImages(): VendorImages {
  return { name: "", url: "" };
}

export const VendorImages = {
  encode(message: VendorImages, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.url !== "") {
      writer.uint32(18).string(message.url);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VendorImages {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVendorImages();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.url = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VendorImages {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      url: isSet(object.url) ? globalThis.String(object.url) : "",
    };
  },

  toJSON(message: VendorImages): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.url !== "") {
      obj.url = message.url;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VendorImages>, I>>(base?: I): VendorImages {
    return VendorImages.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VendorImages>, I>>(object: I): VendorImages {
    const message = createBaseVendorImages();
    message.name = object.name ?? "";
    message.url = object.url ?? "";
    return message;
  },
};

function createBaseVendorAdmin(): VendorAdmin {
  return {
    id: "",
    name: "",
    code: "",
    officeHours: undefined,
    timeZone: "",
    location: "",
    address: "",
    about: "",
    additionalInfo: "",
    googleMapSrc: "",
    bookingCancellationPolicy: "",
    additionalBookingNotes: "",
    images: [],
  };
}

export const VendorAdmin = {
  encode(message: VendorAdmin, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.code !== "") {
      writer.uint32(26).string(message.code);
    }
    if (message.officeHours !== undefined) {
      OfficeHours.encode(message.officeHours, writer.uint32(34).fork()).ldelim();
    }
    if (message.timeZone !== "") {
      writer.uint32(42).string(message.timeZone);
    }
    if (message.location !== "") {
      writer.uint32(50).string(message.location);
    }
    if (message.address !== "") {
      writer.uint32(58).string(message.address);
    }
    if (message.about !== "") {
      writer.uint32(66).string(message.about);
    }
    if (message.additionalInfo !== "") {
      writer.uint32(74).string(message.additionalInfo);
    }
    if (message.googleMapSrc !== "") {
      writer.uint32(82).string(message.googleMapSrc);
    }
    if (message.bookingCancellationPolicy !== "") {
      writer.uint32(90).string(message.bookingCancellationPolicy);
    }
    if (message.additionalBookingNotes !== "") {
      writer.uint32(98).string(message.additionalBookingNotes);
    }
    for (const v of message.images) {
      VendorImages.encode(v!, writer.uint32(106).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VendorAdmin {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVendorAdmin();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.code = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.officeHours = OfficeHours.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.timeZone = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.location = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.address = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.about = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.additionalInfo = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.googleMapSrc = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.bookingCancellationPolicy = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.additionalBookingNotes = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.images.push(VendorImages.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VendorAdmin {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      code: isSet(object.code) ? globalThis.String(object.code) : "",
      officeHours: isSet(object.officeHours) ? OfficeHours.fromJSON(object.officeHours) : undefined,
      timeZone: isSet(object.timeZone) ? globalThis.String(object.timeZone) : "",
      location: isSet(object.location) ? globalThis.String(object.location) : "",
      address: isSet(object.address) ? globalThis.String(object.address) : "",
      about: isSet(object.about) ? globalThis.String(object.about) : "",
      additionalInfo: isSet(object.additionalInfo) ? globalThis.String(object.additionalInfo) : "",
      googleMapSrc: isSet(object.googleMapSrc) ? globalThis.String(object.googleMapSrc) : "",
      bookingCancellationPolicy: isSet(object.bookingCancellationPolicy)
        ? globalThis.String(object.bookingCancellationPolicy)
        : "",
      additionalBookingNotes: isSet(object.additionalBookingNotes)
        ? globalThis.String(object.additionalBookingNotes)
        : "",
      images: globalThis.Array.isArray(object?.images) ? object.images.map((e: any) => VendorImages.fromJSON(e)) : [],
    };
  },

  toJSON(message: VendorAdmin): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.code !== "") {
      obj.code = message.code;
    }
    if (message.officeHours !== undefined) {
      obj.officeHours = OfficeHours.toJSON(message.officeHours);
    }
    if (message.timeZone !== "") {
      obj.timeZone = message.timeZone;
    }
    if (message.location !== "") {
      obj.location = message.location;
    }
    if (message.address !== "") {
      obj.address = message.address;
    }
    if (message.about !== "") {
      obj.about = message.about;
    }
    if (message.additionalInfo !== "") {
      obj.additionalInfo = message.additionalInfo;
    }
    if (message.googleMapSrc !== "") {
      obj.googleMapSrc = message.googleMapSrc;
    }
    if (message.bookingCancellationPolicy !== "") {
      obj.bookingCancellationPolicy = message.bookingCancellationPolicy;
    }
    if (message.additionalBookingNotes !== "") {
      obj.additionalBookingNotes = message.additionalBookingNotes;
    }
    if (message.images?.length) {
      obj.images = message.images.map((e) => VendorImages.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VendorAdmin>, I>>(base?: I): VendorAdmin {
    return VendorAdmin.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VendorAdmin>, I>>(object: I): VendorAdmin {
    const message = createBaseVendorAdmin();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.code = object.code ?? "";
    message.officeHours = (object.officeHours !== undefined && object.officeHours !== null)
      ? OfficeHours.fromPartial(object.officeHours)
      : undefined;
    message.timeZone = object.timeZone ?? "";
    message.location = object.location ?? "";
    message.address = object.address ?? "";
    message.about = object.about ?? "";
    message.additionalInfo = object.additionalInfo ?? "";
    message.googleMapSrc = object.googleMapSrc ?? "";
    message.bookingCancellationPolicy = object.bookingCancellationPolicy ?? "";
    message.additionalBookingNotes = object.additionalBookingNotes ?? "";
    message.images = object.images?.map((e) => VendorImages.fromPartial(e)) || [];
    return message;
  },
};

function createBaseVendorAdminForUpdate(): VendorAdminForUpdate {
  return {
    id: "",
    name: undefined,
    code: undefined,
    officeHours: undefined,
    timeZone: undefined,
    location: undefined,
    address: undefined,
    about: undefined,
    additionalInfo: undefined,
    googleMapSrc: undefined,
    bookingCancellationPolicy: undefined,
    additionalBookingNotes: undefined,
    images: [],
  };
}

export const VendorAdminForUpdate = {
  encode(message: VendorAdminForUpdate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    if (message.code !== undefined) {
      writer.uint32(26).string(message.code);
    }
    if (message.officeHours !== undefined) {
      OfficeHours.encode(message.officeHours, writer.uint32(34).fork()).ldelim();
    }
    if (message.timeZone !== undefined) {
      writer.uint32(42).string(message.timeZone);
    }
    if (message.location !== undefined) {
      writer.uint32(50).string(message.location);
    }
    if (message.address !== undefined) {
      writer.uint32(58).string(message.address);
    }
    if (message.about !== undefined) {
      writer.uint32(66).string(message.about);
    }
    if (message.additionalInfo !== undefined) {
      writer.uint32(74).string(message.additionalInfo);
    }
    if (message.googleMapSrc !== undefined) {
      writer.uint32(82).string(message.googleMapSrc);
    }
    if (message.bookingCancellationPolicy !== undefined) {
      writer.uint32(90).string(message.bookingCancellationPolicy);
    }
    if (message.additionalBookingNotes !== undefined) {
      writer.uint32(98).string(message.additionalBookingNotes);
    }
    for (const v of message.images) {
      VendorImages.encode(v!, writer.uint32(106).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VendorAdminForUpdate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVendorAdminForUpdate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.code = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.officeHours = OfficeHours.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.timeZone = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.location = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.address = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.about = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.additionalInfo = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.googleMapSrc = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.bookingCancellationPolicy = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.additionalBookingNotes = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.images.push(VendorImages.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VendorAdminForUpdate {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : undefined,
      code: isSet(object.code) ? globalThis.String(object.code) : undefined,
      officeHours: isSet(object.officeHours) ? OfficeHours.fromJSON(object.officeHours) : undefined,
      timeZone: isSet(object.timeZone) ? globalThis.String(object.timeZone) : undefined,
      location: isSet(object.location) ? globalThis.String(object.location) : undefined,
      address: isSet(object.address) ? globalThis.String(object.address) : undefined,
      about: isSet(object.about) ? globalThis.String(object.about) : undefined,
      additionalInfo: isSet(object.additionalInfo) ? globalThis.String(object.additionalInfo) : undefined,
      googleMapSrc: isSet(object.googleMapSrc) ? globalThis.String(object.googleMapSrc) : undefined,
      bookingCancellationPolicy: isSet(object.bookingCancellationPolicy)
        ? globalThis.String(object.bookingCancellationPolicy)
        : undefined,
      additionalBookingNotes: isSet(object.additionalBookingNotes)
        ? globalThis.String(object.additionalBookingNotes)
        : undefined,
      images: globalThis.Array.isArray(object?.images) ? object.images.map((e: any) => VendorImages.fromJSON(e)) : [],
    };
  },

  toJSON(message: VendorAdminForUpdate): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== undefined) {
      obj.name = message.name;
    }
    if (message.code !== undefined) {
      obj.code = message.code;
    }
    if (message.officeHours !== undefined) {
      obj.officeHours = OfficeHours.toJSON(message.officeHours);
    }
    if (message.timeZone !== undefined) {
      obj.timeZone = message.timeZone;
    }
    if (message.location !== undefined) {
      obj.location = message.location;
    }
    if (message.address !== undefined) {
      obj.address = message.address;
    }
    if (message.about !== undefined) {
      obj.about = message.about;
    }
    if (message.additionalInfo !== undefined) {
      obj.additionalInfo = message.additionalInfo;
    }
    if (message.googleMapSrc !== undefined) {
      obj.googleMapSrc = message.googleMapSrc;
    }
    if (message.bookingCancellationPolicy !== undefined) {
      obj.bookingCancellationPolicy = message.bookingCancellationPolicy;
    }
    if (message.additionalBookingNotes !== undefined) {
      obj.additionalBookingNotes = message.additionalBookingNotes;
    }
    if (message.images?.length) {
      obj.images = message.images.map((e) => VendorImages.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VendorAdminForUpdate>, I>>(base?: I): VendorAdminForUpdate {
    return VendorAdminForUpdate.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VendorAdminForUpdate>, I>>(object: I): VendorAdminForUpdate {
    const message = createBaseVendorAdminForUpdate();
    message.id = object.id ?? "";
    message.name = object.name ?? undefined;
    message.code = object.code ?? undefined;
    message.officeHours = (object.officeHours !== undefined && object.officeHours !== null)
      ? OfficeHours.fromPartial(object.officeHours)
      : undefined;
    message.timeZone = object.timeZone ?? undefined;
    message.location = object.location ?? undefined;
    message.address = object.address ?? undefined;
    message.about = object.about ?? undefined;
    message.additionalInfo = object.additionalInfo ?? undefined;
    message.googleMapSrc = object.googleMapSrc ?? undefined;
    message.bookingCancellationPolicy = object.bookingCancellationPolicy ?? undefined;
    message.additionalBookingNotes = object.additionalBookingNotes ?? undefined;
    message.images = object.images?.map((e) => VendorImages.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCheckAccessRequest(): CheckAccessRequest {
  return { vendorId: "", emailAddress: "" };
}

export const CheckAccessRequest = {
  encode(message: CheckAccessRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.vendorId !== "") {
      writer.uint32(10).string(message.vendorId);
    }
    if (message.emailAddress !== "") {
      writer.uint32(18).string(message.emailAddress);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CheckAccessRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCheckAccessRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.vendorId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.emailAddress = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CheckAccessRequest {
    return {
      vendorId: isSet(object.vendorId) ? globalThis.String(object.vendorId) : "",
      emailAddress: isSet(object.emailAddress) ? globalThis.String(object.emailAddress) : "",
    };
  },

  toJSON(message: CheckAccessRequest): unknown {
    const obj: any = {};
    if (message.vendorId !== "") {
      obj.vendorId = message.vendorId;
    }
    if (message.emailAddress !== "") {
      obj.emailAddress = message.emailAddress;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CheckAccessRequest>, I>>(base?: I): CheckAccessRequest {
    return CheckAccessRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CheckAccessRequest>, I>>(object: I): CheckAccessRequest {
    const message = createBaseCheckAccessRequest();
    message.vendorId = object.vendorId ?? "";
    message.emailAddress = object.emailAddress ?? "";
    return message;
  },
};

function createBaseCheckAccessResponse(): CheckAccessResponse {
  return { access: "" };
}

export const CheckAccessResponse = {
  encode(message: CheckAccessResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.access !== "") {
      writer.uint32(10).string(message.access);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CheckAccessResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCheckAccessResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.access = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CheckAccessResponse {
    return { access: isSet(object.access) ? globalThis.String(object.access) : "" };
  },

  toJSON(message: CheckAccessResponse): unknown {
    const obj: any = {};
    if (message.access !== "") {
      obj.access = message.access;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CheckAccessResponse>, I>>(base?: I): CheckAccessResponse {
    return CheckAccessResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CheckAccessResponse>, I>>(object: I): CheckAccessResponse {
    const message = createBaseCheckAccessResponse();
    message.access = object.access ?? "";
    return message;
  },
};

function createBaseCreateVendorAdminRequest(): CreateVendorAdminRequest {
  return { vendor: undefined };
}

export const CreateVendorAdminRequest = {
  encode(message: CreateVendorAdminRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.vendor !== undefined) {
      VendorAdmin.encode(message.vendor, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateVendorAdminRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateVendorAdminRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.vendor = VendorAdmin.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateVendorAdminRequest {
    return { vendor: isSet(object.vendor) ? VendorAdmin.fromJSON(object.vendor) : undefined };
  },

  toJSON(message: CreateVendorAdminRequest): unknown {
    const obj: any = {};
    if (message.vendor !== undefined) {
      obj.vendor = VendorAdmin.toJSON(message.vendor);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateVendorAdminRequest>, I>>(base?: I): CreateVendorAdminRequest {
    return CreateVendorAdminRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateVendorAdminRequest>, I>>(object: I): CreateVendorAdminRequest {
    const message = createBaseCreateVendorAdminRequest();
    message.vendor = (object.vendor !== undefined && object.vendor !== null)
      ? VendorAdmin.fromPartial(object.vendor)
      : undefined;
    return message;
  },
};

function createBaseCreateVendorAdminResponse(): CreateVendorAdminResponse {
  return { vendor: undefined };
}

export const CreateVendorAdminResponse = {
  encode(message: CreateVendorAdminResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.vendor !== undefined) {
      VendorAdmin.encode(message.vendor, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateVendorAdminResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateVendorAdminResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.vendor = VendorAdmin.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateVendorAdminResponse {
    return { vendor: isSet(object.vendor) ? VendorAdmin.fromJSON(object.vendor) : undefined };
  },

  toJSON(message: CreateVendorAdminResponse): unknown {
    const obj: any = {};
    if (message.vendor !== undefined) {
      obj.vendor = VendorAdmin.toJSON(message.vendor);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateVendorAdminResponse>, I>>(base?: I): CreateVendorAdminResponse {
    return CreateVendorAdminResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateVendorAdminResponse>, I>>(object: I): CreateVendorAdminResponse {
    const message = createBaseCreateVendorAdminResponse();
    message.vendor = (object.vendor !== undefined && object.vendor !== null)
      ? VendorAdmin.fromPartial(object.vendor)
      : undefined;
    return message;
  },
};

function createBaseGetVendorAdminsRequest(): GetVendorAdminsRequest {
  return {};
}

export const GetVendorAdminsRequest = {
  encode(_: GetVendorAdminsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetVendorAdminsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetVendorAdminsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetVendorAdminsRequest {
    return {};
  },

  toJSON(_: GetVendorAdminsRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<GetVendorAdminsRequest>, I>>(base?: I): GetVendorAdminsRequest {
    return GetVendorAdminsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetVendorAdminsRequest>, I>>(_: I): GetVendorAdminsRequest {
    const message = createBaseGetVendorAdminsRequest();
    return message;
  },
};

function createBaseGetVendorAdminsResponse(): GetVendorAdminsResponse {
  return { vendor: [] };
}

export const GetVendorAdminsResponse = {
  encode(message: GetVendorAdminsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.vendor) {
      VendorAdmin.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetVendorAdminsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetVendorAdminsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.vendor.push(VendorAdmin.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetVendorAdminsResponse {
    return {
      vendor: globalThis.Array.isArray(object?.vendor) ? object.vendor.map((e: any) => VendorAdmin.fromJSON(e)) : [],
    };
  },

  toJSON(message: GetVendorAdminsResponse): unknown {
    const obj: any = {};
    if (message.vendor?.length) {
      obj.vendor = message.vendor.map((e) => VendorAdmin.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetVendorAdminsResponse>, I>>(base?: I): GetVendorAdminsResponse {
    return GetVendorAdminsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetVendorAdminsResponse>, I>>(object: I): GetVendorAdminsResponse {
    const message = createBaseGetVendorAdminsResponse();
    message.vendor = object.vendor?.map((e) => VendorAdmin.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetVendorAdminRequest(): GetVendorAdminRequest {
  return { id: "" };
}

export const GetVendorAdminRequest = {
  encode(message: GetVendorAdminRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetVendorAdminRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetVendorAdminRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetVendorAdminRequest {
    return { id: isSet(object.id) ? globalThis.String(object.id) : "" };
  },

  toJSON(message: GetVendorAdminRequest): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetVendorAdminRequest>, I>>(base?: I): GetVendorAdminRequest {
    return GetVendorAdminRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetVendorAdminRequest>, I>>(object: I): GetVendorAdminRequest {
    const message = createBaseGetVendorAdminRequest();
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseGetVendorAdminResponse(): GetVendorAdminResponse {
  return { vendor: undefined };
}

export const GetVendorAdminResponse = {
  encode(message: GetVendorAdminResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.vendor !== undefined) {
      VendorAdmin.encode(message.vendor, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetVendorAdminResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetVendorAdminResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.vendor = VendorAdmin.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetVendorAdminResponse {
    return { vendor: isSet(object.vendor) ? VendorAdmin.fromJSON(object.vendor) : undefined };
  },

  toJSON(message: GetVendorAdminResponse): unknown {
    const obj: any = {};
    if (message.vendor !== undefined) {
      obj.vendor = VendorAdmin.toJSON(message.vendor);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetVendorAdminResponse>, I>>(base?: I): GetVendorAdminResponse {
    return GetVendorAdminResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetVendorAdminResponse>, I>>(object: I): GetVendorAdminResponse {
    const message = createBaseGetVendorAdminResponse();
    message.vendor = (object.vendor !== undefined && object.vendor !== null)
      ? VendorAdmin.fromPartial(object.vendor)
      : undefined;
    return message;
  },
};

function createBaseUpdateVendorAdminRequest(): UpdateVendorAdminRequest {
  return { vendor: undefined };
}

export const UpdateVendorAdminRequest = {
  encode(message: UpdateVendorAdminRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.vendor !== undefined) {
      VendorAdminForUpdate.encode(message.vendor, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateVendorAdminRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateVendorAdminRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.vendor = VendorAdminForUpdate.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateVendorAdminRequest {
    return { vendor: isSet(object.vendor) ? VendorAdminForUpdate.fromJSON(object.vendor) : undefined };
  },

  toJSON(message: UpdateVendorAdminRequest): unknown {
    const obj: any = {};
    if (message.vendor !== undefined) {
      obj.vendor = VendorAdminForUpdate.toJSON(message.vendor);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateVendorAdminRequest>, I>>(base?: I): UpdateVendorAdminRequest {
    return UpdateVendorAdminRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateVendorAdminRequest>, I>>(object: I): UpdateVendorAdminRequest {
    const message = createBaseUpdateVendorAdminRequest();
    message.vendor = (object.vendor !== undefined && object.vendor !== null)
      ? VendorAdminForUpdate.fromPartial(object.vendor)
      : undefined;
    return message;
  },
};

function createBaseUpdateVendorAdminResponse(): UpdateVendorAdminResponse {
  return { status: "" };
}

export const UpdateVendorAdminResponse = {
  encode(message: UpdateVendorAdminResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== "") {
      writer.uint32(10).string(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateVendorAdminResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateVendorAdminResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.status = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateVendorAdminResponse {
    return { status: isSet(object.status) ? globalThis.String(object.status) : "" };
  },

  toJSON(message: UpdateVendorAdminResponse): unknown {
    const obj: any = {};
    if (message.status !== "") {
      obj.status = message.status;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateVendorAdminResponse>, I>>(base?: I): UpdateVendorAdminResponse {
    return UpdateVendorAdminResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateVendorAdminResponse>, I>>(object: I): UpdateVendorAdminResponse {
    const message = createBaseUpdateVendorAdminResponse();
    message.status = object.status ?? "";
    return message;
  },
};

export interface VendorAdminService {
  createVendorAdmin(request: CreateVendorAdminRequest): Promise<CreateVendorAdminResponse>;
  getVendorAdmins(request: GetVendorAdminsRequest): Promise<GetVendorAdminsResponse>;
  getVendorAdmin(request: GetVendorAdminRequest): Promise<GetVendorAdminResponse>;
  updateVendorAdmin(request: UpdateVendorAdminRequest): Promise<UpdateVendorAdminResponse>;
  checkAccess(request: CheckAccessRequest): Promise<CheckAccessResponse>;
}

export const VendorAdminServiceServiceName = "anythingpet.vendorAdmin.VendorAdminService";
export class VendorAdminServiceClientImpl implements VendorAdminService {
  private readonly rpc: Rpc;
  private readonly service: string;
  constructor(rpc: Rpc, opts?: { service?: string }) {
    this.service = opts?.service || VendorAdminServiceServiceName;
    this.rpc = rpc;
    this.createVendorAdmin = this.createVendorAdmin.bind(this);
    this.getVendorAdmins = this.getVendorAdmins.bind(this);
    this.getVendorAdmin = this.getVendorAdmin.bind(this);
    this.updateVendorAdmin = this.updateVendorAdmin.bind(this);
    this.checkAccess = this.checkAccess.bind(this);
  }
  createVendorAdmin(request: CreateVendorAdminRequest): Promise<CreateVendorAdminResponse> {
    const data = CreateVendorAdminRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "createVendorAdmin", data);
    return promise.then((data) => CreateVendorAdminResponse.decode(_m0.Reader.create(data)));
  }

  getVendorAdmins(request: GetVendorAdminsRequest): Promise<GetVendorAdminsResponse> {
    const data = GetVendorAdminsRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "getVendorAdmins", data);
    return promise.then((data) => GetVendorAdminsResponse.decode(_m0.Reader.create(data)));
  }

  getVendorAdmin(request: GetVendorAdminRequest): Promise<GetVendorAdminResponse> {
    const data = GetVendorAdminRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "getVendorAdmin", data);
    return promise.then((data) => GetVendorAdminResponse.decode(_m0.Reader.create(data)));
  }

  updateVendorAdmin(request: UpdateVendorAdminRequest): Promise<UpdateVendorAdminResponse> {
    const data = UpdateVendorAdminRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "updateVendorAdmin", data);
    return promise.then((data) => UpdateVendorAdminResponse.decode(_m0.Reader.create(data)));
  }

  checkAccess(request: CheckAccessRequest): Promise<CheckAccessResponse> {
    const data = CheckAccessRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "checkAccess", data);
    return promise.then((data) => CheckAccessResponse.decode(_m0.Reader.create(data)));
  }
}

interface Rpc {
  request(service: string, method: string, data: Uint8Array): Promise<Uint8Array>;
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
