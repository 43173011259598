import { Box, Button, Card, CardActionArea, CardContent, Grid, Skeleton, Typography } from "@mui/material";
import { AuthenticatedTemplate, LoginButton, SignUpButton, UnauthenticatedTemplate, useAuthManager } from "../components/Auth";
import { useSocketIO } from "../components/SocketIO";
import { useEffect, useState } from "react";
import { booking, offering, pet } from "@anything-pet/grpc-lib";
import { getPetServiceProxy } from "../services/PetServiceProxy";
import AddPetDialog from "../components/AddPetDialog";
import { BookingFormReviewBooking } from "./BookingFormReviewBooking";
import { useBookingFormStateContext } from "./BookOfferingState";


function AuthenticatedSelectPet(props: {
    offering: offering.Offering, 
    slot: booking.AvailableSlot, 
    staff: booking.AvailableSlotResource
}) {
    const context = useBookingFormStateContext();
    const authService = useAuthManager();
    const socket = useSocketIO();
    const [currentPet, setCurrentPet] = useState<pet.Pet | null>(null);
    const [pets, setPets] = useState<pet.Pet[]>();
    const [openDialog, setOpenDialog] = useState(false);

    const userId = authService?.user?.userId;

    useEffect(() => {
        (async () => {
            if (userId) {
                const service = getPetServiceProxy(socket);

                const { pet: pets } = await service.getPets({
                    consumerId: userId
                });
                setPets(pets);
            }
        })();
    }, [userId]);

    if (!pets) {
        return (
            <Box padding={"15px"}>
                <Skeleton variant="rectangular" height={100} />
            </Box>
        );
    }

    if (context.state.petId) {
        const valid = pets.find(pet => pet.id === context.state.petId);

        if (valid) {
            //  Go to next step
            return <BookingFormReviewBooking 
                offering={props.offering} 
                slot={props.slot} 
                staff={props.staff}
                pet={valid} 
            />;
        } else {
            //  Invalid pet id.  Fix the url
            context.state.petId = undefined;
            context.navigateToState();
        }
    }

    const handleSavePet = async (newPet: any) => {
        if (userId) {
            const service = await getPetServiceProxy(socket);
            // const newPetData = {
            //     ...newPet,
            //     consumerId: account.idTokenClaims?.sub,
            // };
            // await service.addPet(newPetData);
            await service.addPet({
                pet: {
                    consumerId: userId,
                    birthDate: newPet.dob.toISOString(),
                    ...newPet,
                },
            })
            const { pet: pets } = await service.getPets({
                consumerId: userId
            });

            setPets(pets);
            setOpenDialog(false);
        }
    };

    const handlePetSelection = (
        pet: pet.Pet
    ) => {
        context.state.petId = pet.id;
        context.navigateToState();
    };


    if (pets.length > 0) {
        return (
            <>
                <Typography
                    variant="h5"
                    paddingTop="20px"
                    paddingBlock="10px"
                    align="center"
                >
                    Please select a pet
                </Typography>
                <Box display="flex" justifyContent="center" mb={2}>
                    <Grid container spacing={2} justifyContent="center">
                        {(pets ?? []).map((pet) => (
                            <Grid item key={pet.id}>
                                <Card
                                    variant={
                                        currentPet === pet
                                            ? "outlined"
                                            : "elevation"
                                    }
                                    sx={{
                                        width: 200,
                                        cursor: "pointer",
                                        backgroundColor:
                                            currentPet === pet
                                                ? "secondary.light"
                                                : "background.paper",
                                        "&:hover": {
                                            backgroundColor: "primary.main",
                                        },
                                    }}
                                    onClick={() =>
                                        handlePetSelection(pet)
                                    }
                                >
                                    <CardActionArea>
                                        <CardContent>
                                            <Typography
                                                variant="h6"
                                                align="center"
                                                sx={{
                                                    fontWeight: "bold",
                                                    color:
                                                        currentPet === pet
                                                            ? "secondary.contrastText"
                                                            : "text.primary",
                                                }}
                                            >
                                                {pet.name}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </>
        );
    } else {
        return (
            <>
                <Typography
                    variant="h5"
                    paddingTop="20px"
                    paddingBlock="10px"
                    align="center"
                >
                    Add a pet
                </Typography>
                <Box display="flex" justifyContent="center" mb={2}>
                    <Typography variant="body1">
                        It appears you do not have a pet in your profile yet.
                    </Typography>
                </Box>
                <Box display="flex" justifyContent="center" mb={2}>
                    <Button variant="contained" color="primary" onClick={() => { setOpenDialog(true) }}>
                        Add a Pet
                    </Button>
                </Box>

                <AddPetDialog
                    open={openDialog}
                    onClose={() => { setOpenDialog(false) }}
                    onSave={handleSavePet}
                />
            </>
        );
    }
}


export function BookingFormSelectPet(props: {
    offering: offering.Offering, 
    slot: booking.AvailableSlot, 
    staff: booking.AvailableSlotResource
}) {
    return (
        <>
            <UnauthenticatedTemplate>
                <>
                    <Typography
                        variant="h5"
                        paddingTop="20px"
                        paddingBlock="10px"
                        align="center"
                    >
                        Login or Sign up to continue booking
                    </Typography>
                    <Box display="flex" justifyContent="center" mb={2}>
                        <LoginButton />
                        <SignUpButton />
                    </Box>
                </>
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
                <AuthenticatedSelectPet {...props} />
            </AuthenticatedTemplate>
        </>
    );
}