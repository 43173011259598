import React from "react";
import {
    AppBar,
    Box,
    Divider,
    ListItemIcon,
    ListItemText,
    Toolbar,
    IconButton,
    Menu,
    MenuItem,
    Typography,
    Container,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import AccountCircle from "@mui/icons-material/AccountCircle";
import HomeIcon from "@mui/icons-material/Home";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import LogoutIcon from "@mui/icons-material/Logout";
import { AuthenticatedTemplate } from "./Auth";
import logo from "../resources/Logo160x60.png";

const NavBar: React.FC = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const navigate = useNavigate();

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleHomeClick = () => {
        navigate("/"); // Navigates to the Home page
    };

    const handleUserProfileClick = () => {
        navigate("/user/profile"); // Navigates to the UserProfile page
    };

    const handleLogoutClick = () => {
        navigate("/logout"); // Navigates to the Logout page
    };

    const open = Boolean(anchorEl);

    return (
        <AppBar position="static" color="default">
            <Container>
                <Toolbar>
                    <Box style={{ flexGrow: 1 }} paddingTop={"20px"} paddingBottom={"20px"}>
                        <img src={logo} />
                    </Box>
                    <AuthenticatedTemplate>
                        <Box sx={{ flexGrow: 0 }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMenu}
                                color="inherit"
                            >
                                <AccountCircle />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                id="account-menu"
                                open={open}
                                onClose={handleClose}
                                onClick={handleClose}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: "visible",
                                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                        mt: 1.5,
                                        "& .MuiAvatar-root": {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1,
                                        },
                                        "&::before": {
                                            content: '""',
                                            display: "block",
                                            position: "absolute",
                                            top: 0,
                                            right: 20,
                                            width: 10,
                                            height: 10,
                                            bgcolor: "background.paper",
                                            transform:
                                                "translateY(-50%) rotate(45deg)",
                                            zIndex: 0,
                                        },
                                    },
                                }}
                                transformOrigin={{
                                    horizontal: "right",
                                    vertical: "top",
                                }}
                                anchorOrigin={{
                                    horizontal: "right",
                                    vertical: "bottom",
                                }}
                            >
                                <MenuItem onClick={handleHomeClick}>
                                    <ListItemIcon>
                                        <HomeIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Home" />
                                </MenuItem>
                                <MenuItem onClick={handleUserProfileClick}>
                                    <ListItemIcon>
                                        <AccountBoxIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="User Profile" />
                                </MenuItem>
                                <Divider component="li" />
                                <MenuItem onClick={handleLogoutClick}>
                                    <ListItemIcon>
                                        <LogoutIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Logout" />
                                </MenuItem>
                            </Menu>
                        </Box>
                    </AuthenticatedTemplate>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default NavBar;
