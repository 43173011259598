import dayjs, { Dayjs } from "dayjs";
import { createContext, PropsWithChildren, useContext } from "react";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";

export type BookingFormState = {
    bookingDate?: Dayjs,
    bookingTime?: string,
    staffId?: string,
    petId?: string
}

export class BookingFormStateContext {
    constructor(
        public readonly state : BookingFormState,
        public readonly offeringId: string,
        private onNavigate : (url: string) => void
    ) {
    }

    navigateToState() {
        const states = [this.offeringId];

        if (this.state.bookingDate) {
            states.push(this.state.bookingDate.format('YYYY-MM-DD'));

            if (this.state.bookingTime) {
                states.push(this.state.bookingTime);

                if (this.state.staffId) {
                    states.push(this.state.staffId);

                    if (this.state.petId) {
                        states.push(this.state.petId);
                    }
                }
            }
        }

        const targetUrl = states.join('/');

        this.onNavigate(targetUrl);
    }
}

const bookingFormStateContext = createContext<BookingFormStateContext | undefined>(undefined);

/**
 * 
 * @returns 
 */
export function useBookingFormStateContext() {
    const result = useContext(bookingFormStateContext);

    if (! result) {
        throw new Error('BookingFormStateContext is not available')
    }

    return result;
}

/**
 * 
 * @param props 
 * @returns 
 */
export function BookingFormStateProvider(
    props: PropsWithChildren & { urlPrefix: string, offeringId: string }
) {
    const { date, time, staffId, petId } = useParams();
    const navigate = useNavigate();

    const onNavigate = (url: string) => {
        navigate(`${props.urlPrefix}/${url}`);
    };

    const state : BookingFormState = {
        bookingDate: date ? dayjs(date, 'YYYY-MM-DD') : undefined,
        bookingTime: time,
        staffId: staffId,
        petId: petId
    };

    const context = new BookingFormStateContext(
        state, props.offeringId, onNavigate
    );

    return (
        <bookingFormStateContext.Provider value={context}>
            {props.children}
        </bookingFormStateContext.Provider>
    )
}