import { booking, offering, pet } from "@anything-pet/grpc-lib";
import {
    Box,
    Card,
    CardActionArea,
    CardContent,
    Divider,
    Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

export function BookingFormConfirmBooking(props: {
    offering: offering.Offering;
    slot: booking.AvailableSlot;
    staff: booking.AvailableSlotResource;
    pet: pet.Pet;
}) {
    const timeFormat = "HH:mm";
    const navigate = useNavigate();

    const onClose = () => {
        navigate("/");
    };

    return (
        <Box textAlign="center">
            <Typography
                variant="h5"
                paddingTop="20px"
                paddingBlock="10px"
                align="center"
            >
                Booking Confirmed!
            </Typography>
            <Typography variant="body1">
                Thank you for making the booking. Your booking details is as
                below. We have also sent you an email with the booking details.
            </Typography>
            <Divider sx={{ p: 2 }} />
            <Box sx={{ p: 2 }}>
                <Typography variant="body1">
                    Service: {props.offering.name}
                </Typography>
                <Typography variant="body1">
                    Date: {dayjs(props.slot.fromDateTime).format("YYYY-MM-DD")}
                </Typography>
                <Typography variant="body1">
                    Time: {dayjs(props.slot.fromDateTime).format(timeFormat)} -{" "}
                    {dayjs(props.slot.toDateTime).format(timeFormat)}
                </Typography>
                <Typography variant="body1">
                    Team member: {props.staff.firstName} {props.staff.lastName}
                </Typography>
                <Typography variant="body1">For: {props.pet.name}</Typography>
            </Box>
            <Divider />
            <Box marginTop="20px" display="flex" justifyContent="center" mt={2}>
                <Card
                    variant={"elevation"}
                    sx={{
                        width: 200,
                        cursor: "pointer",
                        backgroundColor: "primary.main",
                    }}
                    onClick={onClose}
                >
                    <CardActionArea>
                        <CardContent>
                            <Typography
                                variant="h6"
                                align="center"
                                sx={{
                                    fontWeight: "bold",
                                }}
                            >
                                OK
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Box>
        </Box>
    );
}
