import { Alert, AlertTitle, Box, Card, CardActionArea, CardContent, Divider, LinearProgress, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useSocketIO } from "../components/SocketIO";
import { useState } from "react";
import { getUserBookingServiceProxy } from "../services/UserBookingServiceProxy";
import { useVendorScope } from "../components/VendorScopeProvider";
import { useBookingFormStateContext } from "./BookOfferingState";
import { booking, offering, pet } from "@anything-pet/grpc-lib";
import { BookingFormConfirmBooking } from "./BookingFormConfirmBooking";

export function BookingFormReviewBooking(
    props: { 
        offering: offering.Offering, 
        slot: booking.AvailableSlot, 
        staff: booking.AvailableSlotResource, 
        pet: pet.Pet 
    }
) {
    const timeFormat = "HH:mm";

    const { id: vendorId } = useVendorScope();
    const context = useBookingFormStateContext();

    const socket = useSocketIO();
    const [loading, setLoading] = useState(false);
    const [booked, setBooked] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const onBook = async () => {
        setLoading(true);
        setError(null); // Reset error state

        try {
            const service = getUserBookingServiceProxy(socket);

            await service.addBooking({
                booking: {
                    id: "",
                    userId: "",
                    petId: props.pet.id,
                    vendorId: vendorId,
                    offeringId: context.offeringId,
                    staffId: props.staff.id,
                    startDateTime: props.slot.fromDateTime,
                    endDateTime: props.slot.toDateTime,
                },
            });

            setBooked(true);
        } catch (e) {
            console.log(e);
            //const errorMessage = e instanceof Error ? e.message : String(e);
            setError(
                "An error occurred while completing the booking. We apologize for the inconvenience."
            );
        } finally {
            setLoading(false);
        }
    };

    if (booked) {
        return (
            <BookingFormConfirmBooking 
                offering={props.offering}
                slot={props.slot}
                staff={props.staff}
                pet={props.pet}
                />
        );
    }

    return (
        <>
            <Box textAlign="center">
                <Typography
                    variant="h5"
                    paddingTop="20px"
                    paddingBlock="10px"
                    align="center"
                >
                    Booking Summary
                </Typography>
                <Typography variant="body1">
                    Please review the booking details you have selected.
                </Typography>
                <Divider sx={{ p: 2 }}/>
                <Box sx={{ p: 2 }}>
                    <Typography variant="body1">
                        Service: {props.offering.name}
                    </Typography>
                    <Typography variant="body1">
                        Date: {dayjs(props.slot.fromDateTime).format("YYYY-MM-DD")}
                    </Typography>
                    <Typography variant="body1">
                        Time: {dayjs(props.slot.fromDateTime).format(timeFormat)} -{" "}
                        {dayjs(props.slot.toDateTime).format(timeFormat)}
                    </Typography>
                    <Typography variant="body1">
                        Team member: {props.staff.firstName} {props.staff.lastName}
                    </Typography>
                    <Typography variant="body1">For: {props.pet.name}</Typography>
                </Box>
                <Divider/>
            </Box>
            <Typography
                variant="body1"
                paddingTop="20px"
                paddingBlock="10px"
                align="center"
            >
                Click the Book button to confirm.
            </Typography>
            {loading && <LinearProgress />}
            {error && (
                <Box mt={2}>
                    <Alert severity="error">
                        <AlertTitle>Oops</AlertTitle>
                        {error}
                    </Alert>
                </Box>
            )}
            <Box display="flex" justifyContent="center" mt={2}>
                <Card
                    variant={"elevation"}
                    sx={{
                        width: 200,
                        cursor: "pointer",
                        backgroundColor: "primary.main",
                    }}
                    onClick={onBook}
                >
                    <CardActionArea disabled={loading}>
                        <CardContent>
                            <Typography
                                variant="h6"
                                align="center"
                                sx={{
                                    fontWeight: "bold",
                                }}
                            >
                                Book
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Box>
        </>
    );
}