import React from "react";
import { Box, Grid, Link, Stack, Container, Typography } from "@mui/material";
import logo from "../resources/Logo160x60.png";
import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";
import InstagramIcon from "@mui/icons-material/Instagram";

const SiteFooter: React.FC = () => {
    return (
        <Container>
            <Box paddingTop={"40px"} paddingBottom={"40px"}>
                <Grid container spacing={2}>
                    <Grid item xs={9}>
                        <Link href="https://www.anything-pet.com">
                            <img src={logo} />
                        </Link>
                        <Box>
                            <Typography variant="body2">
                                Elevating Pet Care, One Paw at a Time
                            </Typography>
                        </Box>
                        <Stack
                            direction="row"
                            spacing={1}
                            alignItems="flex-end"
                            paddingTop={"20px"}
                            paddingBottom={"20px"}
                        >
                            <Link href="http://facebook.com/AnythingPet">
                                <FacebookIcon color="secondary" />
                            </Link>
                            <Link href="http://instagram.com/anythingpetapp">
                                <InstagramIcon color="secondary" />
                            </Link>
                            <Link href="http://twitter.com/AnythingPet">
                                <XIcon color="secondary" />
                            </Link>
                        </Stack>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="h6" paddingTop={"20px"} paddingBottom={"10px"}>
                            Legal
                        </Typography>
                        <Stack spacing={1}>
                            <Link
                                href="https://www.anything-pet.com/privacy-policy"
                                variant="body2"
                                color={"secondary"}
                            >
                                Privacy Policy
                            </Link>
                            <Link
                                href="https://www.anything-pet.com/terms-of-service"
                                variant="body2"
                                color={"secondary"}
                            >
                                Terms of Service
                            </Link>
                            <Link
                                href="https://www.anything-pet.com/terms-of-use"
                                variant="body2"
                                color={"secondary"}
                            >
                                Terms of Use
                            </Link>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
};

export default SiteFooter;
