import React, { useEffect, useState } from 'react';
import {
  Box,
  LinearProgress,
} from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { pet, userProfile } from '@anything-pet/grpc-lib';
import { useAuthManager } from '../components/Auth'
import { getUserProfileServiceProxy } from '../services/UserProfileServiceProxy';
import { getPetServiceProxy } from '../services/PetServiceProxy';
import { useSocketIO } from '../components/SocketIO';
import UserProfileEditor from '../components/UserProfileEditor';

type UserProfile = userProfile.UserProfile;

type PetProfile = Omit<pet.Pet, 'consumerId' | 'birthDate'> & { dob: Dayjs };

const toPetProfile = (pet : pet.Pet) => {
  const { birthDate, ...thePet} = pet;

  return {
    ...thePet,
    dob: dayjs(birthDate)
  };
}

const UserProfilePage: React.FC = () => {
  const socket = useSocketIO();
  const authService = useAuthManager();
  const userId = authService?.user?.userId;

  const [editUserMode, setEditUserMode] = useState(false);
  const [user, setUser] = useState<UserProfile>();
  const [pets, setPets] = useState<PetProfile[]>();
  const [currentPet, setCurrentPet] = useState<PetProfile | null>(null);
  
  useEffect(() => {
    async function loadUserProfile() {
      if (userId) {
        const proxy = getUserProfileServiceProxy(socket);

        const result = await proxy.getUserProfile({
          id: userId
        });
        
        setUser(result.profile);
      }
    }

    async function loadPets() {
      if (userId) {
        const proxy = getPetServiceProxy(socket);

        const { pet: pets } = await proxy.getPets({ consumerId: userId });

        setPets(
          pets.map(pet => (toPetProfile(pet)))
        );
      }
    }

    (async () => {
      await Promise.all([
        loadUserProfile(),
        loadPets()
      ]);
    })();
  }, [userId, socket]);

  if (!userId) {
    throw new Error('Not authenticated');
  }

  if (!user || ! pets) {
    return (
      <LinearProgress />
    )
  }

  const handleEditUser = () => {
    setEditUserMode(true);
  };

  const handleCancelUser = () => {
    setEditUserMode(false);
  };

  const handleSaveUser = async () => {
    // Send the updated user data to your backend server
    const proxy = getUserProfileServiceProxy(socket);

    await proxy.updateUserProfile({
      profile: {
        ...user,
        id: userId
      }
    });

    setEditUserMode(false);
  };

  const handleChange = (name: string, value: string) => {    
    setUser({ ...user, [name.split('.')[1]]: value });
  };

  return (
    <Box sx={{ padding: 3 }}>
      <UserProfileEditor 
        user={user} 
        editMode={editUserMode} 
        handleChange={handleChange}
        handleSaveUser={handleSaveUser}
        handleCancelUser={handleCancelUser}
        handleEditUser={handleEditUser}
      />
    </Box>
  );
};

export default UserProfilePage;
