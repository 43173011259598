import { userBooking } from '@anything-pet/grpc-lib'
import { Socket } from 'socket.io-client';
import { emitEvent } from '../components/SocketIO';

type UserBookingService = userBooking.UserBookingService
/**
 * 
 * @param socket 
 * @returns 
 */
export function getUserBookingServiceProxy(socket: Socket): UserBookingService {
    return new BffUserBookingServiceProxy(socket);
}

/**
 * 
 */
class BffUserBookingServiceProxy implements UserBookingService {
    /**
     * 
     * @param socket 
     */
    constructor(private socket: Socket) { }

    /**
     * 
     * @param request 
     */
    async addBooking(request: userBooking.AddBookingRequest): Promise<userBooking.AddBookingResponse> {
        return emitEvent<userBooking.AddBookingResponse, userBooking.AddBookingRequest>(
            this.socket, "userBooking:addBooking", request
        );
    }

    /**
     * 
     * @param request 
     */
    removeBooking(request: userBooking.RemoveBookingRequest): Promise<userBooking.RemoveBookingResponse> {
        return emitEvent<userBooking.RemoveBookingResponse, userBooking.RemoveBookingRequest>(
            this.socket, "userBooking:removeBooking", request
        );
    }

    /**
     * 
     * @param request 
     */
    getBookings(request: userBooking.GetBookingsRequest): Promise<userBooking.GetBookingsResponse> {
        return emitEvent<userBooking.GetBookingsResponse, userBooking.GetBookingsRequest>(
            this.socket, "userBooking:getBookings", request
        );
    }
}
