import { Socket } from "socket.io-client";
import { userProfile } from "@anything-pet/grpc-lib";
import { emitEvent } from "../components/SocketIO";

/**
 * 
 * @param socket 
 * @returns 
 */
export function getUserProfileServiceProxy(socket : Socket) : userProfile.UserProfileService {
    return new UserProfileServiceProxy(socket);
}

/**
 * 
 */
class UserProfileServiceProxy implements userProfile.UserProfileService {

    constructor(private socket : Socket) {        
    }

    /**
     * 
     * @param request 
     * @returns 
     */
    async getUserProfile(request: userProfile.GetUserProfileRequest): Promise<userProfile.GetUserProfileResponse> {
        const response = await emitEvent<userProfile.GetUserProfileResponse, userProfile.GetUserProfileRequest>(
            this.socket, "userprofile:getUserProfile", request
        );

        return response;
    }

    /**
     * 
     * @param request 
     * @returns 
     */
    async updateUserProfile(request: userProfile.UpdateUserProfileRequest): Promise<userProfile.UpdateUserProfileResponse> {
        const response = await emitEvent<userProfile.UpdateUserProfileResponse, userProfile.UpdateUserProfileRequest>(
            this.socket, "userprofile:updateUserProfile", request
        );

        return response;
    }
}