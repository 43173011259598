import { vendor } from "@anything-pet/grpc-lib";
import { Socket } from "socket.io-client";
import { emitEvent } from "../components/SocketIO";

type VendorProfileService = Pick<vendor.VendorService, 'getVendorByCode'>

/**
 * 
 * @param serviecUrl 
 * @returns 
 */
export function getVendorService(socket : Socket) : VendorProfileService {
    return new BffVendorService(socket);
}


/**
 * 
 */
class BffVendorService implements VendorProfileService {
    
    /**
     *
     */
    constructor(private socket : Socket) {        
    }

    /**
     * 
     * @param shortName 
     */
    async getVendorByCode(request: vendor.GetVendorByCodeRequest): Promise<vendor.GetVendorByCodeResponse> {
        const response = await emitEvent<vendor.GetVendorByCodeResponse, vendor.GetVendorByCodeRequest>(
            this.socket, "vendor:getVendorByCode", request
        );

        return response;
    }

}