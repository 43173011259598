import { SocketIOProvider } from "../components/SocketIO";
import { Link, Route, Routes } from "react-router-dom";
import TestProfilePage from "./TestProfilePage";
import { AuthenticatedTemplate, UnauthenticatedTemplate, LoginButton, LogoutButton } from "../components/Auth";
import { MyBookings } from "./MyBookings";
import { Box, Button, Divider } from "@mui/material";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import EventIcon from '@mui/icons-material/Event';


export function TestHome() {
    return (
        <>
            <AuthenticatedTemplate>
                <SocketIOProvider>
                    <Box sx={{ padding: 3 }}>
                        <Button variant="contained" sx={{ mt: 2, mr: 1 }} href="/test-home" startIcon={<AccountBoxIcon />}>Test Profile</Button>
                        <Button variant="contained" sx={{ mt: 2, mr: 1 }} href="/test-home/bookings" startIcon={<EventIcon />}>Bookings</Button>
                        <Box sx={{ pt: 3 }}>                        
                            <Routes>
                                <Route path='bookings' element={<MyBookings />} />
                                <Route index element={<TestProfilePage />} />
                            </Routes>
                        </Box>
                    </Box>
                </SocketIOProvider>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Box sx={{ padding: 3 }}>
                    <h1> Test login</h1>
                    <LoginButton />  
                </Box>                   
            </UnauthenticatedTemplate>
        </>
    )
}