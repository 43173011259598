/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "anythingpet.booking";

export interface Booking {
  id: string;
  vendorId: string;
  /** string userId = 3;  //  Hide userId */
  staffId: string;
  offeringId: string;
  /** How long the booking will take (in minutes) */
  startDateTime: string;
  endDateTime: string;
}

export interface AvailableDay {
  date: string;
  isAvailable: boolean;
}

export interface AvailableSlotResource {
  id: string;
  firstName: string;
  lastName: string;
}

export interface AvailableSlot {
  fromDateTime: string;
  toDateTime: string;
  staff: AvailableSlotResource[];
}

export interface GetAvailableBookingDaysRequest {
  vendorId: string;
  startDateTime: string;
  endDateTime: string;
  offeringId: string;
}

export interface GetAvailableBookingDaysResponse {
  day: AvailableDay[];
}

export interface GetAvailableSlotsRequest {
  vendorId: string;
  startDateTime: string;
  endDateTime: string;
  offeringId: string;
}

export interface GetAvailableSlotsResponse {
  slot: AvailableSlot[];
}

function createBaseBooking(): Booking {
  return { id: "", vendorId: "", staffId: "", offeringId: "", startDateTime: "", endDateTime: "" };
}

export const Booking = {
  encode(message: Booking, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.vendorId !== "") {
      writer.uint32(18).string(message.vendorId);
    }
    if (message.staffId !== "") {
      writer.uint32(34).string(message.staffId);
    }
    if (message.offeringId !== "") {
      writer.uint32(42).string(message.offeringId);
    }
    if (message.startDateTime !== "") {
      writer.uint32(50).string(message.startDateTime);
    }
    if (message.endDateTime !== "") {
      writer.uint32(58).string(message.endDateTime);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Booking {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBooking();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.vendorId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.staffId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.offeringId = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.startDateTime = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.endDateTime = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Booking {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      vendorId: isSet(object.vendorId) ? globalThis.String(object.vendorId) : "",
      staffId: isSet(object.staffId) ? globalThis.String(object.staffId) : "",
      offeringId: isSet(object.offeringId) ? globalThis.String(object.offeringId) : "",
      startDateTime: isSet(object.startDateTime) ? globalThis.String(object.startDateTime) : "",
      endDateTime: isSet(object.endDateTime) ? globalThis.String(object.endDateTime) : "",
    };
  },

  toJSON(message: Booking): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.vendorId !== "") {
      obj.vendorId = message.vendorId;
    }
    if (message.staffId !== "") {
      obj.staffId = message.staffId;
    }
    if (message.offeringId !== "") {
      obj.offeringId = message.offeringId;
    }
    if (message.startDateTime !== "") {
      obj.startDateTime = message.startDateTime;
    }
    if (message.endDateTime !== "") {
      obj.endDateTime = message.endDateTime;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Booking>, I>>(base?: I): Booking {
    return Booking.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Booking>, I>>(object: I): Booking {
    const message = createBaseBooking();
    message.id = object.id ?? "";
    message.vendorId = object.vendorId ?? "";
    message.staffId = object.staffId ?? "";
    message.offeringId = object.offeringId ?? "";
    message.startDateTime = object.startDateTime ?? "";
    message.endDateTime = object.endDateTime ?? "";
    return message;
  },
};

function createBaseAvailableDay(): AvailableDay {
  return { date: "", isAvailable: false };
}

export const AvailableDay = {
  encode(message: AvailableDay, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.date !== "") {
      writer.uint32(10).string(message.date);
    }
    if (message.isAvailable === true) {
      writer.uint32(16).bool(message.isAvailable);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AvailableDay {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAvailableDay();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.date = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.isAvailable = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AvailableDay {
    return {
      date: isSet(object.date) ? globalThis.String(object.date) : "",
      isAvailable: isSet(object.isAvailable) ? globalThis.Boolean(object.isAvailable) : false,
    };
  },

  toJSON(message: AvailableDay): unknown {
    const obj: any = {};
    if (message.date !== "") {
      obj.date = message.date;
    }
    if (message.isAvailable === true) {
      obj.isAvailable = message.isAvailable;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AvailableDay>, I>>(base?: I): AvailableDay {
    return AvailableDay.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AvailableDay>, I>>(object: I): AvailableDay {
    const message = createBaseAvailableDay();
    message.date = object.date ?? "";
    message.isAvailable = object.isAvailable ?? false;
    return message;
  },
};

function createBaseAvailableSlotResource(): AvailableSlotResource {
  return { id: "", firstName: "", lastName: "" };
}

export const AvailableSlotResource = {
  encode(message: AvailableSlotResource, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.firstName !== "") {
      writer.uint32(18).string(message.firstName);
    }
    if (message.lastName !== "") {
      writer.uint32(26).string(message.lastName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AvailableSlotResource {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAvailableSlotResource();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.firstName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.lastName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AvailableSlotResource {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      firstName: isSet(object.firstName) ? globalThis.String(object.firstName) : "",
      lastName: isSet(object.lastName) ? globalThis.String(object.lastName) : "",
    };
  },

  toJSON(message: AvailableSlotResource): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.firstName !== "") {
      obj.firstName = message.firstName;
    }
    if (message.lastName !== "") {
      obj.lastName = message.lastName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AvailableSlotResource>, I>>(base?: I): AvailableSlotResource {
    return AvailableSlotResource.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AvailableSlotResource>, I>>(object: I): AvailableSlotResource {
    const message = createBaseAvailableSlotResource();
    message.id = object.id ?? "";
    message.firstName = object.firstName ?? "";
    message.lastName = object.lastName ?? "";
    return message;
  },
};

function createBaseAvailableSlot(): AvailableSlot {
  return { fromDateTime: "", toDateTime: "", staff: [] };
}

export const AvailableSlot = {
  encode(message: AvailableSlot, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fromDateTime !== "") {
      writer.uint32(10).string(message.fromDateTime);
    }
    if (message.toDateTime !== "") {
      writer.uint32(18).string(message.toDateTime);
    }
    for (const v of message.staff) {
      AvailableSlotResource.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AvailableSlot {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAvailableSlot();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fromDateTime = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.toDateTime = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.staff.push(AvailableSlotResource.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AvailableSlot {
    return {
      fromDateTime: isSet(object.fromDateTime) ? globalThis.String(object.fromDateTime) : "",
      toDateTime: isSet(object.toDateTime) ? globalThis.String(object.toDateTime) : "",
      staff: globalThis.Array.isArray(object?.staff)
        ? object.staff.map((e: any) => AvailableSlotResource.fromJSON(e))
        : [],
    };
  },

  toJSON(message: AvailableSlot): unknown {
    const obj: any = {};
    if (message.fromDateTime !== "") {
      obj.fromDateTime = message.fromDateTime;
    }
    if (message.toDateTime !== "") {
      obj.toDateTime = message.toDateTime;
    }
    if (message.staff?.length) {
      obj.staff = message.staff.map((e) => AvailableSlotResource.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AvailableSlot>, I>>(base?: I): AvailableSlot {
    return AvailableSlot.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AvailableSlot>, I>>(object: I): AvailableSlot {
    const message = createBaseAvailableSlot();
    message.fromDateTime = object.fromDateTime ?? "";
    message.toDateTime = object.toDateTime ?? "";
    message.staff = object.staff?.map((e) => AvailableSlotResource.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetAvailableBookingDaysRequest(): GetAvailableBookingDaysRequest {
  return { vendorId: "", startDateTime: "", endDateTime: "", offeringId: "" };
}

export const GetAvailableBookingDaysRequest = {
  encode(message: GetAvailableBookingDaysRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.vendorId !== "") {
      writer.uint32(10).string(message.vendorId);
    }
    if (message.startDateTime !== "") {
      writer.uint32(18).string(message.startDateTime);
    }
    if (message.endDateTime !== "") {
      writer.uint32(26).string(message.endDateTime);
    }
    if (message.offeringId !== "") {
      writer.uint32(34).string(message.offeringId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAvailableBookingDaysRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAvailableBookingDaysRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.vendorId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.startDateTime = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.endDateTime = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.offeringId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetAvailableBookingDaysRequest {
    return {
      vendorId: isSet(object.vendorId) ? globalThis.String(object.vendorId) : "",
      startDateTime: isSet(object.startDateTime) ? globalThis.String(object.startDateTime) : "",
      endDateTime: isSet(object.endDateTime) ? globalThis.String(object.endDateTime) : "",
      offeringId: isSet(object.offeringId) ? globalThis.String(object.offeringId) : "",
    };
  },

  toJSON(message: GetAvailableBookingDaysRequest): unknown {
    const obj: any = {};
    if (message.vendorId !== "") {
      obj.vendorId = message.vendorId;
    }
    if (message.startDateTime !== "") {
      obj.startDateTime = message.startDateTime;
    }
    if (message.endDateTime !== "") {
      obj.endDateTime = message.endDateTime;
    }
    if (message.offeringId !== "") {
      obj.offeringId = message.offeringId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetAvailableBookingDaysRequest>, I>>(base?: I): GetAvailableBookingDaysRequest {
    return GetAvailableBookingDaysRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetAvailableBookingDaysRequest>, I>>(
    object: I,
  ): GetAvailableBookingDaysRequest {
    const message = createBaseGetAvailableBookingDaysRequest();
    message.vendorId = object.vendorId ?? "";
    message.startDateTime = object.startDateTime ?? "";
    message.endDateTime = object.endDateTime ?? "";
    message.offeringId = object.offeringId ?? "";
    return message;
  },
};

function createBaseGetAvailableBookingDaysResponse(): GetAvailableBookingDaysResponse {
  return { day: [] };
}

export const GetAvailableBookingDaysResponse = {
  encode(message: GetAvailableBookingDaysResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.day) {
      AvailableDay.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAvailableBookingDaysResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAvailableBookingDaysResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.day.push(AvailableDay.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetAvailableBookingDaysResponse {
    return { day: globalThis.Array.isArray(object?.day) ? object.day.map((e: any) => AvailableDay.fromJSON(e)) : [] };
  },

  toJSON(message: GetAvailableBookingDaysResponse): unknown {
    const obj: any = {};
    if (message.day?.length) {
      obj.day = message.day.map((e) => AvailableDay.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetAvailableBookingDaysResponse>, I>>(base?: I): GetAvailableBookingDaysResponse {
    return GetAvailableBookingDaysResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetAvailableBookingDaysResponse>, I>>(
    object: I,
  ): GetAvailableBookingDaysResponse {
    const message = createBaseGetAvailableBookingDaysResponse();
    message.day = object.day?.map((e) => AvailableDay.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetAvailableSlotsRequest(): GetAvailableSlotsRequest {
  return { vendorId: "", startDateTime: "", endDateTime: "", offeringId: "" };
}

export const GetAvailableSlotsRequest = {
  encode(message: GetAvailableSlotsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.vendorId !== "") {
      writer.uint32(10).string(message.vendorId);
    }
    if (message.startDateTime !== "") {
      writer.uint32(18).string(message.startDateTime);
    }
    if (message.endDateTime !== "") {
      writer.uint32(26).string(message.endDateTime);
    }
    if (message.offeringId !== "") {
      writer.uint32(34).string(message.offeringId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAvailableSlotsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAvailableSlotsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.vendorId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.startDateTime = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.endDateTime = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.offeringId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetAvailableSlotsRequest {
    return {
      vendorId: isSet(object.vendorId) ? globalThis.String(object.vendorId) : "",
      startDateTime: isSet(object.startDateTime) ? globalThis.String(object.startDateTime) : "",
      endDateTime: isSet(object.endDateTime) ? globalThis.String(object.endDateTime) : "",
      offeringId: isSet(object.offeringId) ? globalThis.String(object.offeringId) : "",
    };
  },

  toJSON(message: GetAvailableSlotsRequest): unknown {
    const obj: any = {};
    if (message.vendorId !== "") {
      obj.vendorId = message.vendorId;
    }
    if (message.startDateTime !== "") {
      obj.startDateTime = message.startDateTime;
    }
    if (message.endDateTime !== "") {
      obj.endDateTime = message.endDateTime;
    }
    if (message.offeringId !== "") {
      obj.offeringId = message.offeringId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetAvailableSlotsRequest>, I>>(base?: I): GetAvailableSlotsRequest {
    return GetAvailableSlotsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetAvailableSlotsRequest>, I>>(object: I): GetAvailableSlotsRequest {
    const message = createBaseGetAvailableSlotsRequest();
    message.vendorId = object.vendorId ?? "";
    message.startDateTime = object.startDateTime ?? "";
    message.endDateTime = object.endDateTime ?? "";
    message.offeringId = object.offeringId ?? "";
    return message;
  },
};

function createBaseGetAvailableSlotsResponse(): GetAvailableSlotsResponse {
  return { slot: [] };
}

export const GetAvailableSlotsResponse = {
  encode(message: GetAvailableSlotsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.slot) {
      AvailableSlot.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAvailableSlotsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAvailableSlotsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.slot.push(AvailableSlot.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetAvailableSlotsResponse {
    return {
      slot: globalThis.Array.isArray(object?.slot) ? object.slot.map((e: any) => AvailableSlot.fromJSON(e)) : [],
    };
  },

  toJSON(message: GetAvailableSlotsResponse): unknown {
    const obj: any = {};
    if (message.slot?.length) {
      obj.slot = message.slot.map((e) => AvailableSlot.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetAvailableSlotsResponse>, I>>(base?: I): GetAvailableSlotsResponse {
    return GetAvailableSlotsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetAvailableSlotsResponse>, I>>(object: I): GetAvailableSlotsResponse {
    const message = createBaseGetAvailableSlotsResponse();
    message.slot = object.slot?.map((e) => AvailableSlot.fromPartial(e)) || [];
    return message;
  },
};

export interface BookingService {
  getAvailableBookingDays(request: GetAvailableBookingDaysRequest): Promise<GetAvailableBookingDaysResponse>;
  getAvailableSlots(request: GetAvailableSlotsRequest): Promise<GetAvailableSlotsResponse>;
}

export const BookingServiceServiceName = "anythingpet.booking.BookingService";
export class BookingServiceClientImpl implements BookingService {
  private readonly rpc: Rpc;
  private readonly service: string;
  constructor(rpc: Rpc, opts?: { service?: string }) {
    this.service = opts?.service || BookingServiceServiceName;
    this.rpc = rpc;
    this.getAvailableBookingDays = this.getAvailableBookingDays.bind(this);
    this.getAvailableSlots = this.getAvailableSlots.bind(this);
  }
  getAvailableBookingDays(request: GetAvailableBookingDaysRequest): Promise<GetAvailableBookingDaysResponse> {
    const data = GetAvailableBookingDaysRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "getAvailableBookingDays", data);
    return promise.then((data) => GetAvailableBookingDaysResponse.decode(_m0.Reader.create(data)));
  }

  getAvailableSlots(request: GetAvailableSlotsRequest): Promise<GetAvailableSlotsResponse> {
    const data = GetAvailableSlotsRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "getAvailableSlots", data);
    return promise.then((data) => GetAvailableSlotsResponse.decode(_m0.Reader.create(data)));
  }
}

interface Rpc {
  request(service: string, method: string, data: Uint8Array): Promise<Uint8Array>;
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
