/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "anythingpet.userBooking";

export interface UserBooking {
  id: string;
  vendorId: string;
  userId: string;
  petId: string;
  staffId: string;
  offeringId: string;
  /** How long the booking will take (in minutes) */
  startDateTime: string;
  endDateTime: string;
}

export interface UserBookingReport {
  id: string;
  vendorId: string;
  userId: string;
  petId: string;
  staffId: string;
  offeringId: string;
  /** How long the booking will take (in minutes) */
  startDateTime: string;
  endDateTime: string;
  vendorName: string;
  offeringName: string;
  petName: string;
}

export interface UserBookingForUpdate {
  id: string;
  vendorId: string;
  petId?: string | undefined;
  startDateTime?: string | undefined;
  endDateTime?: string | undefined;
}

export interface AddBookingRequest {
  booking: UserBooking | undefined;
}

export interface AddBookingResponse {
  booking: UserBooking | undefined;
}

export interface GetBookingsRequest {
  userId: string;
  fromDateTime: string;
  toDateTime: string;
}

export interface GetBookingsResponse {
  booking: UserBookingReport[];
}

export interface RemoveBookingRequest {
  id: string;
  userId: string;
  vendorId: string;
}

export interface RemoveBookingResponse {
  status: string;
}

function createBaseUserBooking(): UserBooking {
  return {
    id: "",
    vendorId: "",
    userId: "",
    petId: "",
    staffId: "",
    offeringId: "",
    startDateTime: "",
    endDateTime: "",
  };
}

export const UserBooking = {
  encode(message: UserBooking, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.vendorId !== "") {
      writer.uint32(18).string(message.vendorId);
    }
    if (message.userId !== "") {
      writer.uint32(26).string(message.userId);
    }
    if (message.petId !== "") {
      writer.uint32(34).string(message.petId);
    }
    if (message.staffId !== "") {
      writer.uint32(42).string(message.staffId);
    }
    if (message.offeringId !== "") {
      writer.uint32(50).string(message.offeringId);
    }
    if (message.startDateTime !== "") {
      writer.uint32(58).string(message.startDateTime);
    }
    if (message.endDateTime !== "") {
      writer.uint32(66).string(message.endDateTime);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserBooking {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserBooking();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.vendorId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.petId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.staffId = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.offeringId = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.startDateTime = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.endDateTime = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserBooking {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      vendorId: isSet(object.vendorId) ? globalThis.String(object.vendorId) : "",
      userId: isSet(object.userId) ? globalThis.String(object.userId) : "",
      petId: isSet(object.petId) ? globalThis.String(object.petId) : "",
      staffId: isSet(object.staffId) ? globalThis.String(object.staffId) : "",
      offeringId: isSet(object.offeringId) ? globalThis.String(object.offeringId) : "",
      startDateTime: isSet(object.startDateTime) ? globalThis.String(object.startDateTime) : "",
      endDateTime: isSet(object.endDateTime) ? globalThis.String(object.endDateTime) : "",
    };
  },

  toJSON(message: UserBooking): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.vendorId !== "") {
      obj.vendorId = message.vendorId;
    }
    if (message.userId !== "") {
      obj.userId = message.userId;
    }
    if (message.petId !== "") {
      obj.petId = message.petId;
    }
    if (message.staffId !== "") {
      obj.staffId = message.staffId;
    }
    if (message.offeringId !== "") {
      obj.offeringId = message.offeringId;
    }
    if (message.startDateTime !== "") {
      obj.startDateTime = message.startDateTime;
    }
    if (message.endDateTime !== "") {
      obj.endDateTime = message.endDateTime;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserBooking>, I>>(base?: I): UserBooking {
    return UserBooking.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserBooking>, I>>(object: I): UserBooking {
    const message = createBaseUserBooking();
    message.id = object.id ?? "";
    message.vendorId = object.vendorId ?? "";
    message.userId = object.userId ?? "";
    message.petId = object.petId ?? "";
    message.staffId = object.staffId ?? "";
    message.offeringId = object.offeringId ?? "";
    message.startDateTime = object.startDateTime ?? "";
    message.endDateTime = object.endDateTime ?? "";
    return message;
  },
};

function createBaseUserBookingReport(): UserBookingReport {
  return {
    id: "",
    vendorId: "",
    userId: "",
    petId: "",
    staffId: "",
    offeringId: "",
    startDateTime: "",
    endDateTime: "",
    vendorName: "",
    offeringName: "",
    petName: "",
  };
}

export const UserBookingReport = {
  encode(message: UserBookingReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.vendorId !== "") {
      writer.uint32(18).string(message.vendorId);
    }
    if (message.userId !== "") {
      writer.uint32(26).string(message.userId);
    }
    if (message.petId !== "") {
      writer.uint32(34).string(message.petId);
    }
    if (message.staffId !== "") {
      writer.uint32(42).string(message.staffId);
    }
    if (message.offeringId !== "") {
      writer.uint32(50).string(message.offeringId);
    }
    if (message.startDateTime !== "") {
      writer.uint32(58).string(message.startDateTime);
    }
    if (message.endDateTime !== "") {
      writer.uint32(66).string(message.endDateTime);
    }
    if (message.vendorName !== "") {
      writer.uint32(162).string(message.vendorName);
    }
    if (message.offeringName !== "") {
      writer.uint32(170).string(message.offeringName);
    }
    if (message.petName !== "") {
      writer.uint32(178).string(message.petName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserBookingReport {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserBookingReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.vendorId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.petId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.staffId = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.offeringId = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.startDateTime = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.endDateTime = reader.string();
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.vendorName = reader.string();
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.offeringName = reader.string();
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.petName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserBookingReport {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      vendorId: isSet(object.vendorId) ? globalThis.String(object.vendorId) : "",
      userId: isSet(object.userId) ? globalThis.String(object.userId) : "",
      petId: isSet(object.petId) ? globalThis.String(object.petId) : "",
      staffId: isSet(object.staffId) ? globalThis.String(object.staffId) : "",
      offeringId: isSet(object.offeringId) ? globalThis.String(object.offeringId) : "",
      startDateTime: isSet(object.startDateTime) ? globalThis.String(object.startDateTime) : "",
      endDateTime: isSet(object.endDateTime) ? globalThis.String(object.endDateTime) : "",
      vendorName: isSet(object.vendorName) ? globalThis.String(object.vendorName) : "",
      offeringName: isSet(object.offeringName) ? globalThis.String(object.offeringName) : "",
      petName: isSet(object.petName) ? globalThis.String(object.petName) : "",
    };
  },

  toJSON(message: UserBookingReport): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.vendorId !== "") {
      obj.vendorId = message.vendorId;
    }
    if (message.userId !== "") {
      obj.userId = message.userId;
    }
    if (message.petId !== "") {
      obj.petId = message.petId;
    }
    if (message.staffId !== "") {
      obj.staffId = message.staffId;
    }
    if (message.offeringId !== "") {
      obj.offeringId = message.offeringId;
    }
    if (message.startDateTime !== "") {
      obj.startDateTime = message.startDateTime;
    }
    if (message.endDateTime !== "") {
      obj.endDateTime = message.endDateTime;
    }
    if (message.vendorName !== "") {
      obj.vendorName = message.vendorName;
    }
    if (message.offeringName !== "") {
      obj.offeringName = message.offeringName;
    }
    if (message.petName !== "") {
      obj.petName = message.petName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserBookingReport>, I>>(base?: I): UserBookingReport {
    return UserBookingReport.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserBookingReport>, I>>(object: I): UserBookingReport {
    const message = createBaseUserBookingReport();
    message.id = object.id ?? "";
    message.vendorId = object.vendorId ?? "";
    message.userId = object.userId ?? "";
    message.petId = object.petId ?? "";
    message.staffId = object.staffId ?? "";
    message.offeringId = object.offeringId ?? "";
    message.startDateTime = object.startDateTime ?? "";
    message.endDateTime = object.endDateTime ?? "";
    message.vendorName = object.vendorName ?? "";
    message.offeringName = object.offeringName ?? "";
    message.petName = object.petName ?? "";
    return message;
  },
};

function createBaseUserBookingForUpdate(): UserBookingForUpdate {
  return { id: "", vendorId: "", petId: undefined, startDateTime: undefined, endDateTime: undefined };
}

export const UserBookingForUpdate = {
  encode(message: UserBookingForUpdate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.vendorId !== "") {
      writer.uint32(18).string(message.vendorId);
    }
    if (message.petId !== undefined) {
      writer.uint32(26).string(message.petId);
    }
    if (message.startDateTime !== undefined) {
      writer.uint32(34).string(message.startDateTime);
    }
    if (message.endDateTime !== undefined) {
      writer.uint32(42).string(message.endDateTime);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserBookingForUpdate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserBookingForUpdate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.vendorId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.petId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.startDateTime = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.endDateTime = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserBookingForUpdate {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      vendorId: isSet(object.vendorId) ? globalThis.String(object.vendorId) : "",
      petId: isSet(object.petId) ? globalThis.String(object.petId) : undefined,
      startDateTime: isSet(object.startDateTime) ? globalThis.String(object.startDateTime) : undefined,
      endDateTime: isSet(object.endDateTime) ? globalThis.String(object.endDateTime) : undefined,
    };
  },

  toJSON(message: UserBookingForUpdate): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.vendorId !== "") {
      obj.vendorId = message.vendorId;
    }
    if (message.petId !== undefined) {
      obj.petId = message.petId;
    }
    if (message.startDateTime !== undefined) {
      obj.startDateTime = message.startDateTime;
    }
    if (message.endDateTime !== undefined) {
      obj.endDateTime = message.endDateTime;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserBookingForUpdate>, I>>(base?: I): UserBookingForUpdate {
    return UserBookingForUpdate.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserBookingForUpdate>, I>>(object: I): UserBookingForUpdate {
    const message = createBaseUserBookingForUpdate();
    message.id = object.id ?? "";
    message.vendorId = object.vendorId ?? "";
    message.petId = object.petId ?? undefined;
    message.startDateTime = object.startDateTime ?? undefined;
    message.endDateTime = object.endDateTime ?? undefined;
    return message;
  },
};

function createBaseAddBookingRequest(): AddBookingRequest {
  return { booking: undefined };
}

export const AddBookingRequest = {
  encode(message: AddBookingRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.booking !== undefined) {
      UserBooking.encode(message.booking, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddBookingRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddBookingRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.booking = UserBooking.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AddBookingRequest {
    return { booking: isSet(object.booking) ? UserBooking.fromJSON(object.booking) : undefined };
  },

  toJSON(message: AddBookingRequest): unknown {
    const obj: any = {};
    if (message.booking !== undefined) {
      obj.booking = UserBooking.toJSON(message.booking);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AddBookingRequest>, I>>(base?: I): AddBookingRequest {
    return AddBookingRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddBookingRequest>, I>>(object: I): AddBookingRequest {
    const message = createBaseAddBookingRequest();
    message.booking = (object.booking !== undefined && object.booking !== null)
      ? UserBooking.fromPartial(object.booking)
      : undefined;
    return message;
  },
};

function createBaseAddBookingResponse(): AddBookingResponse {
  return { booking: undefined };
}

export const AddBookingResponse = {
  encode(message: AddBookingResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.booking !== undefined) {
      UserBooking.encode(message.booking, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddBookingResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddBookingResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.booking = UserBooking.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AddBookingResponse {
    return { booking: isSet(object.booking) ? UserBooking.fromJSON(object.booking) : undefined };
  },

  toJSON(message: AddBookingResponse): unknown {
    const obj: any = {};
    if (message.booking !== undefined) {
      obj.booking = UserBooking.toJSON(message.booking);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AddBookingResponse>, I>>(base?: I): AddBookingResponse {
    return AddBookingResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddBookingResponse>, I>>(object: I): AddBookingResponse {
    const message = createBaseAddBookingResponse();
    message.booking = (object.booking !== undefined && object.booking !== null)
      ? UserBooking.fromPartial(object.booking)
      : undefined;
    return message;
  },
};

function createBaseGetBookingsRequest(): GetBookingsRequest {
  return { userId: "", fromDateTime: "", toDateTime: "" };
}

export const GetBookingsRequest = {
  encode(message: GetBookingsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== "") {
      writer.uint32(10).string(message.userId);
    }
    if (message.fromDateTime !== "") {
      writer.uint32(18).string(message.fromDateTime);
    }
    if (message.toDateTime !== "") {
      writer.uint32(26).string(message.toDateTime);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetBookingsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetBookingsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fromDateTime = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.toDateTime = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetBookingsRequest {
    return {
      userId: isSet(object.userId) ? globalThis.String(object.userId) : "",
      fromDateTime: isSet(object.fromDateTime) ? globalThis.String(object.fromDateTime) : "",
      toDateTime: isSet(object.toDateTime) ? globalThis.String(object.toDateTime) : "",
    };
  },

  toJSON(message: GetBookingsRequest): unknown {
    const obj: any = {};
    if (message.userId !== "") {
      obj.userId = message.userId;
    }
    if (message.fromDateTime !== "") {
      obj.fromDateTime = message.fromDateTime;
    }
    if (message.toDateTime !== "") {
      obj.toDateTime = message.toDateTime;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetBookingsRequest>, I>>(base?: I): GetBookingsRequest {
    return GetBookingsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetBookingsRequest>, I>>(object: I): GetBookingsRequest {
    const message = createBaseGetBookingsRequest();
    message.userId = object.userId ?? "";
    message.fromDateTime = object.fromDateTime ?? "";
    message.toDateTime = object.toDateTime ?? "";
    return message;
  },
};

function createBaseGetBookingsResponse(): GetBookingsResponse {
  return { booking: [] };
}

export const GetBookingsResponse = {
  encode(message: GetBookingsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.booking) {
      UserBookingReport.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetBookingsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetBookingsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.booking.push(UserBookingReport.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetBookingsResponse {
    return {
      booking: globalThis.Array.isArray(object?.booking)
        ? object.booking.map((e: any) => UserBookingReport.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetBookingsResponse): unknown {
    const obj: any = {};
    if (message.booking?.length) {
      obj.booking = message.booking.map((e) => UserBookingReport.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetBookingsResponse>, I>>(base?: I): GetBookingsResponse {
    return GetBookingsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetBookingsResponse>, I>>(object: I): GetBookingsResponse {
    const message = createBaseGetBookingsResponse();
    message.booking = object.booking?.map((e) => UserBookingReport.fromPartial(e)) || [];
    return message;
  },
};

function createBaseRemoveBookingRequest(): RemoveBookingRequest {
  return { id: "", userId: "", vendorId: "" };
}

export const RemoveBookingRequest = {
  encode(message: RemoveBookingRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.userId !== "") {
      writer.uint32(18).string(message.userId);
    }
    if (message.vendorId !== "") {
      writer.uint32(26).string(message.vendorId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveBookingRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveBookingRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.vendorId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RemoveBookingRequest {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      userId: isSet(object.userId) ? globalThis.String(object.userId) : "",
      vendorId: isSet(object.vendorId) ? globalThis.String(object.vendorId) : "",
    };
  },

  toJSON(message: RemoveBookingRequest): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.userId !== "") {
      obj.userId = message.userId;
    }
    if (message.vendorId !== "") {
      obj.vendorId = message.vendorId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RemoveBookingRequest>, I>>(base?: I): RemoveBookingRequest {
    return RemoveBookingRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RemoveBookingRequest>, I>>(object: I): RemoveBookingRequest {
    const message = createBaseRemoveBookingRequest();
    message.id = object.id ?? "";
    message.userId = object.userId ?? "";
    message.vendorId = object.vendorId ?? "";
    return message;
  },
};

function createBaseRemoveBookingResponse(): RemoveBookingResponse {
  return { status: "" };
}

export const RemoveBookingResponse = {
  encode(message: RemoveBookingResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== "") {
      writer.uint32(10).string(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveBookingResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveBookingResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.status = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RemoveBookingResponse {
    return { status: isSet(object.status) ? globalThis.String(object.status) : "" };
  },

  toJSON(message: RemoveBookingResponse): unknown {
    const obj: any = {};
    if (message.status !== "") {
      obj.status = message.status;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RemoveBookingResponse>, I>>(base?: I): RemoveBookingResponse {
    return RemoveBookingResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RemoveBookingResponse>, I>>(object: I): RemoveBookingResponse {
    const message = createBaseRemoveBookingResponse();
    message.status = object.status ?? "";
    return message;
  },
};

export interface UserBookingService {
  addBooking(request: AddBookingRequest): Promise<AddBookingResponse>;
  removeBooking(request: RemoveBookingRequest): Promise<RemoveBookingResponse>;
  getBookings(request: GetBookingsRequest): Promise<GetBookingsResponse>;
}

export const UserBookingServiceServiceName = "anythingpet.userBooking.UserBookingService";
export class UserBookingServiceClientImpl implements UserBookingService {
  private readonly rpc: Rpc;
  private readonly service: string;
  constructor(rpc: Rpc, opts?: { service?: string }) {
    this.service = opts?.service || UserBookingServiceServiceName;
    this.rpc = rpc;
    this.addBooking = this.addBooking.bind(this);
    this.removeBooking = this.removeBooking.bind(this);
    this.getBookings = this.getBookings.bind(this);
  }
  addBooking(request: AddBookingRequest): Promise<AddBookingResponse> {
    const data = AddBookingRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "addBooking", data);
    return promise.then((data) => AddBookingResponse.decode(_m0.Reader.create(data)));
  }

  removeBooking(request: RemoveBookingRequest): Promise<RemoveBookingResponse> {
    const data = RemoveBookingRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "removeBooking", data);
    return promise.then((data) => RemoveBookingResponse.decode(_m0.Reader.create(data)));
  }

  getBookings(request: GetBookingsRequest): Promise<GetBookingsResponse> {
    const data = GetBookingsRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "getBookings", data);
    return promise.then((data) => GetBookingsResponse.decode(_m0.Reader.create(data)));
  }
}

interface Rpc {
  request(service: string, method: string, data: Uint8Array): Promise<Uint8Array>;
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
