/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "anythingpet.offering";

export interface Offering {
  id: string;
  vendorId: string;
  name: string;
  /** How long the offering will take (in minutes) */
  duration: number;
  price: number;
}

export interface GetOfferingsRequest {
  vendorId: string;
}

export interface GetOfferingsResponse {
  offering: Offering[];
}

export interface GetOfferingRequest {
  id: string;
  vendorId: string;
}

export interface GetOfferingResponse {
  offering: Offering | undefined;
}

function createBaseOffering(): Offering {
  return { id: "", vendorId: "", name: "", duration: 0, price: 0 };
}

export const Offering = {
  encode(message: Offering, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.vendorId !== "") {
      writer.uint32(18).string(message.vendorId);
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    if (message.duration !== 0) {
      writer.uint32(32).int32(message.duration);
    }
    if (message.price !== 0) {
      writer.uint32(45).float(message.price);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Offering {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOffering();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.vendorId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.duration = reader.int32();
          continue;
        case 5:
          if (tag !== 45) {
            break;
          }

          message.price = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Offering {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      vendorId: isSet(object.vendorId) ? globalThis.String(object.vendorId) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      duration: isSet(object.duration) ? globalThis.Number(object.duration) : 0,
      price: isSet(object.price) ? globalThis.Number(object.price) : 0,
    };
  },

  toJSON(message: Offering): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.vendorId !== "") {
      obj.vendorId = message.vendorId;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.duration !== 0) {
      obj.duration = Math.round(message.duration);
    }
    if (message.price !== 0) {
      obj.price = message.price;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Offering>, I>>(base?: I): Offering {
    return Offering.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Offering>, I>>(object: I): Offering {
    const message = createBaseOffering();
    message.id = object.id ?? "";
    message.vendorId = object.vendorId ?? "";
    message.name = object.name ?? "";
    message.duration = object.duration ?? 0;
    message.price = object.price ?? 0;
    return message;
  },
};

function createBaseGetOfferingsRequest(): GetOfferingsRequest {
  return { vendorId: "" };
}

export const GetOfferingsRequest = {
  encode(message: GetOfferingsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.vendorId !== "") {
      writer.uint32(10).string(message.vendorId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetOfferingsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetOfferingsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.vendorId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetOfferingsRequest {
    return { vendorId: isSet(object.vendorId) ? globalThis.String(object.vendorId) : "" };
  },

  toJSON(message: GetOfferingsRequest): unknown {
    const obj: any = {};
    if (message.vendorId !== "") {
      obj.vendorId = message.vendorId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetOfferingsRequest>, I>>(base?: I): GetOfferingsRequest {
    return GetOfferingsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetOfferingsRequest>, I>>(object: I): GetOfferingsRequest {
    const message = createBaseGetOfferingsRequest();
    message.vendorId = object.vendorId ?? "";
    return message;
  },
};

function createBaseGetOfferingsResponse(): GetOfferingsResponse {
  return { offering: [] };
}

export const GetOfferingsResponse = {
  encode(message: GetOfferingsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.offering) {
      Offering.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetOfferingsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetOfferingsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.offering.push(Offering.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetOfferingsResponse {
    return {
      offering: globalThis.Array.isArray(object?.offering) ? object.offering.map((e: any) => Offering.fromJSON(e)) : [],
    };
  },

  toJSON(message: GetOfferingsResponse): unknown {
    const obj: any = {};
    if (message.offering?.length) {
      obj.offering = message.offering.map((e) => Offering.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetOfferingsResponse>, I>>(base?: I): GetOfferingsResponse {
    return GetOfferingsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetOfferingsResponse>, I>>(object: I): GetOfferingsResponse {
    const message = createBaseGetOfferingsResponse();
    message.offering = object.offering?.map((e) => Offering.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetOfferingRequest(): GetOfferingRequest {
  return { id: "", vendorId: "" };
}

export const GetOfferingRequest = {
  encode(message: GetOfferingRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.vendorId !== "") {
      writer.uint32(18).string(message.vendorId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetOfferingRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetOfferingRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.vendorId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetOfferingRequest {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      vendorId: isSet(object.vendorId) ? globalThis.String(object.vendorId) : "",
    };
  },

  toJSON(message: GetOfferingRequest): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.vendorId !== "") {
      obj.vendorId = message.vendorId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetOfferingRequest>, I>>(base?: I): GetOfferingRequest {
    return GetOfferingRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetOfferingRequest>, I>>(object: I): GetOfferingRequest {
    const message = createBaseGetOfferingRequest();
    message.id = object.id ?? "";
    message.vendorId = object.vendorId ?? "";
    return message;
  },
};

function createBaseGetOfferingResponse(): GetOfferingResponse {
  return { offering: undefined };
}

export const GetOfferingResponse = {
  encode(message: GetOfferingResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.offering !== undefined) {
      Offering.encode(message.offering, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetOfferingResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetOfferingResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.offering = Offering.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetOfferingResponse {
    return { offering: isSet(object.offering) ? Offering.fromJSON(object.offering) : undefined };
  },

  toJSON(message: GetOfferingResponse): unknown {
    const obj: any = {};
    if (message.offering !== undefined) {
      obj.offering = Offering.toJSON(message.offering);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetOfferingResponse>, I>>(base?: I): GetOfferingResponse {
    return GetOfferingResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetOfferingResponse>, I>>(object: I): GetOfferingResponse {
    const message = createBaseGetOfferingResponse();
    message.offering = (object.offering !== undefined && object.offering !== null)
      ? Offering.fromPartial(object.offering)
      : undefined;
    return message;
  },
};

export interface OfferingService {
  getOfferings(request: GetOfferingsRequest): Promise<GetOfferingsResponse>;
  getOffering(request: GetOfferingRequest): Promise<GetOfferingResponse>;
}

export const OfferingServiceServiceName = "anythingpet.offering.OfferingService";
export class OfferingServiceClientImpl implements OfferingService {
  private readonly rpc: Rpc;
  private readonly service: string;
  constructor(rpc: Rpc, opts?: { service?: string }) {
    this.service = opts?.service || OfferingServiceServiceName;
    this.rpc = rpc;
    this.getOfferings = this.getOfferings.bind(this);
    this.getOffering = this.getOffering.bind(this);
  }
  getOfferings(request: GetOfferingsRequest): Promise<GetOfferingsResponse> {
    const data = GetOfferingsRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "getOfferings", data);
    return promise.then((data) => GetOfferingsResponse.decode(_m0.Reader.create(data)));
  }

  getOffering(request: GetOfferingRequest): Promise<GetOfferingResponse> {
    const data = GetOfferingRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "getOffering", data);
    return promise.then((data) => GetOfferingResponse.decode(_m0.Reader.create(data)));
  }
}

interface Rpc {
  request(service: string, method: string, data: Uint8Array): Promise<Uint8Array>;
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
