import { AuthenticatedTemplate, UnauthenticatedTemplate, useAuthManager } from "../components/Auth";
import React, { useEffect } from "react";
import { LinearProgress } from '@mui/material';
import { SocketIOProvider } from "../components/SocketIO";
import { Route, Routes } from "react-router-dom";
import HomePage from "./HomePage";
import UserProfilePage from "./UserProfilePage";
import { VendorHomePage } from "./VendorHomePage";


const RequireAuthentication : React.FC = () => {
    const authService = useAuthManager();
    const isLoading = (! authService);

    useEffect(() => {
        if (! isLoading) {
            authService.loginWithRedirect();
        }

    }, [authService, isLoading]);

    return <LinearProgress />
}

export function UserHomePage() {
    return (<>
        <AuthenticatedTemplate>
            <SocketIOProvider>
                <Routes>
                    <Route path="" element={<HomePage />} />
                    <Route path="profile" element={<UserProfilePage />} />
                    <Route path="v/:vendor/*" element={<VendorHomePage />} />
                </Routes>
            </SocketIOProvider>                
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
            <RequireAuthentication />
        </UnauthenticatedTemplate>    
    </>        
    )
}