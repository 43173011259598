import React, { useState } from "react";
import PetListing from "../components/PetListing";
import BookingList from "../components/BookingList";
import { Box, Tabs, Tab } from "@mui/material";

const HomePage: React.FC = () => {
    const [tabIndex, setTabIndex] = useState(0);

    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setTabIndex(newValue);
    };

    return (
        <Box sx={{ paddingTop: 1}}>
            <Tabs value={tabIndex} onChange={handleChangeTab}>
                <Tab label="Upcoming Bookings" />
                <Tab label="My Pets" />
            </Tabs>

            {tabIndex === 1 && <PetListing />}

            {tabIndex === 0 && <BookingList />}
        </Box>
    );
};

export default HomePage;
