import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import { AuthProvider, LoginCallback } from "./components/Auth";
import { Box, Divider, Container, CssBaseline } from "@mui/material";
import NavBar from "./components/NavBar";
import SignInPage from "./pages/SignInPage";
import SignUpPage from "./pages/SignUpPage";
import { TestHome } from "./pages/TestHome";
import { UserHomePage } from "./pages/UserHomePage";
import LogoutPage from "./pages/LogoutPage";
import { AnonymousHomePage } from "./pages/AnonymousHomePage";
import { ThemeProvider } from "@mui/material/styles";
import Theme from "./components/Theme";
import SiteFooter from "./components/SiteFooter";
import { VendorHomePage } from "./pages/VendorHomePage";

const link = document.createElement("link");
link.href =
    "https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap";
link.rel = "stylesheet";
document.head.appendChild(link);

const App = () => {
    return (
        <Router>
            <AuthProvider>
                <ThemeProvider theme={Theme}>
                    <CssBaseline />
                    <NavBar />
                    <Container>
                        <Routes>
                            <Route path="/" element={<AnonymousHomePage />} />
                            <Route path="/user/*" element={<UserHomePage />} />
                            <Route path="/signin" element={<SignInPage />} />
                            <Route path="/signup" element={<SignUpPage />} />
                            <Route path="/logout" element={<LogoutPage />} />
                            <Route path="/book/:vendor/*" element={<VendorHomePage />} />

                            {/* For auth integration */}
                            <Route
                                path="/callback"
                                element={<LoginCallback />}
                            />
                            <Route path="/test-home/*" element={<TestHome />} />

                            {/* Add more routes here */}
                        </Routes>
                    </Container>
                    <Box paddingTop={"40px"} paddingBottom={"40px"}>
                        <Divider></Divider>
                    </Box>
                    <Container>
                        <SiteFooter />
                    </Container>
                </ThemeProvider>
            </AuthProvider>
        </Router>
    );
};

export default App;
