import { useParams } from "react-router-dom";
import { useVendorScope } from "../components/VendorScopeProvider";
import { useSocketIO } from "../components/SocketIO";
import { useEffect, useState } from "react";
import { offering } from "@anything-pet/grpc-lib";
import { LinearProgress } from "@mui/material";
import { getOfferingService } from "../services/OfferingServiceProxy";
import { BookingFormStateProvider } from "./BookOfferingState";
import { BookingFormSteps } from "./BookingFormSteps";


export function BookingForm() {
    const { id: vendorId, code : vendorCode } = useVendorScope();
    const { offeringId } = useParams(); 
 
    const socket = useSocketIO();

    const [ offering, setOffering ] = useState<offering.Offering | null>();

    useEffect(() => {
        (async () => {
            if (offeringId) {
                const service = getOfferingService(socket);

                const {offering: result} = await service.getOffering({ vendorId: vendorId, id: offeringId });

                setOffering(result ?? null);
            }
        })();
    }, [offeringId])
    
    if (! offeringId) {
        return <h1>Missing offering id</h1>
    }

    if (offering === null) {
        return <h1>Service cannot be loaded</h1>
    }

    if (! offering) {
        return <LinearProgress />
    }

    return (
        <BookingFormStateProvider offeringId={offeringId} urlPrefix={ `/book/${vendorCode}` }>
            <BookingFormSteps offering={offering} />
        </BookingFormStateProvider>
    )
}