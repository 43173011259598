/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "anythingpet.offeringAdmin";

export interface OfferingAdmin {
  id: string;
  vendorId: string;
  name: string;
  /** How long the offering will take (in minutes) */
  duration: number;
  price: number;
}

export interface OfferingAdminForUpdate {
  id: string;
  vendorId: string;
  name?: string | undefined;
  duration?: number | undefined;
  price?: number | undefined;
}

export interface GetOfferingAdminsRequest {
  vendorId: string;
}

export interface GetOfferingAdminsResponse {
  offering: OfferingAdmin[];
}

export interface GetOfferingAdminRequest {
  id: string;
  vendorId: string;
}

export interface GetOfferingAdminResponse {
  offering: OfferingAdmin | undefined;
}

export interface AddOfferingAdminRequest {
  offering: OfferingAdmin | undefined;
}

export interface AddOfferingAdminResponse {
  offering: OfferingAdmin | undefined;
}

export interface UpdateOfferingAdminRequest {
  offering: OfferingAdminForUpdate | undefined;
}

export interface UpdateOfferingAdminResponse {
  status: string;
}

export interface RemoveOfferingAdminRequest {
  id: string;
  vendorId: string;
}

export interface RemoveOfferingAdminResponse {
  status: string;
}

function createBaseOfferingAdmin(): OfferingAdmin {
  return { id: "", vendorId: "", name: "", duration: 0, price: 0 };
}

export const OfferingAdmin = {
  encode(message: OfferingAdmin, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.vendorId !== "") {
      writer.uint32(18).string(message.vendorId);
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    if (message.duration !== 0) {
      writer.uint32(32).int32(message.duration);
    }
    if (message.price !== 0) {
      writer.uint32(45).float(message.price);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OfferingAdmin {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOfferingAdmin();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.vendorId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.duration = reader.int32();
          continue;
        case 5:
          if (tag !== 45) {
            break;
          }

          message.price = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OfferingAdmin {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      vendorId: isSet(object.vendorId) ? globalThis.String(object.vendorId) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      duration: isSet(object.duration) ? globalThis.Number(object.duration) : 0,
      price: isSet(object.price) ? globalThis.Number(object.price) : 0,
    };
  },

  toJSON(message: OfferingAdmin): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.vendorId !== "") {
      obj.vendorId = message.vendorId;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.duration !== 0) {
      obj.duration = Math.round(message.duration);
    }
    if (message.price !== 0) {
      obj.price = message.price;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OfferingAdmin>, I>>(base?: I): OfferingAdmin {
    return OfferingAdmin.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OfferingAdmin>, I>>(object: I): OfferingAdmin {
    const message = createBaseOfferingAdmin();
    message.id = object.id ?? "";
    message.vendorId = object.vendorId ?? "";
    message.name = object.name ?? "";
    message.duration = object.duration ?? 0;
    message.price = object.price ?? 0;
    return message;
  },
};

function createBaseOfferingAdminForUpdate(): OfferingAdminForUpdate {
  return { id: "", vendorId: "", name: undefined, duration: undefined, price: undefined };
}

export const OfferingAdminForUpdate = {
  encode(message: OfferingAdminForUpdate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.vendorId !== "") {
      writer.uint32(18).string(message.vendorId);
    }
    if (message.name !== undefined) {
      writer.uint32(26).string(message.name);
    }
    if (message.duration !== undefined) {
      writer.uint32(32).int32(message.duration);
    }
    if (message.price !== undefined) {
      writer.uint32(45).float(message.price);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OfferingAdminForUpdate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOfferingAdminForUpdate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.vendorId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.duration = reader.int32();
          continue;
        case 5:
          if (tag !== 45) {
            break;
          }

          message.price = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OfferingAdminForUpdate {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      vendorId: isSet(object.vendorId) ? globalThis.String(object.vendorId) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : undefined,
      duration: isSet(object.duration) ? globalThis.Number(object.duration) : undefined,
      price: isSet(object.price) ? globalThis.Number(object.price) : undefined,
    };
  },

  toJSON(message: OfferingAdminForUpdate): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.vendorId !== "") {
      obj.vendorId = message.vendorId;
    }
    if (message.name !== undefined) {
      obj.name = message.name;
    }
    if (message.duration !== undefined) {
      obj.duration = Math.round(message.duration);
    }
    if (message.price !== undefined) {
      obj.price = message.price;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OfferingAdminForUpdate>, I>>(base?: I): OfferingAdminForUpdate {
    return OfferingAdminForUpdate.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OfferingAdminForUpdate>, I>>(object: I): OfferingAdminForUpdate {
    const message = createBaseOfferingAdminForUpdate();
    message.id = object.id ?? "";
    message.vendorId = object.vendorId ?? "";
    message.name = object.name ?? undefined;
    message.duration = object.duration ?? undefined;
    message.price = object.price ?? undefined;
    return message;
  },
};

function createBaseGetOfferingAdminsRequest(): GetOfferingAdminsRequest {
  return { vendorId: "" };
}

export const GetOfferingAdminsRequest = {
  encode(message: GetOfferingAdminsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.vendorId !== "") {
      writer.uint32(10).string(message.vendorId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetOfferingAdminsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetOfferingAdminsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.vendorId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetOfferingAdminsRequest {
    return { vendorId: isSet(object.vendorId) ? globalThis.String(object.vendorId) : "" };
  },

  toJSON(message: GetOfferingAdminsRequest): unknown {
    const obj: any = {};
    if (message.vendorId !== "") {
      obj.vendorId = message.vendorId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetOfferingAdminsRequest>, I>>(base?: I): GetOfferingAdminsRequest {
    return GetOfferingAdminsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetOfferingAdminsRequest>, I>>(object: I): GetOfferingAdminsRequest {
    const message = createBaseGetOfferingAdminsRequest();
    message.vendorId = object.vendorId ?? "";
    return message;
  },
};

function createBaseGetOfferingAdminsResponse(): GetOfferingAdminsResponse {
  return { offering: [] };
}

export const GetOfferingAdminsResponse = {
  encode(message: GetOfferingAdminsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.offering) {
      OfferingAdmin.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetOfferingAdminsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetOfferingAdminsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.offering.push(OfferingAdmin.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetOfferingAdminsResponse {
    return {
      offering: globalThis.Array.isArray(object?.offering)
        ? object.offering.map((e: any) => OfferingAdmin.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetOfferingAdminsResponse): unknown {
    const obj: any = {};
    if (message.offering?.length) {
      obj.offering = message.offering.map((e) => OfferingAdmin.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetOfferingAdminsResponse>, I>>(base?: I): GetOfferingAdminsResponse {
    return GetOfferingAdminsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetOfferingAdminsResponse>, I>>(object: I): GetOfferingAdminsResponse {
    const message = createBaseGetOfferingAdminsResponse();
    message.offering = object.offering?.map((e) => OfferingAdmin.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetOfferingAdminRequest(): GetOfferingAdminRequest {
  return { id: "", vendorId: "" };
}

export const GetOfferingAdminRequest = {
  encode(message: GetOfferingAdminRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.vendorId !== "") {
      writer.uint32(18).string(message.vendorId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetOfferingAdminRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetOfferingAdminRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.vendorId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetOfferingAdminRequest {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      vendorId: isSet(object.vendorId) ? globalThis.String(object.vendorId) : "",
    };
  },

  toJSON(message: GetOfferingAdminRequest): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.vendorId !== "") {
      obj.vendorId = message.vendorId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetOfferingAdminRequest>, I>>(base?: I): GetOfferingAdminRequest {
    return GetOfferingAdminRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetOfferingAdminRequest>, I>>(object: I): GetOfferingAdminRequest {
    const message = createBaseGetOfferingAdminRequest();
    message.id = object.id ?? "";
    message.vendorId = object.vendorId ?? "";
    return message;
  },
};

function createBaseGetOfferingAdminResponse(): GetOfferingAdminResponse {
  return { offering: undefined };
}

export const GetOfferingAdminResponse = {
  encode(message: GetOfferingAdminResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.offering !== undefined) {
      OfferingAdmin.encode(message.offering, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetOfferingAdminResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetOfferingAdminResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.offering = OfferingAdmin.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetOfferingAdminResponse {
    return { offering: isSet(object.offering) ? OfferingAdmin.fromJSON(object.offering) : undefined };
  },

  toJSON(message: GetOfferingAdminResponse): unknown {
    const obj: any = {};
    if (message.offering !== undefined) {
      obj.offering = OfferingAdmin.toJSON(message.offering);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetOfferingAdminResponse>, I>>(base?: I): GetOfferingAdminResponse {
    return GetOfferingAdminResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetOfferingAdminResponse>, I>>(object: I): GetOfferingAdminResponse {
    const message = createBaseGetOfferingAdminResponse();
    message.offering = (object.offering !== undefined && object.offering !== null)
      ? OfferingAdmin.fromPartial(object.offering)
      : undefined;
    return message;
  },
};

function createBaseAddOfferingAdminRequest(): AddOfferingAdminRequest {
  return { offering: undefined };
}

export const AddOfferingAdminRequest = {
  encode(message: AddOfferingAdminRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.offering !== undefined) {
      OfferingAdmin.encode(message.offering, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddOfferingAdminRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddOfferingAdminRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.offering = OfferingAdmin.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AddOfferingAdminRequest {
    return { offering: isSet(object.offering) ? OfferingAdmin.fromJSON(object.offering) : undefined };
  },

  toJSON(message: AddOfferingAdminRequest): unknown {
    const obj: any = {};
    if (message.offering !== undefined) {
      obj.offering = OfferingAdmin.toJSON(message.offering);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AddOfferingAdminRequest>, I>>(base?: I): AddOfferingAdminRequest {
    return AddOfferingAdminRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddOfferingAdminRequest>, I>>(object: I): AddOfferingAdminRequest {
    const message = createBaseAddOfferingAdminRequest();
    message.offering = (object.offering !== undefined && object.offering !== null)
      ? OfferingAdmin.fromPartial(object.offering)
      : undefined;
    return message;
  },
};

function createBaseAddOfferingAdminResponse(): AddOfferingAdminResponse {
  return { offering: undefined };
}

export const AddOfferingAdminResponse = {
  encode(message: AddOfferingAdminResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.offering !== undefined) {
      OfferingAdmin.encode(message.offering, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddOfferingAdminResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddOfferingAdminResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.offering = OfferingAdmin.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AddOfferingAdminResponse {
    return { offering: isSet(object.offering) ? OfferingAdmin.fromJSON(object.offering) : undefined };
  },

  toJSON(message: AddOfferingAdminResponse): unknown {
    const obj: any = {};
    if (message.offering !== undefined) {
      obj.offering = OfferingAdmin.toJSON(message.offering);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AddOfferingAdminResponse>, I>>(base?: I): AddOfferingAdminResponse {
    return AddOfferingAdminResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddOfferingAdminResponse>, I>>(object: I): AddOfferingAdminResponse {
    const message = createBaseAddOfferingAdminResponse();
    message.offering = (object.offering !== undefined && object.offering !== null)
      ? OfferingAdmin.fromPartial(object.offering)
      : undefined;
    return message;
  },
};

function createBaseUpdateOfferingAdminRequest(): UpdateOfferingAdminRequest {
  return { offering: undefined };
}

export const UpdateOfferingAdminRequest = {
  encode(message: UpdateOfferingAdminRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.offering !== undefined) {
      OfferingAdminForUpdate.encode(message.offering, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateOfferingAdminRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateOfferingAdminRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.offering = OfferingAdminForUpdate.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateOfferingAdminRequest {
    return { offering: isSet(object.offering) ? OfferingAdminForUpdate.fromJSON(object.offering) : undefined };
  },

  toJSON(message: UpdateOfferingAdminRequest): unknown {
    const obj: any = {};
    if (message.offering !== undefined) {
      obj.offering = OfferingAdminForUpdate.toJSON(message.offering);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateOfferingAdminRequest>, I>>(base?: I): UpdateOfferingAdminRequest {
    return UpdateOfferingAdminRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateOfferingAdminRequest>, I>>(object: I): UpdateOfferingAdminRequest {
    const message = createBaseUpdateOfferingAdminRequest();
    message.offering = (object.offering !== undefined && object.offering !== null)
      ? OfferingAdminForUpdate.fromPartial(object.offering)
      : undefined;
    return message;
  },
};

function createBaseUpdateOfferingAdminResponse(): UpdateOfferingAdminResponse {
  return { status: "" };
}

export const UpdateOfferingAdminResponse = {
  encode(message: UpdateOfferingAdminResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== "") {
      writer.uint32(10).string(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateOfferingAdminResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateOfferingAdminResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.status = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateOfferingAdminResponse {
    return { status: isSet(object.status) ? globalThis.String(object.status) : "" };
  },

  toJSON(message: UpdateOfferingAdminResponse): unknown {
    const obj: any = {};
    if (message.status !== "") {
      obj.status = message.status;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateOfferingAdminResponse>, I>>(base?: I): UpdateOfferingAdminResponse {
    return UpdateOfferingAdminResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateOfferingAdminResponse>, I>>(object: I): UpdateOfferingAdminResponse {
    const message = createBaseUpdateOfferingAdminResponse();
    message.status = object.status ?? "";
    return message;
  },
};

function createBaseRemoveOfferingAdminRequest(): RemoveOfferingAdminRequest {
  return { id: "", vendorId: "" };
}

export const RemoveOfferingAdminRequest = {
  encode(message: RemoveOfferingAdminRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.vendorId !== "") {
      writer.uint32(18).string(message.vendorId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveOfferingAdminRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveOfferingAdminRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.vendorId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RemoveOfferingAdminRequest {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      vendorId: isSet(object.vendorId) ? globalThis.String(object.vendorId) : "",
    };
  },

  toJSON(message: RemoveOfferingAdminRequest): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.vendorId !== "") {
      obj.vendorId = message.vendorId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RemoveOfferingAdminRequest>, I>>(base?: I): RemoveOfferingAdminRequest {
    return RemoveOfferingAdminRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RemoveOfferingAdminRequest>, I>>(object: I): RemoveOfferingAdminRequest {
    const message = createBaseRemoveOfferingAdminRequest();
    message.id = object.id ?? "";
    message.vendorId = object.vendorId ?? "";
    return message;
  },
};

function createBaseRemoveOfferingAdminResponse(): RemoveOfferingAdminResponse {
  return { status: "" };
}

export const RemoveOfferingAdminResponse = {
  encode(message: RemoveOfferingAdminResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== "") {
      writer.uint32(10).string(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveOfferingAdminResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveOfferingAdminResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.status = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RemoveOfferingAdminResponse {
    return { status: isSet(object.status) ? globalThis.String(object.status) : "" };
  },

  toJSON(message: RemoveOfferingAdminResponse): unknown {
    const obj: any = {};
    if (message.status !== "") {
      obj.status = message.status;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RemoveOfferingAdminResponse>, I>>(base?: I): RemoveOfferingAdminResponse {
    return RemoveOfferingAdminResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RemoveOfferingAdminResponse>, I>>(object: I): RemoveOfferingAdminResponse {
    const message = createBaseRemoveOfferingAdminResponse();
    message.status = object.status ?? "";
    return message;
  },
};

export interface OfferingAdminService {
  getOfferingAdmins(request: GetOfferingAdminsRequest): Promise<GetOfferingAdminsResponse>;
  getOfferingAdmin(request: GetOfferingAdminRequest): Promise<GetOfferingAdminResponse>;
  addOfferingAdmin(request: AddOfferingAdminRequest): Promise<AddOfferingAdminResponse>;
  updateOfferingAdmin(request: UpdateOfferingAdminRequest): Promise<UpdateOfferingAdminResponse>;
  removeOfferingAdmin(request: RemoveOfferingAdminRequest): Promise<RemoveOfferingAdminResponse>;
}

export const OfferingAdminServiceServiceName = "anythingpet.offeringAdmin.OfferingAdminService";
export class OfferingAdminServiceClientImpl implements OfferingAdminService {
  private readonly rpc: Rpc;
  private readonly service: string;
  constructor(rpc: Rpc, opts?: { service?: string }) {
    this.service = opts?.service || OfferingAdminServiceServiceName;
    this.rpc = rpc;
    this.getOfferingAdmins = this.getOfferingAdmins.bind(this);
    this.getOfferingAdmin = this.getOfferingAdmin.bind(this);
    this.addOfferingAdmin = this.addOfferingAdmin.bind(this);
    this.updateOfferingAdmin = this.updateOfferingAdmin.bind(this);
    this.removeOfferingAdmin = this.removeOfferingAdmin.bind(this);
  }
  getOfferingAdmins(request: GetOfferingAdminsRequest): Promise<GetOfferingAdminsResponse> {
    const data = GetOfferingAdminsRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "getOfferingAdmins", data);
    return promise.then((data) => GetOfferingAdminsResponse.decode(_m0.Reader.create(data)));
  }

  getOfferingAdmin(request: GetOfferingAdminRequest): Promise<GetOfferingAdminResponse> {
    const data = GetOfferingAdminRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "getOfferingAdmin", data);
    return promise.then((data) => GetOfferingAdminResponse.decode(_m0.Reader.create(data)));
  }

  addOfferingAdmin(request: AddOfferingAdminRequest): Promise<AddOfferingAdminResponse> {
    const data = AddOfferingAdminRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "addOfferingAdmin", data);
    return promise.then((data) => AddOfferingAdminResponse.decode(_m0.Reader.create(data)));
  }

  updateOfferingAdmin(request: UpdateOfferingAdminRequest): Promise<UpdateOfferingAdminResponse> {
    const data = UpdateOfferingAdminRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "updateOfferingAdmin", data);
    return promise.then((data) => UpdateOfferingAdminResponse.decode(_m0.Reader.create(data)));
  }

  removeOfferingAdmin(request: RemoveOfferingAdminRequest): Promise<RemoveOfferingAdminResponse> {
    const data = RemoveOfferingAdminRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "removeOfferingAdmin", data);
    return promise.then((data) => RemoveOfferingAdminResponse.decode(_m0.Reader.create(data)));
  }
}

interface Rpc {
  request(service: string, method: string, data: Uint8Array): Promise<Uint8Array>;
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
