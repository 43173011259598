import * as userProfile from './proto/userprofile'
import * as vendor from './proto/vendor'
import * as vendorAdmin from './proto/vendorAdmin'
import * as staff from './proto/staff'
import * as staffAdmin from './proto/staffAdmin'
import * as offering from './proto/offering'
import * as offeringAdmin from './proto/offeringAdmin'
import * as pet from './proto/pet'
import * as booking from './proto/booking'
import * as userBooking from './proto/userBooking';
import * as staffBooking from './proto/staffBooking';
import { getOfficeHoursAsArray } from './vendor-utils'

/**
 * 
 * @param componentName 
 */
export function getProtoPath(componentName : string) {
    const protoPath = `${__dirname}/${componentName}.proto`;

    return protoPath;
}

export {
    userProfile,
    vendor,
    vendorAdmin,
    staff,
    staffAdmin,
    offering,
    offeringAdmin,
    pet,
    booking,
    userBooking,
    staffBooking,
    getOfficeHoursAsArray
} 