/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "anythingpet.pet";

export interface Pet {
  id: string;
  consumerId: string;
  name: string;
  petType: string;
  birthDate: string;
  gender?: string | undefined;
  breed?: string | undefined;
  hairType?: string | undefined;
  hairColor?: string | undefined;
  likesDislikes?: string | undefined;
}

export interface PetForUpdate {
  id: string;
  consumerId: string;
  name?: string | undefined;
  petType?: string | undefined;
  birthDate?: string | undefined;
  gender?: string | undefined;
  breed?: string | undefined;
  hairType?: string | undefined;
  hairColor?: string | undefined;
  likesDislikes?: string | undefined;
}

export interface GetPetsRequest {
  consumerId: string;
}

export interface GetPetsResponse {
  pet: Pet[];
}

export interface GetPetRequest {
  id: string;
  consumerId: string;
}

export interface GetPetResponse {
  pet: Pet | undefined;
}

export interface AddPetRequest {
  pet: Pet | undefined;
}

export interface AddPetResponse {
  pet: Pet | undefined;
}

export interface UpdatePetRequest {
  pet: PetForUpdate | undefined;
}

export interface UpdatePetResponse {
  status: string;
}

export interface RemovePetRequest {
  id: string;
  consumerId: string;
}

export interface RemovePetResponse {
  status: string;
}

function createBasePet(): Pet {
  return {
    id: "",
    consumerId: "",
    name: "",
    petType: "",
    birthDate: "",
    gender: undefined,
    breed: undefined,
    hairType: undefined,
    hairColor: undefined,
    likesDislikes: undefined,
  };
}

export const Pet = {
  encode(message: Pet, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.consumerId !== "") {
      writer.uint32(18).string(message.consumerId);
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    if (message.petType !== "") {
      writer.uint32(34).string(message.petType);
    }
    if (message.birthDate !== "") {
      writer.uint32(42).string(message.birthDate);
    }
    if (message.gender !== undefined) {
      writer.uint32(50).string(message.gender);
    }
    if (message.breed !== undefined) {
      writer.uint32(58).string(message.breed);
    }
    if (message.hairType !== undefined) {
      writer.uint32(66).string(message.hairType);
    }
    if (message.hairColor !== undefined) {
      writer.uint32(74).string(message.hairColor);
    }
    if (message.likesDislikes !== undefined) {
      writer.uint32(82).string(message.likesDislikes);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Pet {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePet();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.consumerId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.petType = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.birthDate = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.gender = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.breed = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.hairType = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.hairColor = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.likesDislikes = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Pet {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      consumerId: isSet(object.consumerId) ? globalThis.String(object.consumerId) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      petType: isSet(object.petType) ? globalThis.String(object.petType) : "",
      birthDate: isSet(object.birthDate) ? globalThis.String(object.birthDate) : "",
      gender: isSet(object.gender) ? globalThis.String(object.gender) : undefined,
      breed: isSet(object.breed) ? globalThis.String(object.breed) : undefined,
      hairType: isSet(object.hairType) ? globalThis.String(object.hairType) : undefined,
      hairColor: isSet(object.hairColor) ? globalThis.String(object.hairColor) : undefined,
      likesDislikes: isSet(object.likesDislikes) ? globalThis.String(object.likesDislikes) : undefined,
    };
  },

  toJSON(message: Pet): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.consumerId !== "") {
      obj.consumerId = message.consumerId;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.petType !== "") {
      obj.petType = message.petType;
    }
    if (message.birthDate !== "") {
      obj.birthDate = message.birthDate;
    }
    if (message.gender !== undefined) {
      obj.gender = message.gender;
    }
    if (message.breed !== undefined) {
      obj.breed = message.breed;
    }
    if (message.hairType !== undefined) {
      obj.hairType = message.hairType;
    }
    if (message.hairColor !== undefined) {
      obj.hairColor = message.hairColor;
    }
    if (message.likesDislikes !== undefined) {
      obj.likesDislikes = message.likesDislikes;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Pet>, I>>(base?: I): Pet {
    return Pet.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Pet>, I>>(object: I): Pet {
    const message = createBasePet();
    message.id = object.id ?? "";
    message.consumerId = object.consumerId ?? "";
    message.name = object.name ?? "";
    message.petType = object.petType ?? "";
    message.birthDate = object.birthDate ?? "";
    message.gender = object.gender ?? undefined;
    message.breed = object.breed ?? undefined;
    message.hairType = object.hairType ?? undefined;
    message.hairColor = object.hairColor ?? undefined;
    message.likesDislikes = object.likesDislikes ?? undefined;
    return message;
  },
};

function createBasePetForUpdate(): PetForUpdate {
  return {
    id: "",
    consumerId: "",
    name: undefined,
    petType: undefined,
    birthDate: undefined,
    gender: undefined,
    breed: undefined,
    hairType: undefined,
    hairColor: undefined,
    likesDislikes: undefined,
  };
}

export const PetForUpdate = {
  encode(message: PetForUpdate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.consumerId !== "") {
      writer.uint32(18).string(message.consumerId);
    }
    if (message.name !== undefined) {
      writer.uint32(26).string(message.name);
    }
    if (message.petType !== undefined) {
      writer.uint32(34).string(message.petType);
    }
    if (message.birthDate !== undefined) {
      writer.uint32(42).string(message.birthDate);
    }
    if (message.gender !== undefined) {
      writer.uint32(50).string(message.gender);
    }
    if (message.breed !== undefined) {
      writer.uint32(58).string(message.breed);
    }
    if (message.hairType !== undefined) {
      writer.uint32(66).string(message.hairType);
    }
    if (message.hairColor !== undefined) {
      writer.uint32(74).string(message.hairColor);
    }
    if (message.likesDislikes !== undefined) {
      writer.uint32(82).string(message.likesDislikes);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PetForUpdate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePetForUpdate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.consumerId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.petType = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.birthDate = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.gender = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.breed = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.hairType = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.hairColor = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.likesDislikes = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PetForUpdate {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      consumerId: isSet(object.consumerId) ? globalThis.String(object.consumerId) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : undefined,
      petType: isSet(object.petType) ? globalThis.String(object.petType) : undefined,
      birthDate: isSet(object.birthDate) ? globalThis.String(object.birthDate) : undefined,
      gender: isSet(object.gender) ? globalThis.String(object.gender) : undefined,
      breed: isSet(object.breed) ? globalThis.String(object.breed) : undefined,
      hairType: isSet(object.hairType) ? globalThis.String(object.hairType) : undefined,
      hairColor: isSet(object.hairColor) ? globalThis.String(object.hairColor) : undefined,
      likesDislikes: isSet(object.likesDislikes) ? globalThis.String(object.likesDislikes) : undefined,
    };
  },

  toJSON(message: PetForUpdate): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.consumerId !== "") {
      obj.consumerId = message.consumerId;
    }
    if (message.name !== undefined) {
      obj.name = message.name;
    }
    if (message.petType !== undefined) {
      obj.petType = message.petType;
    }
    if (message.birthDate !== undefined) {
      obj.birthDate = message.birthDate;
    }
    if (message.gender !== undefined) {
      obj.gender = message.gender;
    }
    if (message.breed !== undefined) {
      obj.breed = message.breed;
    }
    if (message.hairType !== undefined) {
      obj.hairType = message.hairType;
    }
    if (message.hairColor !== undefined) {
      obj.hairColor = message.hairColor;
    }
    if (message.likesDislikes !== undefined) {
      obj.likesDislikes = message.likesDislikes;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PetForUpdate>, I>>(base?: I): PetForUpdate {
    return PetForUpdate.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PetForUpdate>, I>>(object: I): PetForUpdate {
    const message = createBasePetForUpdate();
    message.id = object.id ?? "";
    message.consumerId = object.consumerId ?? "";
    message.name = object.name ?? undefined;
    message.petType = object.petType ?? undefined;
    message.birthDate = object.birthDate ?? undefined;
    message.gender = object.gender ?? undefined;
    message.breed = object.breed ?? undefined;
    message.hairType = object.hairType ?? undefined;
    message.hairColor = object.hairColor ?? undefined;
    message.likesDislikes = object.likesDislikes ?? undefined;
    return message;
  },
};

function createBaseGetPetsRequest(): GetPetsRequest {
  return { consumerId: "" };
}

export const GetPetsRequest = {
  encode(message: GetPetsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.consumerId !== "") {
      writer.uint32(10).string(message.consumerId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetPetsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetPetsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.consumerId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetPetsRequest {
    return { consumerId: isSet(object.consumerId) ? globalThis.String(object.consumerId) : "" };
  },

  toJSON(message: GetPetsRequest): unknown {
    const obj: any = {};
    if (message.consumerId !== "") {
      obj.consumerId = message.consumerId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetPetsRequest>, I>>(base?: I): GetPetsRequest {
    return GetPetsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetPetsRequest>, I>>(object: I): GetPetsRequest {
    const message = createBaseGetPetsRequest();
    message.consumerId = object.consumerId ?? "";
    return message;
  },
};

function createBaseGetPetsResponse(): GetPetsResponse {
  return { pet: [] };
}

export const GetPetsResponse = {
  encode(message: GetPetsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.pet) {
      Pet.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetPetsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetPetsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.pet.push(Pet.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetPetsResponse {
    return { pet: globalThis.Array.isArray(object?.pet) ? object.pet.map((e: any) => Pet.fromJSON(e)) : [] };
  },

  toJSON(message: GetPetsResponse): unknown {
    const obj: any = {};
    if (message.pet?.length) {
      obj.pet = message.pet.map((e) => Pet.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetPetsResponse>, I>>(base?: I): GetPetsResponse {
    return GetPetsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetPetsResponse>, I>>(object: I): GetPetsResponse {
    const message = createBaseGetPetsResponse();
    message.pet = object.pet?.map((e) => Pet.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetPetRequest(): GetPetRequest {
  return { id: "", consumerId: "" };
}

export const GetPetRequest = {
  encode(message: GetPetRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.consumerId !== "") {
      writer.uint32(18).string(message.consumerId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetPetRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetPetRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.consumerId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetPetRequest {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      consumerId: isSet(object.consumerId) ? globalThis.String(object.consumerId) : "",
    };
  },

  toJSON(message: GetPetRequest): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.consumerId !== "") {
      obj.consumerId = message.consumerId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetPetRequest>, I>>(base?: I): GetPetRequest {
    return GetPetRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetPetRequest>, I>>(object: I): GetPetRequest {
    const message = createBaseGetPetRequest();
    message.id = object.id ?? "";
    message.consumerId = object.consumerId ?? "";
    return message;
  },
};

function createBaseGetPetResponse(): GetPetResponse {
  return { pet: undefined };
}

export const GetPetResponse = {
  encode(message: GetPetResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pet !== undefined) {
      Pet.encode(message.pet, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetPetResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetPetResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.pet = Pet.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetPetResponse {
    return { pet: isSet(object.pet) ? Pet.fromJSON(object.pet) : undefined };
  },

  toJSON(message: GetPetResponse): unknown {
    const obj: any = {};
    if (message.pet !== undefined) {
      obj.pet = Pet.toJSON(message.pet);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetPetResponse>, I>>(base?: I): GetPetResponse {
    return GetPetResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetPetResponse>, I>>(object: I): GetPetResponse {
    const message = createBaseGetPetResponse();
    message.pet = (object.pet !== undefined && object.pet !== null) ? Pet.fromPartial(object.pet) : undefined;
    return message;
  },
};

function createBaseAddPetRequest(): AddPetRequest {
  return { pet: undefined };
}

export const AddPetRequest = {
  encode(message: AddPetRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pet !== undefined) {
      Pet.encode(message.pet, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddPetRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddPetRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.pet = Pet.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AddPetRequest {
    return { pet: isSet(object.pet) ? Pet.fromJSON(object.pet) : undefined };
  },

  toJSON(message: AddPetRequest): unknown {
    const obj: any = {};
    if (message.pet !== undefined) {
      obj.pet = Pet.toJSON(message.pet);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AddPetRequest>, I>>(base?: I): AddPetRequest {
    return AddPetRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddPetRequest>, I>>(object: I): AddPetRequest {
    const message = createBaseAddPetRequest();
    message.pet = (object.pet !== undefined && object.pet !== null) ? Pet.fromPartial(object.pet) : undefined;
    return message;
  },
};

function createBaseAddPetResponse(): AddPetResponse {
  return { pet: undefined };
}

export const AddPetResponse = {
  encode(message: AddPetResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pet !== undefined) {
      Pet.encode(message.pet, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddPetResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddPetResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.pet = Pet.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AddPetResponse {
    return { pet: isSet(object.pet) ? Pet.fromJSON(object.pet) : undefined };
  },

  toJSON(message: AddPetResponse): unknown {
    const obj: any = {};
    if (message.pet !== undefined) {
      obj.pet = Pet.toJSON(message.pet);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AddPetResponse>, I>>(base?: I): AddPetResponse {
    return AddPetResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddPetResponse>, I>>(object: I): AddPetResponse {
    const message = createBaseAddPetResponse();
    message.pet = (object.pet !== undefined && object.pet !== null) ? Pet.fromPartial(object.pet) : undefined;
    return message;
  },
};

function createBaseUpdatePetRequest(): UpdatePetRequest {
  return { pet: undefined };
}

export const UpdatePetRequest = {
  encode(message: UpdatePetRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pet !== undefined) {
      PetForUpdate.encode(message.pet, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdatePetRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdatePetRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.pet = PetForUpdate.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdatePetRequest {
    return { pet: isSet(object.pet) ? PetForUpdate.fromJSON(object.pet) : undefined };
  },

  toJSON(message: UpdatePetRequest): unknown {
    const obj: any = {};
    if (message.pet !== undefined) {
      obj.pet = PetForUpdate.toJSON(message.pet);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdatePetRequest>, I>>(base?: I): UpdatePetRequest {
    return UpdatePetRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdatePetRequest>, I>>(object: I): UpdatePetRequest {
    const message = createBaseUpdatePetRequest();
    message.pet = (object.pet !== undefined && object.pet !== null) ? PetForUpdate.fromPartial(object.pet) : undefined;
    return message;
  },
};

function createBaseUpdatePetResponse(): UpdatePetResponse {
  return { status: "" };
}

export const UpdatePetResponse = {
  encode(message: UpdatePetResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== "") {
      writer.uint32(10).string(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdatePetResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdatePetResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.status = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdatePetResponse {
    return { status: isSet(object.status) ? globalThis.String(object.status) : "" };
  },

  toJSON(message: UpdatePetResponse): unknown {
    const obj: any = {};
    if (message.status !== "") {
      obj.status = message.status;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdatePetResponse>, I>>(base?: I): UpdatePetResponse {
    return UpdatePetResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdatePetResponse>, I>>(object: I): UpdatePetResponse {
    const message = createBaseUpdatePetResponse();
    message.status = object.status ?? "";
    return message;
  },
};

function createBaseRemovePetRequest(): RemovePetRequest {
  return { id: "", consumerId: "" };
}

export const RemovePetRequest = {
  encode(message: RemovePetRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.consumerId !== "") {
      writer.uint32(18).string(message.consumerId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemovePetRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemovePetRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.consumerId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RemovePetRequest {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      consumerId: isSet(object.consumerId) ? globalThis.String(object.consumerId) : "",
    };
  },

  toJSON(message: RemovePetRequest): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.consumerId !== "") {
      obj.consumerId = message.consumerId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RemovePetRequest>, I>>(base?: I): RemovePetRequest {
    return RemovePetRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RemovePetRequest>, I>>(object: I): RemovePetRequest {
    const message = createBaseRemovePetRequest();
    message.id = object.id ?? "";
    message.consumerId = object.consumerId ?? "";
    return message;
  },
};

function createBaseRemovePetResponse(): RemovePetResponse {
  return { status: "" };
}

export const RemovePetResponse = {
  encode(message: RemovePetResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== "") {
      writer.uint32(10).string(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemovePetResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemovePetResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.status = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RemovePetResponse {
    return { status: isSet(object.status) ? globalThis.String(object.status) : "" };
  },

  toJSON(message: RemovePetResponse): unknown {
    const obj: any = {};
    if (message.status !== "") {
      obj.status = message.status;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RemovePetResponse>, I>>(base?: I): RemovePetResponse {
    return RemovePetResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RemovePetResponse>, I>>(object: I): RemovePetResponse {
    const message = createBaseRemovePetResponse();
    message.status = object.status ?? "";
    return message;
  },
};

export interface PetService {
  getPets(request: GetPetsRequest): Promise<GetPetsResponse>;
  getPet(request: GetPetRequest): Promise<GetPetResponse>;
  addPet(request: AddPetRequest): Promise<AddPetResponse>;
  updatePet(request: UpdatePetRequest): Promise<UpdatePetResponse>;
  removePet(request: RemovePetRequest): Promise<RemovePetResponse>;
}

export const PetServiceServiceName = "anythingpet.pet.PetService";
export class PetServiceClientImpl implements PetService {
  private readonly rpc: Rpc;
  private readonly service: string;
  constructor(rpc: Rpc, opts?: { service?: string }) {
    this.service = opts?.service || PetServiceServiceName;
    this.rpc = rpc;
    this.getPets = this.getPets.bind(this);
    this.getPet = this.getPet.bind(this);
    this.addPet = this.addPet.bind(this);
    this.updatePet = this.updatePet.bind(this);
    this.removePet = this.removePet.bind(this);
  }
  getPets(request: GetPetsRequest): Promise<GetPetsResponse> {
    const data = GetPetsRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "getPets", data);
    return promise.then((data) => GetPetsResponse.decode(_m0.Reader.create(data)));
  }

  getPet(request: GetPetRequest): Promise<GetPetResponse> {
    const data = GetPetRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "getPet", data);
    return promise.then((data) => GetPetResponse.decode(_m0.Reader.create(data)));
  }

  addPet(request: AddPetRequest): Promise<AddPetResponse> {
    const data = AddPetRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "addPet", data);
    return promise.then((data) => AddPetResponse.decode(_m0.Reader.create(data)));
  }

  updatePet(request: UpdatePetRequest): Promise<UpdatePetResponse> {
    const data = UpdatePetRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "updatePet", data);
    return promise.then((data) => UpdatePetResponse.decode(_m0.Reader.create(data)));
  }

  removePet(request: RemovePetRequest): Promise<RemovePetResponse> {
    const data = RemovePetRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "removePet", data);
    return promise.then((data) => RemovePetResponse.decode(_m0.Reader.create(data)));
  }
}

interface Rpc {
  request(service: string, method: string, data: Uint8Array): Promise<Uint8Array>;
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
