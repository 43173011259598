/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "anythingpet.vendor";

export interface OfficeDayHours {
  startTime: string;
  endTime: string;
}

export interface OfficeHours {
  sunday: OfficeDayHours | undefined;
  monday: OfficeDayHours | undefined;
  tuesday: OfficeDayHours | undefined;
  wednesday: OfficeDayHours | undefined;
  thursday: OfficeDayHours | undefined;
  friday: OfficeDayHours | undefined;
  saturday: OfficeDayHours | undefined;
}

export interface VendorImages {
  name: string;
  url: string;
}

export interface Vendor {
  id: string;
  name: string;
  code: string;
  officeHours: OfficeHours | undefined;
  timeZone: string;
  location: string;
  address: string;
  about: string;
  additionalInfo: string;
  googleMapSrc: string;
  bookingCancellationPolicy: string;
  additionalBookingNotes: string;
  images: VendorImages[];
}

export interface GetVendorRequest {
  id: string;
}

export interface GetVendorResponse {
  vendor: Vendor | undefined;
}

export interface GetVendorByCodeRequest {
  code: string;
}

export interface GetVendorByCodeResponse {
  vendor: Vendor | undefined;
}

function createBaseOfficeDayHours(): OfficeDayHours {
  return { startTime: "", endTime: "" };
}

export const OfficeDayHours = {
  encode(message: OfficeDayHours, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.startTime !== "") {
      writer.uint32(10).string(message.startTime);
    }
    if (message.endTime !== "") {
      writer.uint32(18).string(message.endTime);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OfficeDayHours {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOfficeDayHours();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.startTime = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.endTime = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OfficeDayHours {
    return {
      startTime: isSet(object.startTime) ? globalThis.String(object.startTime) : "",
      endTime: isSet(object.endTime) ? globalThis.String(object.endTime) : "",
    };
  },

  toJSON(message: OfficeDayHours): unknown {
    const obj: any = {};
    if (message.startTime !== "") {
      obj.startTime = message.startTime;
    }
    if (message.endTime !== "") {
      obj.endTime = message.endTime;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OfficeDayHours>, I>>(base?: I): OfficeDayHours {
    return OfficeDayHours.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OfficeDayHours>, I>>(object: I): OfficeDayHours {
    const message = createBaseOfficeDayHours();
    message.startTime = object.startTime ?? "";
    message.endTime = object.endTime ?? "";
    return message;
  },
};

function createBaseOfficeHours(): OfficeHours {
  return {
    sunday: undefined,
    monday: undefined,
    tuesday: undefined,
    wednesday: undefined,
    thursday: undefined,
    friday: undefined,
    saturday: undefined,
  };
}

export const OfficeHours = {
  encode(message: OfficeHours, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.sunday !== undefined) {
      OfficeDayHours.encode(message.sunday, writer.uint32(10).fork()).ldelim();
    }
    if (message.monday !== undefined) {
      OfficeDayHours.encode(message.monday, writer.uint32(18).fork()).ldelim();
    }
    if (message.tuesday !== undefined) {
      OfficeDayHours.encode(message.tuesday, writer.uint32(26).fork()).ldelim();
    }
    if (message.wednesday !== undefined) {
      OfficeDayHours.encode(message.wednesday, writer.uint32(34).fork()).ldelim();
    }
    if (message.thursday !== undefined) {
      OfficeDayHours.encode(message.thursday, writer.uint32(42).fork()).ldelim();
    }
    if (message.friday !== undefined) {
      OfficeDayHours.encode(message.friday, writer.uint32(50).fork()).ldelim();
    }
    if (message.saturday !== undefined) {
      OfficeDayHours.encode(message.saturday, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OfficeHours {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOfficeHours();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.sunday = OfficeDayHours.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.monday = OfficeDayHours.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.tuesday = OfficeDayHours.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.wednesday = OfficeDayHours.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.thursday = OfficeDayHours.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.friday = OfficeDayHours.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.saturday = OfficeDayHours.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OfficeHours {
    return {
      sunday: isSet(object.sunday) ? OfficeDayHours.fromJSON(object.sunday) : undefined,
      monday: isSet(object.monday) ? OfficeDayHours.fromJSON(object.monday) : undefined,
      tuesday: isSet(object.tuesday) ? OfficeDayHours.fromJSON(object.tuesday) : undefined,
      wednesday: isSet(object.wednesday) ? OfficeDayHours.fromJSON(object.wednesday) : undefined,
      thursday: isSet(object.thursday) ? OfficeDayHours.fromJSON(object.thursday) : undefined,
      friday: isSet(object.friday) ? OfficeDayHours.fromJSON(object.friday) : undefined,
      saturday: isSet(object.saturday) ? OfficeDayHours.fromJSON(object.saturday) : undefined,
    };
  },

  toJSON(message: OfficeHours): unknown {
    const obj: any = {};
    if (message.sunday !== undefined) {
      obj.sunday = OfficeDayHours.toJSON(message.sunday);
    }
    if (message.monday !== undefined) {
      obj.monday = OfficeDayHours.toJSON(message.monday);
    }
    if (message.tuesday !== undefined) {
      obj.tuesday = OfficeDayHours.toJSON(message.tuesday);
    }
    if (message.wednesday !== undefined) {
      obj.wednesday = OfficeDayHours.toJSON(message.wednesday);
    }
    if (message.thursday !== undefined) {
      obj.thursday = OfficeDayHours.toJSON(message.thursday);
    }
    if (message.friday !== undefined) {
      obj.friday = OfficeDayHours.toJSON(message.friday);
    }
    if (message.saturday !== undefined) {
      obj.saturday = OfficeDayHours.toJSON(message.saturday);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OfficeHours>, I>>(base?: I): OfficeHours {
    return OfficeHours.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OfficeHours>, I>>(object: I): OfficeHours {
    const message = createBaseOfficeHours();
    message.sunday = (object.sunday !== undefined && object.sunday !== null)
      ? OfficeDayHours.fromPartial(object.sunday)
      : undefined;
    message.monday = (object.monday !== undefined && object.monday !== null)
      ? OfficeDayHours.fromPartial(object.monday)
      : undefined;
    message.tuesday = (object.tuesday !== undefined && object.tuesday !== null)
      ? OfficeDayHours.fromPartial(object.tuesday)
      : undefined;
    message.wednesday = (object.wednesday !== undefined && object.wednesday !== null)
      ? OfficeDayHours.fromPartial(object.wednesday)
      : undefined;
    message.thursday = (object.thursday !== undefined && object.thursday !== null)
      ? OfficeDayHours.fromPartial(object.thursday)
      : undefined;
    message.friday = (object.friday !== undefined && object.friday !== null)
      ? OfficeDayHours.fromPartial(object.friday)
      : undefined;
    message.saturday = (object.saturday !== undefined && object.saturday !== null)
      ? OfficeDayHours.fromPartial(object.saturday)
      : undefined;
    return message;
  },
};

function createBaseVendorImages(): VendorImages {
  return { name: "", url: "" };
}

export const VendorImages = {
  encode(message: VendorImages, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.url !== "") {
      writer.uint32(18).string(message.url);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VendorImages {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVendorImages();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.url = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VendorImages {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      url: isSet(object.url) ? globalThis.String(object.url) : "",
    };
  },

  toJSON(message: VendorImages): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.url !== "") {
      obj.url = message.url;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VendorImages>, I>>(base?: I): VendorImages {
    return VendorImages.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VendorImages>, I>>(object: I): VendorImages {
    const message = createBaseVendorImages();
    message.name = object.name ?? "";
    message.url = object.url ?? "";
    return message;
  },
};

function createBaseVendor(): Vendor {
  return {
    id: "",
    name: "",
    code: "",
    officeHours: undefined,
    timeZone: "",
    location: "",
    address: "",
    about: "",
    additionalInfo: "",
    googleMapSrc: "",
    bookingCancellationPolicy: "",
    additionalBookingNotes: "",
    images: [],
  };
}

export const Vendor = {
  encode(message: Vendor, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.code !== "") {
      writer.uint32(26).string(message.code);
    }
    if (message.officeHours !== undefined) {
      OfficeHours.encode(message.officeHours, writer.uint32(34).fork()).ldelim();
    }
    if (message.timeZone !== "") {
      writer.uint32(42).string(message.timeZone);
    }
    if (message.location !== "") {
      writer.uint32(50).string(message.location);
    }
    if (message.address !== "") {
      writer.uint32(58).string(message.address);
    }
    if (message.about !== "") {
      writer.uint32(66).string(message.about);
    }
    if (message.additionalInfo !== "") {
      writer.uint32(74).string(message.additionalInfo);
    }
    if (message.googleMapSrc !== "") {
      writer.uint32(82).string(message.googleMapSrc);
    }
    if (message.bookingCancellationPolicy !== "") {
      writer.uint32(90).string(message.bookingCancellationPolicy);
    }
    if (message.additionalBookingNotes !== "") {
      writer.uint32(98).string(message.additionalBookingNotes);
    }
    for (const v of message.images) {
      VendorImages.encode(v!, writer.uint32(106).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Vendor {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVendor();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.code = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.officeHours = OfficeHours.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.timeZone = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.location = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.address = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.about = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.additionalInfo = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.googleMapSrc = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.bookingCancellationPolicy = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.additionalBookingNotes = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.images.push(VendorImages.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Vendor {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      code: isSet(object.code) ? globalThis.String(object.code) : "",
      officeHours: isSet(object.officeHours) ? OfficeHours.fromJSON(object.officeHours) : undefined,
      timeZone: isSet(object.timeZone) ? globalThis.String(object.timeZone) : "",
      location: isSet(object.location) ? globalThis.String(object.location) : "",
      address: isSet(object.address) ? globalThis.String(object.address) : "",
      about: isSet(object.about) ? globalThis.String(object.about) : "",
      additionalInfo: isSet(object.additionalInfo) ? globalThis.String(object.additionalInfo) : "",
      googleMapSrc: isSet(object.googleMapSrc) ? globalThis.String(object.googleMapSrc) : "",
      bookingCancellationPolicy: isSet(object.bookingCancellationPolicy)
        ? globalThis.String(object.bookingCancellationPolicy)
        : "",
      additionalBookingNotes: isSet(object.additionalBookingNotes)
        ? globalThis.String(object.additionalBookingNotes)
        : "",
      images: globalThis.Array.isArray(object?.images) ? object.images.map((e: any) => VendorImages.fromJSON(e)) : [],
    };
  },

  toJSON(message: Vendor): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.code !== "") {
      obj.code = message.code;
    }
    if (message.officeHours !== undefined) {
      obj.officeHours = OfficeHours.toJSON(message.officeHours);
    }
    if (message.timeZone !== "") {
      obj.timeZone = message.timeZone;
    }
    if (message.location !== "") {
      obj.location = message.location;
    }
    if (message.address !== "") {
      obj.address = message.address;
    }
    if (message.about !== "") {
      obj.about = message.about;
    }
    if (message.additionalInfo !== "") {
      obj.additionalInfo = message.additionalInfo;
    }
    if (message.googleMapSrc !== "") {
      obj.googleMapSrc = message.googleMapSrc;
    }
    if (message.bookingCancellationPolicy !== "") {
      obj.bookingCancellationPolicy = message.bookingCancellationPolicy;
    }
    if (message.additionalBookingNotes !== "") {
      obj.additionalBookingNotes = message.additionalBookingNotes;
    }
    if (message.images?.length) {
      obj.images = message.images.map((e) => VendorImages.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Vendor>, I>>(base?: I): Vendor {
    return Vendor.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Vendor>, I>>(object: I): Vendor {
    const message = createBaseVendor();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.code = object.code ?? "";
    message.officeHours = (object.officeHours !== undefined && object.officeHours !== null)
      ? OfficeHours.fromPartial(object.officeHours)
      : undefined;
    message.timeZone = object.timeZone ?? "";
    message.location = object.location ?? "";
    message.address = object.address ?? "";
    message.about = object.about ?? "";
    message.additionalInfo = object.additionalInfo ?? "";
    message.googleMapSrc = object.googleMapSrc ?? "";
    message.bookingCancellationPolicy = object.bookingCancellationPolicy ?? "";
    message.additionalBookingNotes = object.additionalBookingNotes ?? "";
    message.images = object.images?.map((e) => VendorImages.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetVendorRequest(): GetVendorRequest {
  return { id: "" };
}

export const GetVendorRequest = {
  encode(message: GetVendorRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetVendorRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetVendorRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetVendorRequest {
    return { id: isSet(object.id) ? globalThis.String(object.id) : "" };
  },

  toJSON(message: GetVendorRequest): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetVendorRequest>, I>>(base?: I): GetVendorRequest {
    return GetVendorRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetVendorRequest>, I>>(object: I): GetVendorRequest {
    const message = createBaseGetVendorRequest();
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseGetVendorResponse(): GetVendorResponse {
  return { vendor: undefined };
}

export const GetVendorResponse = {
  encode(message: GetVendorResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.vendor !== undefined) {
      Vendor.encode(message.vendor, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetVendorResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetVendorResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.vendor = Vendor.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetVendorResponse {
    return { vendor: isSet(object.vendor) ? Vendor.fromJSON(object.vendor) : undefined };
  },

  toJSON(message: GetVendorResponse): unknown {
    const obj: any = {};
    if (message.vendor !== undefined) {
      obj.vendor = Vendor.toJSON(message.vendor);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetVendorResponse>, I>>(base?: I): GetVendorResponse {
    return GetVendorResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetVendorResponse>, I>>(object: I): GetVendorResponse {
    const message = createBaseGetVendorResponse();
    message.vendor = (object.vendor !== undefined && object.vendor !== null)
      ? Vendor.fromPartial(object.vendor)
      : undefined;
    return message;
  },
};

function createBaseGetVendorByCodeRequest(): GetVendorByCodeRequest {
  return { code: "" };
}

export const GetVendorByCodeRequest = {
  encode(message: GetVendorByCodeRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== "") {
      writer.uint32(10).string(message.code);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetVendorByCodeRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetVendorByCodeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.code = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetVendorByCodeRequest {
    return { code: isSet(object.code) ? globalThis.String(object.code) : "" };
  },

  toJSON(message: GetVendorByCodeRequest): unknown {
    const obj: any = {};
    if (message.code !== "") {
      obj.code = message.code;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetVendorByCodeRequest>, I>>(base?: I): GetVendorByCodeRequest {
    return GetVendorByCodeRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetVendorByCodeRequest>, I>>(object: I): GetVendorByCodeRequest {
    const message = createBaseGetVendorByCodeRequest();
    message.code = object.code ?? "";
    return message;
  },
};

function createBaseGetVendorByCodeResponse(): GetVendorByCodeResponse {
  return { vendor: undefined };
}

export const GetVendorByCodeResponse = {
  encode(message: GetVendorByCodeResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.vendor !== undefined) {
      Vendor.encode(message.vendor, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetVendorByCodeResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetVendorByCodeResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.vendor = Vendor.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetVendorByCodeResponse {
    return { vendor: isSet(object.vendor) ? Vendor.fromJSON(object.vendor) : undefined };
  },

  toJSON(message: GetVendorByCodeResponse): unknown {
    const obj: any = {};
    if (message.vendor !== undefined) {
      obj.vendor = Vendor.toJSON(message.vendor);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetVendorByCodeResponse>, I>>(base?: I): GetVendorByCodeResponse {
    return GetVendorByCodeResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetVendorByCodeResponse>, I>>(object: I): GetVendorByCodeResponse {
    const message = createBaseGetVendorByCodeResponse();
    message.vendor = (object.vendor !== undefined && object.vendor !== null)
      ? Vendor.fromPartial(object.vendor)
      : undefined;
    return message;
  },
};

export interface VendorService {
  getVendor(request: GetVendorRequest): Promise<GetVendorResponse>;
  getVendorByCode(request: GetVendorByCodeRequest): Promise<GetVendorByCodeResponse>;
}

export const VendorServiceServiceName = "anythingpet.vendor.VendorService";
export class VendorServiceClientImpl implements VendorService {
  private readonly rpc: Rpc;
  private readonly service: string;
  constructor(rpc: Rpc, opts?: { service?: string }) {
    this.service = opts?.service || VendorServiceServiceName;
    this.rpc = rpc;
    this.getVendor = this.getVendor.bind(this);
    this.getVendorByCode = this.getVendorByCode.bind(this);
  }
  getVendor(request: GetVendorRequest): Promise<GetVendorResponse> {
    const data = GetVendorRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "getVendor", data);
    return promise.then((data) => GetVendorResponse.decode(_m0.Reader.create(data)));
  }

  getVendorByCode(request: GetVendorByCodeRequest): Promise<GetVendorByCodeResponse> {
    const data = GetVendorByCodeRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "getVendorByCode", data);
    return promise.then((data) => GetVendorByCodeResponse.decode(_m0.Reader.create(data)));
  }
}

interface Rpc {
  request(service: string, method: string, data: Uint8Array): Promise<Uint8Array>;
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
