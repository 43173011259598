import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography} from '@mui/material';

const SignInPage: React.FC = () => {
  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>Welcome to Anything Pet</Typography>

      <p>
        Don't have an account? <Link to="/signup">Sign Up</Link>
      </p>
    </Box>
  );
};

export default SignInPage;
