// AddPetDialog.tsx
import React, { useState } from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    MenuItem,
    Button,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";

interface AddPetDialogProps {
    open: boolean;
    onClose: () => void;
    onSave: (newPet: any) => void;
}

const AddPetDialog: React.FC<AddPetDialogProps> = ({
    open,
    onClose,
    onSave,
}) => {
    const [newPet, setNewPet] = useState({
        id: "",
        name: "",
        petType: "Dog",
        dob: dayjs(),
        gender: "Male",
        breed: "",
        hairType: "",
        hairColor: "",
        likesDislikes: "",
    });

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setNewPet((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleDateChange = (date: Dayjs | null) => {
        setNewPet((prev) => {
            if (!date) {
                return prev;
            }
    
            // Ensure prev is always an object with default values
            const defaultPet = {
                name: '',
                petType: '',
                dob: date,
                gender: '',
                breed: '',
                hairType: '',
                hairColor: '',
                likesDislikes: ''
            };
    
            return { 
                ...defaultPet, 
                ...prev, 
                dob: date 
            };
        });
    };
    

    const handleSave = () => {
        onSave(newPet);
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Add a New Pet</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please fill in the details of your new pet.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="normal"
                    name="name"
                    label="Name"
                    type="text"
                    fullWidth
                    value={newPet.name}
                    onChange={handleInputChange}
                />
                <TextField
                    select
                    margin="dense"
                    name="petType"
                    label="Type"
                    fullWidth
                    value={newPet.petType}
                    onChange={handleInputChange}
                >
                    <MenuItem value="Dog">Dog</MenuItem>
                    <MenuItem value="Cat">Cat</MenuItem>
                </TextField>
                {/* <TextField
                    margin="dense"
                    name="dateOfBirth"
                    label="Date of Birth"
                    type="date"
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={newPet.dob}
                    onChange={handleInputChange}
                /> */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label="Date of Birth"
                        value={newPet.dob}
                        onChange={handleDateChange}
                    />
                </LocalizationProvider>
                <TextField
                    select
                    margin="dense"
                    name="gender"
                    label="Gender"
                    fullWidth
                    value={newPet.gender}
                    onChange={handleInputChange}
                >
                    <MenuItem value="Male">Male</MenuItem>
                    <MenuItem value="Female">Female</MenuItem>
                </TextField>
                <TextField
                    margin="dense"
                    name="breed"
                    label="Pet Breed"
                    type="text"
                    fullWidth
                    value={newPet.breed}
                    onChange={handleInputChange}
                />
                <TextField
                    margin="dense"
                    name="hairType"
                    label="Pet Hair Type"
                    type="text"
                    fullWidth
                    value={newPet.hairType}
                    onChange={handleInputChange}
                />
                <TextField
                    margin="dense"
                    name="hairColor"
                    label="Pet Hair Color"
                    type="text"
                    fullWidth
                    value={newPet.hairColor}
                    onChange={handleInputChange}
                />
                <TextField
                    margin="dense"
                    name="likesDislikes"
                    label="Likes or Dislikes"
                    type="text"
                    fullWidth
                    multiline
                    rows={4}
                    value={newPet.likesDislikes}
                    onChange={handleInputChange}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="error">
                    Cancel
                </Button>
                <Button onClick={handleSave} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddPetDialog;
