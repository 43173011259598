import React, { useState } from 'react';
import { Box, Button, TextField, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

interface UserFormValues {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  emailAddress: string;
  username: string;
  password: string;
}

interface PetFormValues {
  petName: string;
  petType: string;
  petAge: string;
  petGender: string;
  petHairType: string;
  petHairColor: string;
  petLikesDislikes: string;
}

const UserSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  phoneNumber: Yup.string().required('Phone number is required'),
  emailAddress: Yup.string().email('Invalid email').required('Email address is required'),
  username: Yup.string().required('Username is required'),
  password: Yup.string().min(8, 'Password must be at least 8 characters').required('Password is required'),
});

const PetSchema = Yup.object().shape({
  petName: Yup.string().required('Pet name is required'),
  petType: Yup.string().required('Please select dog or cat'),
  petAge: Yup.string().required('Pet age is required'),
  petGender: Yup.string().required('Pet gender is required'),
  petHairType: Yup.string().required('Pet hair type is required'),
  petHairColor: Yup.string().required('Pet hair color is required'),
  petLikesDislikes: Yup.string(),
});

const SignUpPage: React.FC = () => {
  const [step, setStep] = useState(1);
  const [userData, setUserData] = useState<UserFormValues | null>(null);

  const handleUserSubmit = (values: UserFormValues) => {
    setUserData(values);
    setStep(2); // Proceed to the next step
  };

  const handlePetSubmit = (values: PetFormValues) => {
    console.log('User Data:', userData);
    console.log('Pet Data:', values);
    // Here, you would handle the final submission to the server
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>Sign Up</Typography>
      {step === 1 && (
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            phoneNumber: '',
            emailAddress: '',
            username: '',
            password: '',
          }}
          validationSchema={UserSchema}
          onSubmit={handleUserSubmit}
        >
          {({ handleChange, handleBlur, values }) => (
            <Form>
              <TextField label="First Name" name="firstName" value={values.firstName} onChange={handleChange} onBlur={handleBlur} fullWidth margin="normal" />
              <TextField label="Last Name" name="lastName" value={values.lastName} onChange={handleChange} onBlur={handleBlur} fullWidth margin="normal" />
              <TextField label="Phone Number" name="phoneNumber" value={values.phoneNumber} onChange={handleChange} onBlur={handleBlur} fullWidth margin="normal" />
              <TextField label="Email Address" name="emailAddress" value={values.emailAddress} onChange={handleChange} onBlur={handleBlur} fullWidth margin="normal" />
              <TextField label="Username" name="username" value={values.username} onChange={handleChange} onBlur={handleBlur} fullWidth margin="normal" />
              <TextField label="Password" name="password" type="password" value={values.password} onChange={handleChange} onBlur={handleBlur} fullWidth margin="normal" />
              <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
                Next
              </Button>
            </Form>
          )}
        </Formik>
      )}
      {step === 2 && (
        <Formik
          initialValues={{
            petName: '',
            petType: '',
            petAge: '',
            petGender: '',
            petHairType: '',
            petHairColor: '',
            petLikesDislikes: '',
          }}
          validationSchema={PetSchema}
          onSubmit={handlePetSubmit}
        >
          {({ handleChange, handleBlur, values }) => (
            <Form>
              <TextField label="Pet Name" name="petName" value={values.petName} onChange={handleChange} onBlur={handleBlur} fullWidth margin="normal" />
              <FormControl fullWidth margin="normal">
                <InputLabel>Type</InputLabel>
                <Select name="petType" value={values.petType} onChange={handleChange} label="Type">
                  <MenuItem value="Dog">Dog</MenuItem>
                  <MenuItem value="Cat">Cat</MenuItem>
                </Select>
              </FormControl>
              <TextField label="Pet Age" name="petAge" value={values.petAge} onChange={handleChange} onBlur={handleBlur} fullWidth margin="normal" />
              <FormControl fullWidth margin="normal">
                <InputLabel>Gender</InputLabel>
                <Select name="petGender" value={values.petGender} onChange={handleChange} label="Gender">
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                </Select>
              </FormControl>
              <TextField label="Pet Hair Type" name="petHairType" value={values.petHairType} onChange={handleChange} onBlur={handleBlur} fullWidth margin="normal" />
              <TextField label="Pet Hair Color" name="petHairColor" value={values.petHairColor} onChange={handleChange} onBlur={handleBlur} fullWidth margin="normal" />
              <TextField label="Likes and Dislikes" name="petLikesDislikes" value={values.petLikesDislikes} onChange={handleChange} onBlur={handleBlur} multiline rows={4} fullWidth margin="normal" />
              <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
                Sign Up
              </Button>
            </Form>
          )}
        </Formik>
      )}
    </Box>
  );
};

export default SignUpPage;
