import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { Box, Button, Step, StepLabel, Stepper } from "@mui/material";

import { BookingFormState, useBookingFormStateContext } from "./BookOfferingState";
import { BookingFormSelectDate } from "./BookingFormSelectDate";
import { offering } from "@anything-pet/grpc-lib";


function determineActiveStep(state : BookingFormState) {
    let step = 0;

    if (state.bookingDate) {
        step ++;
        
        if (state.bookingTime) {
            step ++;
        
            if (state.staffId) {
                step ++;

                if (state.petId) {
                    step ++;
                }
            }
        }
    }

    return step;
}


export function BookingFormSteps(props: { offering: offering.Offering }) {
    const context = useBookingFormStateContext();

    const activeStep = determineActiveStep(context.state);

    const handleBack = () => {
        switch (activeStep) {
            case 1:
                context.state.bookingDate = undefined;
                context.navigateToState();
                break;

            case 2:
                context.state.bookingTime = undefined;
                context.navigateToState();
                break;

            case 3:
                context.state.staffId = undefined;
                context.navigateToState();
                break;

            case 4:
                context.state.petId = undefined;
                context.navigateToState();
                break;
        }
    };

    return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box sx={{ width: "100%" }}>
            <Box sx={{ p: { sm: 2, xs: 0 } }}>
                <Stepper activeStep={activeStep} alternativeLabel>
                    <Step>
                        <StepLabel>Select a date</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>Select a time</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>Select a team member</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>Select a pet</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>Confirm</StepLabel>
                    </Step>
                </Stepper>
            </Box>
            <BookingFormSelectDate offering={props.offering} />
            {activeStep > 0 && activeStep < 5 && (
                    <Box display="flex" justifyContent="space-between" mt={2}>
                        <Button onClick={handleBack}>Back</Button>
                    </Box>
                )}
        </Box>
    </LocalizationProvider>
    )
}