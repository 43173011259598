import { PropsWithChildren, useEffect, useState, useContext, createContext } from "react";
import { Navigate, useParams } from "react-router-dom";
import { getVendorService } from "../services/VendorServiceProxy";
import { LinearProgress } from "@mui/material";
import { useSocketIO } from "./SocketIO";
import { vendor } from "@anything-pet/grpc-lib";

const vendorScopeContext = createContext<vendor.Vendor | undefined>(undefined);

/**
 * 
 * @returns 
 */
export function useVendorScope() {
    const vendor = useContext(vendorScopeContext);

    if (! vendor) {
        throw new Error('No vendor in scope');
    }

    return vendor;
}

/**
 * 
 * @param props 
 */
export function VendorScopeProvider(props: PropsWithChildren) {
    const socket = useSocketIO();    
    const { vendor: vendorCode } = useParams();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [vendorProfile, setVendorProfile] = useState<vendor.Vendor>();

    useEffect(() => {
        async function load() {
            if (vendorCode) {
                const service = getVendorService(socket);

                const { vendor : resultVendor } = await service.getVendorByCode({ code: vendorCode });

                if (resultVendor) {
                    setVendorProfile(resultVendor);

                    document.title = resultVendor.name;
                }
            }

            setIsLoading(false);
        }

        load();
    }, [vendorCode]);

    if (isLoading) {
        return (
            <LinearProgress />
        )
    }

    if (! vendorProfile) {
        return <Navigate to='/not-found' />;
    }

    return (
        <vendorScopeContext.Provider value={vendorProfile}>
            {props.children}
        </vendorScopeContext.Provider>
    )
}