import React, { useEffect, useState } from "react";
import { useSocketIO } from "../components/SocketIO";
import { getUserBookingServiceProxy } from "../services/UserBookingServiceProxy";
import dayjs from "dayjs";
import {
    Box,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    List,
    ListItem,
    ListItemText,
    IconButton,
} from "@mui/material";
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';

interface BookingDetails {
    id: string;
    start: Date;
    end: Date;
    petName: string;
    offeringName: string;
    vendorName: string;
}

const BookingList: React.FC = () => {
    const socket = useSocketIO();
    const [bookings, setBookings] = useState<BookingDetails[]>([]);
    const [open, setOpen] = useState(false);
    const [bookingToCancel, setBookingToCancel] = useState<BookingDetails | null>(null);

    useEffect(() => {
        (async () => {
            const now = dayjs();
            const service = getUserBookingServiceProxy(socket);

            const { booking: bookings } = await service.getBookings({
                userId: "",
                fromDateTime: now.startOf("day").toISOString(),
                toDateTime: now.add(1, "month").endOf("day").toISOString(),
            });

            const formattedBookings = bookings.map((booking) => ({
                id: booking.id,
                start: dayjs(booking.startDateTime).toDate(),
                end: dayjs(booking.endDateTime).toDate(),
                petName: booking.petName,
                offeringName: booking.offeringName,
                vendorName: booking.vendorName,
            }));

            setBookings(
                formattedBookings.sort(
                    (a, b) => a.start.getTime() - b.start.getTime()
                )
            );
        })();
    }, [socket]);


    const handleOpenDialog = (booking: BookingDetails) => {
        setBookingToCancel(booking);
        setOpen(true);
    };

    const handleCloseDialog = () => {
        setOpen(false);
        setBookingToCancel(null);
    };

    const handleConfirmCancel = async () => {
        if (!bookingToCancel) return;

        const service = getUserBookingServiceProxy(socket);

        await service.removeBooking({
            id: bookingToCancel.id,
            userId: "",
            vendorId: bookingToCancel.vendorName,
        });

        const index = bookings.findIndex((b) => b.id === bookingToCancel.id);

        if (index !== -1) {
            const newBookings = [...bookings];
            newBookings.splice(index, 1);

            setBookings(newBookings);
        }

        handleCloseDialog();
    };

    return (
        <>
            <Box sx={{ paddingTop: 1 }}>
                {bookings.length === 0 ? (
                    <Typography variant="body1">
                        You have no upcoming bookings.
                    </Typography>
                ) : (
                    <List>
                        {bookings.map((booking) => (
                            <ListItem key={booking.id} divider>
                                <ListItemText
                                    primary={`${booking.offeringName} for ${booking.petName}`}
                                    secondary={`on ${dayjs(booking.start).format("YYYY-MM-DD")} from ${dayjs(booking.start).format(
                                        "H:mm A"
                                    )} to ${dayjs(booking.end).format(
                                        "H:mm A"
                                    )} at ${booking.vendorName}`}
                                />
                                <IconButton
                                    edge="end"
                                    aria-label="cancel"
                                    onClick={() => handleOpenDialog(booking)}
                                >
                                    <DeleteForeverRoundedIcon color="error" />
                                </IconButton>
                            </ListItem>
                        ))}
                    </List>
                )}

                <Dialog
                    open={open}
                    onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Confirm Cancellation"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to cancel this booking?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleConfirmCancel}
                            color="error"
                            variant="outlined"
                        >
                            Cancel Booking
                        </Button>
                        <Button
                            variant="contained"
                            onClick={handleCloseDialog}
                            color="primary"
                            autoFocus
                            startIcon={<DeleteForeverRoundedIcon />}
                        >
                            No
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </>
    );
};

export default BookingList;
