import { booking, offering } from "@anything-pet/grpc-lib";
import { useEffect, useState } from "react";
import { useBookingFormStateContext } from "./BookOfferingState";
import { Box, Card, CardActionArea, CardContent, Grid, Typography } from "@mui/material";
import { BookingFormSelectPet } from "./BookingFormSelectPet";


export function BookingFormSelectStaff(
    props: { 
        offering: offering.Offering,
        slot: booking.AvailableSlot }
) {

    const context = useBookingFormStateContext();
    const [selectedStaff, setSelectedStaff] = useState<booking.AvailableSlotResource | null>(null);
    
    if (context.state.staffId) {
        const available = props.slot.staff.find(staff => staff.id === context.state.staffId);

        if (available) {
            //  go to next step
            return <BookingFormSelectPet offering={props.offering} slot={props.slot} staff={available} />;
        } else {
            //  Invalid staff if. Fix the url
            context.state.staffId = undefined;
            context.navigateToState();
        }
    }

    const handleStaffSelection = (staff : booking.AvailableSlotResource) =>{
        setSelectedStaff(staff);

        context.state.staffId = staff.id;
        context.navigateToState();
    }

    return (
        <>
            <Typography
                variant="h5"
                paddingTop="20px"
                paddingBottom="10px"
                align="center"
            >
                Please select a team member
            </Typography>
            <Box display="flex" justifyContent="center" mb={2}>
                <Grid container spacing={2} justifyContent="center">
                    {props.slot.staff.map(staff => (
                        <Grid item key={staff.id}>
                            <Card
                                variant={selectedStaff === staff ? "outlined" : "elevation"}
                                sx={{
                                    width: 200,
                                    cursor: "pointer",
                                    backgroundColor: selectedStaff === staff ? "secondary.light" : "background.paper",
                                    '&:hover': {
                                        backgroundColor: "primary.main",
                                    },
                                }}
                                onClick={() => handleStaffSelection(staff)}
                            >
                                <CardActionArea>
                                    <CardContent>
                                        <Typography
                                            variant="h6"
                                            align="center"
                                            sx={{
                                                fontWeight: "bold",
                                                color: selectedStaff === staff ? "secondary.contrastText" : "text.primary",
                                            }}
                                        >
                                            {staff.firstName} {staff.lastName}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </>
    );
}