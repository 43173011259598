import React from 'react';
import { Button, Typography, TextField } from '@mui/material';
import SaveAltRoundedIcon from '@mui/icons-material/SaveAltRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { userProfile } from '@anything-pet/grpc-lib';
import EditRoundedIcon from '@mui/icons-material/EditRounded';

type UserProfileProps = {
  user: userProfile.UserProfile;
  editMode: boolean;
  handleChange: (name: string, value: string) => void;
  handleSaveUser: () => void;
  handleCancelUser: () => void;
  handleEditUser: () => void;
};

const UserProfileEditor: React.FC<UserProfileProps> = ({
  user,
  editMode,
  handleChange,
  handleSaveUser,
  handleCancelUser,
  handleEditUser,
}) => {
  return (
    <>
      <Typography variant="h4" gutterBottom>User Profile</Typography>
      {editMode ? (
        <>
          {/* Editable fields here */}
          <TextField
            label="First Name"
            name="user.firstName"
            value={user.firstName}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Last Name"
            name="user.lastName"
            value={user.lastName}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Phone Number"
            name="user.phoneNumber"
            value={user.phoneNumber}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Email Address"
            name="user.emailAddress"
            value={user.email}
            disabled={true} //we do not allow the user to update their email address
            //onChange={(e) => handleChange(e.target.name, e.target.value)}
            fullWidth
            margin="normal"
          />          
          <Button startIcon={<SaveAltRoundedIcon />} onClick={handleSaveUser} variant="contained" color="primary" sx={{ mt: 2, mr: 1 }}>Save</Button>
          <Button startIcon={<CloseRoundedIcon />} onClick={handleCancelUser} variant="outlined" sx={{ mt: 2 }}>Cancel</Button>
        </>
      ) : (
        <>
          {/* Display fields here */}
          <Typography variant="body1">
            <b>First Name:</b> <span data-cy='firstName'>{user.firstName}</span>
          </Typography>
          <Typography variant="body1">
            <b>Last Name:</b> <span data-cy='lastName'>{user.lastName}</span>
          </Typography>
          <Typography variant="body1">
            <b>Phone Number:</b> <span data-cy='phoneNumber'>{user.phoneNumber}</span>
          </Typography>
          <Typography variant="body1">
            <b>Email Address:</b> <span data-cy='email'>{user.email}</span>
          </Typography>
          <Button data-cy='editBtn' startIcon={<EditRoundedIcon />} onClick={handleEditUser} variant="contained" sx={{ mt: 2 }}>Edit</Button>
        </>
      )}
      </>
  );
};

export default UserProfileEditor;
