import { offering, vendor } from "@anything-pet/grpc-lib";
import { Socket } from "socket.io-client";
import { emitEvent } from "../components/SocketIO";

type OfferingService = Pick<offering.OfferingService, 'getOffering'>;

/**
 * 
 * @param serviecUrl 
 * @returns 
 */
export function getOfferingService(socket : Socket) : OfferingService {
    return new BffOfferingService(socket);
}


/**
 * 
 */
class BffOfferingService implements OfferingService {
    
    /**
     *
     */
    constructor(private socket : Socket) {        
    }

    /**
     * 
     * @param request 
     */
    async getOffering(request: offering.GetOfferingRequest): Promise<offering.GetOfferingResponse> {
        const response = await emitEvent<offering.GetOfferingResponse, offering.GetOfferingRequest>(
            this.socket, "offering:getOffering", request
        );

        return response;
    }
}