import { Socket } from "socket.io-client";
import { pet } from "@anything-pet/grpc-lib";
import { emitEvent } from "../components/SocketIO";

/**
 * 
 * @param socket 
 * @returns 
 */
export function getPetServiceProxy(socket : Socket) : pet.PetService {
    return new PetServiceProxy(socket);
}

/**
 * 
 */
class PetServiceProxy implements pet.PetService {

    constructor(private socket : Socket) { }

    /**
     * 
     */
    async getPets(request: pet.GetPetsRequest): Promise<pet.GetPetsResponse> {
        const response = await emitEvent<pet.GetPetsResponse, pet.GetPetsRequest>(
            this.socket, "pet:getPets", request
        );

        return response;
    }

    /**
     * 
     * @param request 
     */
    async getPet(request: pet.GetPetRequest): Promise<pet.GetPetResponse> {
        const response = await emitEvent<pet.GetPetResponse, pet.GetPetRequest>(
            this.socket, "pet:getPet", request
        );

        return response;
    }

    /**
     * 
     * @param request 
     */
    async addPet(request: pet.AddPetRequest): Promise<pet.AddPetResponse> {
        const response = await emitEvent<pet.AddPetResponse, pet.AddPetRequest>(
            this.socket, "pet:addPet", request
        );

        return response;
    }

    /**
     * 
     * @param request 
     */
    async updatePet(request: pet.UpdatePetRequest): Promise<pet.UpdatePetResponse> {
        const response = await emitEvent<pet.UpdatePetResponse, pet.UpdatePetRequest>(
            this.socket, "pet:updatePet", request
        );

        return response;
    }

    /**
     * 
     * @param request 
     */
    async removePet(request: pet.RemovePetRequest): Promise<pet.RemovePetResponse> {
        const response = await emitEvent<pet.RemovePetResponse, pet.RemovePetRequest>(
            this.socket, "pet:removePet", request
        );

        return response;
    }
}