/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "anythingpet.staffAdmin";

export interface StaffAdmin {
  id: string;
  vendorId: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  position: string;
}

export interface StaffAdminForUpdate {
  id: string;
  vendorId: string;
  firstName?: string | undefined;
  lastName?: string | undefined;
  emailAddress?: string | undefined;
  position?: string | undefined;
}

export interface VendorAccess {
  vendorId: string;
  role: string;
}

export interface GetStaffAdminsRequest {
  vendorId: string;
}

export interface GetStaffAdminsResponse {
  staff: StaffAdmin[];
}

export interface GetStaffAdminsByOfferingRequest {
  vendorId: string;
  offeringId: string;
}

export interface GetStaffAdminsByOfferingResponse {
  staff: StaffAdmin[];
}

export interface GetStaffAdminRequest {
  id: string;
  vendorId: string;
}

export interface GetStaffAdminResponse {
  staff: StaffAdmin | undefined;
}

export interface AddStaffAdminRequest {
  staff: StaffAdmin | undefined;
}

export interface AddStaffAdminResponse {
  staff: StaffAdmin | undefined;
}

export interface UpdateStaffAdminRequest {
  staff: StaffAdminForUpdate | undefined;
}

export interface UpdateStaffAdminResponse {
  status: string;
}

export interface RemoveStaffAdminRequest {
  id: string;
  vendorId: string;
}

export interface RemoveStaffAdminResponse {
  status: string;
}

export interface SetSupportedOfferingsRequest {
  id: string;
  vendorId: string;
  offeringId: string[];
}

export interface SetSupportedOfferingsResponse {
  status: string;
}

export interface GetSupportedOfferingsRequest {
  id: string;
  vendorId: string;
}

export interface GetSupportedOfferingsResponse {
  offeringId: string[];
}

function createBaseStaffAdmin(): StaffAdmin {
  return { id: "", vendorId: "", firstName: "", lastName: "", emailAddress: "", position: "" };
}

export const StaffAdmin = {
  encode(message: StaffAdmin, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.vendorId !== "") {
      writer.uint32(18).string(message.vendorId);
    }
    if (message.firstName !== "") {
      writer.uint32(26).string(message.firstName);
    }
    if (message.lastName !== "") {
      writer.uint32(34).string(message.lastName);
    }
    if (message.emailAddress !== "") {
      writer.uint32(42).string(message.emailAddress);
    }
    if (message.position !== "") {
      writer.uint32(50).string(message.position);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StaffAdmin {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStaffAdmin();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.vendorId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.firstName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.lastName = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.emailAddress = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.position = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StaffAdmin {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      vendorId: isSet(object.vendorId) ? globalThis.String(object.vendorId) : "",
      firstName: isSet(object.firstName) ? globalThis.String(object.firstName) : "",
      lastName: isSet(object.lastName) ? globalThis.String(object.lastName) : "",
      emailAddress: isSet(object.emailAddress) ? globalThis.String(object.emailAddress) : "",
      position: isSet(object.position) ? globalThis.String(object.position) : "",
    };
  },

  toJSON(message: StaffAdmin): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.vendorId !== "") {
      obj.vendorId = message.vendorId;
    }
    if (message.firstName !== "") {
      obj.firstName = message.firstName;
    }
    if (message.lastName !== "") {
      obj.lastName = message.lastName;
    }
    if (message.emailAddress !== "") {
      obj.emailAddress = message.emailAddress;
    }
    if (message.position !== "") {
      obj.position = message.position;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StaffAdmin>, I>>(base?: I): StaffAdmin {
    return StaffAdmin.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StaffAdmin>, I>>(object: I): StaffAdmin {
    const message = createBaseStaffAdmin();
    message.id = object.id ?? "";
    message.vendorId = object.vendorId ?? "";
    message.firstName = object.firstName ?? "";
    message.lastName = object.lastName ?? "";
    message.emailAddress = object.emailAddress ?? "";
    message.position = object.position ?? "";
    return message;
  },
};

function createBaseStaffAdminForUpdate(): StaffAdminForUpdate {
  return {
    id: "",
    vendorId: "",
    firstName: undefined,
    lastName: undefined,
    emailAddress: undefined,
    position: undefined,
  };
}

export const StaffAdminForUpdate = {
  encode(message: StaffAdminForUpdate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.vendorId !== "") {
      writer.uint32(18).string(message.vendorId);
    }
    if (message.firstName !== undefined) {
      writer.uint32(26).string(message.firstName);
    }
    if (message.lastName !== undefined) {
      writer.uint32(34).string(message.lastName);
    }
    if (message.emailAddress !== undefined) {
      writer.uint32(42).string(message.emailAddress);
    }
    if (message.position !== undefined) {
      writer.uint32(50).string(message.position);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StaffAdminForUpdate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStaffAdminForUpdate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.vendorId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.firstName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.lastName = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.emailAddress = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.position = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StaffAdminForUpdate {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      vendorId: isSet(object.vendorId) ? globalThis.String(object.vendorId) : "",
      firstName: isSet(object.firstName) ? globalThis.String(object.firstName) : undefined,
      lastName: isSet(object.lastName) ? globalThis.String(object.lastName) : undefined,
      emailAddress: isSet(object.emailAddress) ? globalThis.String(object.emailAddress) : undefined,
      position: isSet(object.position) ? globalThis.String(object.position) : undefined,
    };
  },

  toJSON(message: StaffAdminForUpdate): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.vendorId !== "") {
      obj.vendorId = message.vendorId;
    }
    if (message.firstName !== undefined) {
      obj.firstName = message.firstName;
    }
    if (message.lastName !== undefined) {
      obj.lastName = message.lastName;
    }
    if (message.emailAddress !== undefined) {
      obj.emailAddress = message.emailAddress;
    }
    if (message.position !== undefined) {
      obj.position = message.position;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StaffAdminForUpdate>, I>>(base?: I): StaffAdminForUpdate {
    return StaffAdminForUpdate.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StaffAdminForUpdate>, I>>(object: I): StaffAdminForUpdate {
    const message = createBaseStaffAdminForUpdate();
    message.id = object.id ?? "";
    message.vendorId = object.vendorId ?? "";
    message.firstName = object.firstName ?? undefined;
    message.lastName = object.lastName ?? undefined;
    message.emailAddress = object.emailAddress ?? undefined;
    message.position = object.position ?? undefined;
    return message;
  },
};

function createBaseVendorAccess(): VendorAccess {
  return { vendorId: "", role: "" };
}

export const VendorAccess = {
  encode(message: VendorAccess, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.vendorId !== "") {
      writer.uint32(10).string(message.vendorId);
    }
    if (message.role !== "") {
      writer.uint32(18).string(message.role);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VendorAccess {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVendorAccess();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.vendorId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.role = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VendorAccess {
    return {
      vendorId: isSet(object.vendorId) ? globalThis.String(object.vendorId) : "",
      role: isSet(object.role) ? globalThis.String(object.role) : "",
    };
  },

  toJSON(message: VendorAccess): unknown {
    const obj: any = {};
    if (message.vendorId !== "") {
      obj.vendorId = message.vendorId;
    }
    if (message.role !== "") {
      obj.role = message.role;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VendorAccess>, I>>(base?: I): VendorAccess {
    return VendorAccess.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VendorAccess>, I>>(object: I): VendorAccess {
    const message = createBaseVendorAccess();
    message.vendorId = object.vendorId ?? "";
    message.role = object.role ?? "";
    return message;
  },
};

function createBaseGetStaffAdminsRequest(): GetStaffAdminsRequest {
  return { vendorId: "" };
}

export const GetStaffAdminsRequest = {
  encode(message: GetStaffAdminsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.vendorId !== "") {
      writer.uint32(10).string(message.vendorId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetStaffAdminsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetStaffAdminsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.vendorId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetStaffAdminsRequest {
    return { vendorId: isSet(object.vendorId) ? globalThis.String(object.vendorId) : "" };
  },

  toJSON(message: GetStaffAdminsRequest): unknown {
    const obj: any = {};
    if (message.vendorId !== "") {
      obj.vendorId = message.vendorId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetStaffAdminsRequest>, I>>(base?: I): GetStaffAdminsRequest {
    return GetStaffAdminsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetStaffAdminsRequest>, I>>(object: I): GetStaffAdminsRequest {
    const message = createBaseGetStaffAdminsRequest();
    message.vendorId = object.vendorId ?? "";
    return message;
  },
};

function createBaseGetStaffAdminsResponse(): GetStaffAdminsResponse {
  return { staff: [] };
}

export const GetStaffAdminsResponse = {
  encode(message: GetStaffAdminsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.staff) {
      StaffAdmin.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetStaffAdminsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetStaffAdminsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.staff.push(StaffAdmin.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetStaffAdminsResponse {
    return {
      staff: globalThis.Array.isArray(object?.staff) ? object.staff.map((e: any) => StaffAdmin.fromJSON(e)) : [],
    };
  },

  toJSON(message: GetStaffAdminsResponse): unknown {
    const obj: any = {};
    if (message.staff?.length) {
      obj.staff = message.staff.map((e) => StaffAdmin.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetStaffAdminsResponse>, I>>(base?: I): GetStaffAdminsResponse {
    return GetStaffAdminsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetStaffAdminsResponse>, I>>(object: I): GetStaffAdminsResponse {
    const message = createBaseGetStaffAdminsResponse();
    message.staff = object.staff?.map((e) => StaffAdmin.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetStaffAdminsByOfferingRequest(): GetStaffAdminsByOfferingRequest {
  return { vendorId: "", offeringId: "" };
}

export const GetStaffAdminsByOfferingRequest = {
  encode(message: GetStaffAdminsByOfferingRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.vendorId !== "") {
      writer.uint32(10).string(message.vendorId);
    }
    if (message.offeringId !== "") {
      writer.uint32(18).string(message.offeringId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetStaffAdminsByOfferingRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetStaffAdminsByOfferingRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.vendorId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.offeringId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetStaffAdminsByOfferingRequest {
    return {
      vendorId: isSet(object.vendorId) ? globalThis.String(object.vendorId) : "",
      offeringId: isSet(object.offeringId) ? globalThis.String(object.offeringId) : "",
    };
  },

  toJSON(message: GetStaffAdminsByOfferingRequest): unknown {
    const obj: any = {};
    if (message.vendorId !== "") {
      obj.vendorId = message.vendorId;
    }
    if (message.offeringId !== "") {
      obj.offeringId = message.offeringId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetStaffAdminsByOfferingRequest>, I>>(base?: I): GetStaffAdminsByOfferingRequest {
    return GetStaffAdminsByOfferingRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetStaffAdminsByOfferingRequest>, I>>(
    object: I,
  ): GetStaffAdminsByOfferingRequest {
    const message = createBaseGetStaffAdminsByOfferingRequest();
    message.vendorId = object.vendorId ?? "";
    message.offeringId = object.offeringId ?? "";
    return message;
  },
};

function createBaseGetStaffAdminsByOfferingResponse(): GetStaffAdminsByOfferingResponse {
  return { staff: [] };
}

export const GetStaffAdminsByOfferingResponse = {
  encode(message: GetStaffAdminsByOfferingResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.staff) {
      StaffAdmin.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetStaffAdminsByOfferingResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetStaffAdminsByOfferingResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.staff.push(StaffAdmin.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetStaffAdminsByOfferingResponse {
    return {
      staff: globalThis.Array.isArray(object?.staff) ? object.staff.map((e: any) => StaffAdmin.fromJSON(e)) : [],
    };
  },

  toJSON(message: GetStaffAdminsByOfferingResponse): unknown {
    const obj: any = {};
    if (message.staff?.length) {
      obj.staff = message.staff.map((e) => StaffAdmin.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetStaffAdminsByOfferingResponse>, I>>(
    base?: I,
  ): GetStaffAdminsByOfferingResponse {
    return GetStaffAdminsByOfferingResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetStaffAdminsByOfferingResponse>, I>>(
    object: I,
  ): GetStaffAdminsByOfferingResponse {
    const message = createBaseGetStaffAdminsByOfferingResponse();
    message.staff = object.staff?.map((e) => StaffAdmin.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetStaffAdminRequest(): GetStaffAdminRequest {
  return { id: "", vendorId: "" };
}

export const GetStaffAdminRequest = {
  encode(message: GetStaffAdminRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.vendorId !== "") {
      writer.uint32(18).string(message.vendorId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetStaffAdminRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetStaffAdminRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.vendorId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetStaffAdminRequest {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      vendorId: isSet(object.vendorId) ? globalThis.String(object.vendorId) : "",
    };
  },

  toJSON(message: GetStaffAdminRequest): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.vendorId !== "") {
      obj.vendorId = message.vendorId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetStaffAdminRequest>, I>>(base?: I): GetStaffAdminRequest {
    return GetStaffAdminRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetStaffAdminRequest>, I>>(object: I): GetStaffAdminRequest {
    const message = createBaseGetStaffAdminRequest();
    message.id = object.id ?? "";
    message.vendorId = object.vendorId ?? "";
    return message;
  },
};

function createBaseGetStaffAdminResponse(): GetStaffAdminResponse {
  return { staff: undefined };
}

export const GetStaffAdminResponse = {
  encode(message: GetStaffAdminResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.staff !== undefined) {
      StaffAdmin.encode(message.staff, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetStaffAdminResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetStaffAdminResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.staff = StaffAdmin.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetStaffAdminResponse {
    return { staff: isSet(object.staff) ? StaffAdmin.fromJSON(object.staff) : undefined };
  },

  toJSON(message: GetStaffAdminResponse): unknown {
    const obj: any = {};
    if (message.staff !== undefined) {
      obj.staff = StaffAdmin.toJSON(message.staff);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetStaffAdminResponse>, I>>(base?: I): GetStaffAdminResponse {
    return GetStaffAdminResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetStaffAdminResponse>, I>>(object: I): GetStaffAdminResponse {
    const message = createBaseGetStaffAdminResponse();
    message.staff = (object.staff !== undefined && object.staff !== null)
      ? StaffAdmin.fromPartial(object.staff)
      : undefined;
    return message;
  },
};

function createBaseAddStaffAdminRequest(): AddStaffAdminRequest {
  return { staff: undefined };
}

export const AddStaffAdminRequest = {
  encode(message: AddStaffAdminRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.staff !== undefined) {
      StaffAdmin.encode(message.staff, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddStaffAdminRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddStaffAdminRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.staff = StaffAdmin.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AddStaffAdminRequest {
    return { staff: isSet(object.staff) ? StaffAdmin.fromJSON(object.staff) : undefined };
  },

  toJSON(message: AddStaffAdminRequest): unknown {
    const obj: any = {};
    if (message.staff !== undefined) {
      obj.staff = StaffAdmin.toJSON(message.staff);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AddStaffAdminRequest>, I>>(base?: I): AddStaffAdminRequest {
    return AddStaffAdminRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddStaffAdminRequest>, I>>(object: I): AddStaffAdminRequest {
    const message = createBaseAddStaffAdminRequest();
    message.staff = (object.staff !== undefined && object.staff !== null)
      ? StaffAdmin.fromPartial(object.staff)
      : undefined;
    return message;
  },
};

function createBaseAddStaffAdminResponse(): AddStaffAdminResponse {
  return { staff: undefined };
}

export const AddStaffAdminResponse = {
  encode(message: AddStaffAdminResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.staff !== undefined) {
      StaffAdmin.encode(message.staff, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddStaffAdminResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddStaffAdminResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.staff = StaffAdmin.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AddStaffAdminResponse {
    return { staff: isSet(object.staff) ? StaffAdmin.fromJSON(object.staff) : undefined };
  },

  toJSON(message: AddStaffAdminResponse): unknown {
    const obj: any = {};
    if (message.staff !== undefined) {
      obj.staff = StaffAdmin.toJSON(message.staff);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AddStaffAdminResponse>, I>>(base?: I): AddStaffAdminResponse {
    return AddStaffAdminResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddStaffAdminResponse>, I>>(object: I): AddStaffAdminResponse {
    const message = createBaseAddStaffAdminResponse();
    message.staff = (object.staff !== undefined && object.staff !== null)
      ? StaffAdmin.fromPartial(object.staff)
      : undefined;
    return message;
  },
};

function createBaseUpdateStaffAdminRequest(): UpdateStaffAdminRequest {
  return { staff: undefined };
}

export const UpdateStaffAdminRequest = {
  encode(message: UpdateStaffAdminRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.staff !== undefined) {
      StaffAdminForUpdate.encode(message.staff, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateStaffAdminRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateStaffAdminRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.staff = StaffAdminForUpdate.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateStaffAdminRequest {
    return { staff: isSet(object.staff) ? StaffAdminForUpdate.fromJSON(object.staff) : undefined };
  },

  toJSON(message: UpdateStaffAdminRequest): unknown {
    const obj: any = {};
    if (message.staff !== undefined) {
      obj.staff = StaffAdminForUpdate.toJSON(message.staff);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateStaffAdminRequest>, I>>(base?: I): UpdateStaffAdminRequest {
    return UpdateStaffAdminRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateStaffAdminRequest>, I>>(object: I): UpdateStaffAdminRequest {
    const message = createBaseUpdateStaffAdminRequest();
    message.staff = (object.staff !== undefined && object.staff !== null)
      ? StaffAdminForUpdate.fromPartial(object.staff)
      : undefined;
    return message;
  },
};

function createBaseUpdateStaffAdminResponse(): UpdateStaffAdminResponse {
  return { status: "" };
}

export const UpdateStaffAdminResponse = {
  encode(message: UpdateStaffAdminResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== "") {
      writer.uint32(10).string(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateStaffAdminResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateStaffAdminResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.status = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateStaffAdminResponse {
    return { status: isSet(object.status) ? globalThis.String(object.status) : "" };
  },

  toJSON(message: UpdateStaffAdminResponse): unknown {
    const obj: any = {};
    if (message.status !== "") {
      obj.status = message.status;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateStaffAdminResponse>, I>>(base?: I): UpdateStaffAdminResponse {
    return UpdateStaffAdminResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateStaffAdminResponse>, I>>(object: I): UpdateStaffAdminResponse {
    const message = createBaseUpdateStaffAdminResponse();
    message.status = object.status ?? "";
    return message;
  },
};

function createBaseRemoveStaffAdminRequest(): RemoveStaffAdminRequest {
  return { id: "", vendorId: "" };
}

export const RemoveStaffAdminRequest = {
  encode(message: RemoveStaffAdminRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.vendorId !== "") {
      writer.uint32(18).string(message.vendorId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveStaffAdminRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveStaffAdminRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.vendorId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RemoveStaffAdminRequest {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      vendorId: isSet(object.vendorId) ? globalThis.String(object.vendorId) : "",
    };
  },

  toJSON(message: RemoveStaffAdminRequest): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.vendorId !== "") {
      obj.vendorId = message.vendorId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RemoveStaffAdminRequest>, I>>(base?: I): RemoveStaffAdminRequest {
    return RemoveStaffAdminRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RemoveStaffAdminRequest>, I>>(object: I): RemoveStaffAdminRequest {
    const message = createBaseRemoveStaffAdminRequest();
    message.id = object.id ?? "";
    message.vendorId = object.vendorId ?? "";
    return message;
  },
};

function createBaseRemoveStaffAdminResponse(): RemoveStaffAdminResponse {
  return { status: "" };
}

export const RemoveStaffAdminResponse = {
  encode(message: RemoveStaffAdminResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== "") {
      writer.uint32(10).string(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveStaffAdminResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveStaffAdminResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.status = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RemoveStaffAdminResponse {
    return { status: isSet(object.status) ? globalThis.String(object.status) : "" };
  },

  toJSON(message: RemoveStaffAdminResponse): unknown {
    const obj: any = {};
    if (message.status !== "") {
      obj.status = message.status;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RemoveStaffAdminResponse>, I>>(base?: I): RemoveStaffAdminResponse {
    return RemoveStaffAdminResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RemoveStaffAdminResponse>, I>>(object: I): RemoveStaffAdminResponse {
    const message = createBaseRemoveStaffAdminResponse();
    message.status = object.status ?? "";
    return message;
  },
};

function createBaseSetSupportedOfferingsRequest(): SetSupportedOfferingsRequest {
  return { id: "", vendorId: "", offeringId: [] };
}

export const SetSupportedOfferingsRequest = {
  encode(message: SetSupportedOfferingsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.vendorId !== "") {
      writer.uint32(18).string(message.vendorId);
    }
    for (const v of message.offeringId) {
      writer.uint32(26).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetSupportedOfferingsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetSupportedOfferingsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.vendorId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.offeringId.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetSupportedOfferingsRequest {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      vendorId: isSet(object.vendorId) ? globalThis.String(object.vendorId) : "",
      offeringId: globalThis.Array.isArray(object?.offeringId)
        ? object.offeringId.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: SetSupportedOfferingsRequest): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.vendorId !== "") {
      obj.vendorId = message.vendorId;
    }
    if (message.offeringId?.length) {
      obj.offeringId = message.offeringId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetSupportedOfferingsRequest>, I>>(base?: I): SetSupportedOfferingsRequest {
    return SetSupportedOfferingsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetSupportedOfferingsRequest>, I>>(object: I): SetSupportedOfferingsRequest {
    const message = createBaseSetSupportedOfferingsRequest();
    message.id = object.id ?? "";
    message.vendorId = object.vendorId ?? "";
    message.offeringId = object.offeringId?.map((e) => e) || [];
    return message;
  },
};

function createBaseSetSupportedOfferingsResponse(): SetSupportedOfferingsResponse {
  return { status: "" };
}

export const SetSupportedOfferingsResponse = {
  encode(message: SetSupportedOfferingsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== "") {
      writer.uint32(10).string(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetSupportedOfferingsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetSupportedOfferingsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.status = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetSupportedOfferingsResponse {
    return { status: isSet(object.status) ? globalThis.String(object.status) : "" };
  },

  toJSON(message: SetSupportedOfferingsResponse): unknown {
    const obj: any = {};
    if (message.status !== "") {
      obj.status = message.status;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetSupportedOfferingsResponse>, I>>(base?: I): SetSupportedOfferingsResponse {
    return SetSupportedOfferingsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetSupportedOfferingsResponse>, I>>(
    object: I,
  ): SetSupportedOfferingsResponse {
    const message = createBaseSetSupportedOfferingsResponse();
    message.status = object.status ?? "";
    return message;
  },
};

function createBaseGetSupportedOfferingsRequest(): GetSupportedOfferingsRequest {
  return { id: "", vendorId: "" };
}

export const GetSupportedOfferingsRequest = {
  encode(message: GetSupportedOfferingsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.vendorId !== "") {
      writer.uint32(18).string(message.vendorId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetSupportedOfferingsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetSupportedOfferingsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.vendorId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetSupportedOfferingsRequest {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      vendorId: isSet(object.vendorId) ? globalThis.String(object.vendorId) : "",
    };
  },

  toJSON(message: GetSupportedOfferingsRequest): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.vendorId !== "") {
      obj.vendorId = message.vendorId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetSupportedOfferingsRequest>, I>>(base?: I): GetSupportedOfferingsRequest {
    return GetSupportedOfferingsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetSupportedOfferingsRequest>, I>>(object: I): GetSupportedOfferingsRequest {
    const message = createBaseGetSupportedOfferingsRequest();
    message.id = object.id ?? "";
    message.vendorId = object.vendorId ?? "";
    return message;
  },
};

function createBaseGetSupportedOfferingsResponse(): GetSupportedOfferingsResponse {
  return { offeringId: [] };
}

export const GetSupportedOfferingsResponse = {
  encode(message: GetSupportedOfferingsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.offeringId) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetSupportedOfferingsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetSupportedOfferingsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.offeringId.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetSupportedOfferingsResponse {
    return {
      offeringId: globalThis.Array.isArray(object?.offeringId)
        ? object.offeringId.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: GetSupportedOfferingsResponse): unknown {
    const obj: any = {};
    if (message.offeringId?.length) {
      obj.offeringId = message.offeringId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetSupportedOfferingsResponse>, I>>(base?: I): GetSupportedOfferingsResponse {
    return GetSupportedOfferingsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetSupportedOfferingsResponse>, I>>(
    object: I,
  ): GetSupportedOfferingsResponse {
    const message = createBaseGetSupportedOfferingsResponse();
    message.offeringId = object.offeringId?.map((e) => e) || [];
    return message;
  },
};

export interface StaffAdminService {
  getStaffAdmins(request: GetStaffAdminsRequest): Promise<GetStaffAdminsResponse>;
  getStaffAdminsByOffering(request: GetStaffAdminsByOfferingRequest): Promise<GetStaffAdminsByOfferingResponse>;
  getStaffAdmin(request: GetStaffAdminRequest): Promise<GetStaffAdminResponse>;
  addStaffAdmin(request: AddStaffAdminRequest): Promise<AddStaffAdminResponse>;
  updateStaffAdmin(request: UpdateStaffAdminRequest): Promise<UpdateStaffAdminResponse>;
  removeStaffAdmin(request: RemoveStaffAdminRequest): Promise<RemoveStaffAdminResponse>;
  setSupportedOfferings(request: SetSupportedOfferingsRequest): Promise<SetSupportedOfferingsResponse>;
  getSupportedOfferings(request: GetSupportedOfferingsRequest): Promise<GetSupportedOfferingsResponse>;
}

export const StaffAdminServiceServiceName = "anythingpet.staffAdmin.StaffAdminService";
export class StaffAdminServiceClientImpl implements StaffAdminService {
  private readonly rpc: Rpc;
  private readonly service: string;
  constructor(rpc: Rpc, opts?: { service?: string }) {
    this.service = opts?.service || StaffAdminServiceServiceName;
    this.rpc = rpc;
    this.getStaffAdmins = this.getStaffAdmins.bind(this);
    this.getStaffAdminsByOffering = this.getStaffAdminsByOffering.bind(this);
    this.getStaffAdmin = this.getStaffAdmin.bind(this);
    this.addStaffAdmin = this.addStaffAdmin.bind(this);
    this.updateStaffAdmin = this.updateStaffAdmin.bind(this);
    this.removeStaffAdmin = this.removeStaffAdmin.bind(this);
    this.setSupportedOfferings = this.setSupportedOfferings.bind(this);
    this.getSupportedOfferings = this.getSupportedOfferings.bind(this);
  }
  getStaffAdmins(request: GetStaffAdminsRequest): Promise<GetStaffAdminsResponse> {
    const data = GetStaffAdminsRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "getStaffAdmins", data);
    return promise.then((data) => GetStaffAdminsResponse.decode(_m0.Reader.create(data)));
  }

  getStaffAdminsByOffering(request: GetStaffAdminsByOfferingRequest): Promise<GetStaffAdminsByOfferingResponse> {
    const data = GetStaffAdminsByOfferingRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "getStaffAdminsByOffering", data);
    return promise.then((data) => GetStaffAdminsByOfferingResponse.decode(_m0.Reader.create(data)));
  }

  getStaffAdmin(request: GetStaffAdminRequest): Promise<GetStaffAdminResponse> {
    const data = GetStaffAdminRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "getStaffAdmin", data);
    return promise.then((data) => GetStaffAdminResponse.decode(_m0.Reader.create(data)));
  }

  addStaffAdmin(request: AddStaffAdminRequest): Promise<AddStaffAdminResponse> {
    const data = AddStaffAdminRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "addStaffAdmin", data);
    return promise.then((data) => AddStaffAdminResponse.decode(_m0.Reader.create(data)));
  }

  updateStaffAdmin(request: UpdateStaffAdminRequest): Promise<UpdateStaffAdminResponse> {
    const data = UpdateStaffAdminRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "updateStaffAdmin", data);
    return promise.then((data) => UpdateStaffAdminResponse.decode(_m0.Reader.create(data)));
  }

  removeStaffAdmin(request: RemoveStaffAdminRequest): Promise<RemoveStaffAdminResponse> {
    const data = RemoveStaffAdminRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "removeStaffAdmin", data);
    return promise.then((data) => RemoveStaffAdminResponse.decode(_m0.Reader.create(data)));
  }

  setSupportedOfferings(request: SetSupportedOfferingsRequest): Promise<SetSupportedOfferingsResponse> {
    const data = SetSupportedOfferingsRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "setSupportedOfferings", data);
    return promise.then((data) => SetSupportedOfferingsResponse.decode(_m0.Reader.create(data)));
  }

  getSupportedOfferings(request: GetSupportedOfferingsRequest): Promise<GetSupportedOfferingsResponse> {
    const data = GetSupportedOfferingsRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "getSupportedOfferings", data);
    return promise.then((data) => GetSupportedOfferingsResponse.decode(_m0.Reader.create(data)));
  }
}

interface Rpc {
  request(service: string, method: string, data: Uint8Array): Promise<Uint8Array>;
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
