/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "anythingpet.userprofile";

export interface UserProfile {
  id: string;
  email: string;
  username?: string | undefined;
  firstName?: string | undefined;
  lastName?: string | undefined;
  phoneNumber?: string | undefined;
}

export interface UserProfileForUpdate {
  id: string;
  username?: string | undefined;
  firstName?: string | undefined;
  lastName?: string | undefined;
  phoneNumber?: string | undefined;
}

export interface GetUserProfileRequest {
  id: string;
}

export interface GetUserProfileResponse {
  profile: UserProfile | undefined;
}

export interface UpdateUserProfileRequest {
  profile: UserProfileForUpdate | undefined;
}

export interface UpdateUserProfileResponse {
  status: string;
}

function createBaseUserProfile(): UserProfile {
  return { id: "", email: "", username: undefined, firstName: undefined, lastName: undefined, phoneNumber: undefined };
}

export const UserProfile = {
  encode(message: UserProfile, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.email !== "") {
      writer.uint32(18).string(message.email);
    }
    if (message.username !== undefined) {
      writer.uint32(26).string(message.username);
    }
    if (message.firstName !== undefined) {
      writer.uint32(34).string(message.firstName);
    }
    if (message.lastName !== undefined) {
      writer.uint32(42).string(message.lastName);
    }
    if (message.phoneNumber !== undefined) {
      writer.uint32(50).string(message.phoneNumber);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserProfile {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserProfile();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.email = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.username = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.firstName = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.lastName = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.phoneNumber = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserProfile {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      email: isSet(object.email) ? globalThis.String(object.email) : "",
      username: isSet(object.username) ? globalThis.String(object.username) : undefined,
      firstName: isSet(object.firstName) ? globalThis.String(object.firstName) : undefined,
      lastName: isSet(object.lastName) ? globalThis.String(object.lastName) : undefined,
      phoneNumber: isSet(object.phoneNumber) ? globalThis.String(object.phoneNumber) : undefined,
    };
  },

  toJSON(message: UserProfile): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.email !== "") {
      obj.email = message.email;
    }
    if (message.username !== undefined) {
      obj.username = message.username;
    }
    if (message.firstName !== undefined) {
      obj.firstName = message.firstName;
    }
    if (message.lastName !== undefined) {
      obj.lastName = message.lastName;
    }
    if (message.phoneNumber !== undefined) {
      obj.phoneNumber = message.phoneNumber;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserProfile>, I>>(base?: I): UserProfile {
    return UserProfile.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserProfile>, I>>(object: I): UserProfile {
    const message = createBaseUserProfile();
    message.id = object.id ?? "";
    message.email = object.email ?? "";
    message.username = object.username ?? undefined;
    message.firstName = object.firstName ?? undefined;
    message.lastName = object.lastName ?? undefined;
    message.phoneNumber = object.phoneNumber ?? undefined;
    return message;
  },
};

function createBaseUserProfileForUpdate(): UserProfileForUpdate {
  return { id: "", username: undefined, firstName: undefined, lastName: undefined, phoneNumber: undefined };
}

export const UserProfileForUpdate = {
  encode(message: UserProfileForUpdate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.username !== undefined) {
      writer.uint32(18).string(message.username);
    }
    if (message.firstName !== undefined) {
      writer.uint32(26).string(message.firstName);
    }
    if (message.lastName !== undefined) {
      writer.uint32(34).string(message.lastName);
    }
    if (message.phoneNumber !== undefined) {
      writer.uint32(42).string(message.phoneNumber);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserProfileForUpdate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserProfileForUpdate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.username = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.firstName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.lastName = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.phoneNumber = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserProfileForUpdate {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      username: isSet(object.username) ? globalThis.String(object.username) : undefined,
      firstName: isSet(object.firstName) ? globalThis.String(object.firstName) : undefined,
      lastName: isSet(object.lastName) ? globalThis.String(object.lastName) : undefined,
      phoneNumber: isSet(object.phoneNumber) ? globalThis.String(object.phoneNumber) : undefined,
    };
  },

  toJSON(message: UserProfileForUpdate): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.username !== undefined) {
      obj.username = message.username;
    }
    if (message.firstName !== undefined) {
      obj.firstName = message.firstName;
    }
    if (message.lastName !== undefined) {
      obj.lastName = message.lastName;
    }
    if (message.phoneNumber !== undefined) {
      obj.phoneNumber = message.phoneNumber;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserProfileForUpdate>, I>>(base?: I): UserProfileForUpdate {
    return UserProfileForUpdate.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserProfileForUpdate>, I>>(object: I): UserProfileForUpdate {
    const message = createBaseUserProfileForUpdate();
    message.id = object.id ?? "";
    message.username = object.username ?? undefined;
    message.firstName = object.firstName ?? undefined;
    message.lastName = object.lastName ?? undefined;
    message.phoneNumber = object.phoneNumber ?? undefined;
    return message;
  },
};

function createBaseGetUserProfileRequest(): GetUserProfileRequest {
  return { id: "" };
}

export const GetUserProfileRequest = {
  encode(message: GetUserProfileRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetUserProfileRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetUserProfileRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetUserProfileRequest {
    return { id: isSet(object.id) ? globalThis.String(object.id) : "" };
  },

  toJSON(message: GetUserProfileRequest): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetUserProfileRequest>, I>>(base?: I): GetUserProfileRequest {
    return GetUserProfileRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetUserProfileRequest>, I>>(object: I): GetUserProfileRequest {
    const message = createBaseGetUserProfileRequest();
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseGetUserProfileResponse(): GetUserProfileResponse {
  return { profile: undefined };
}

export const GetUserProfileResponse = {
  encode(message: GetUserProfileResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.profile !== undefined) {
      UserProfile.encode(message.profile, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetUserProfileResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetUserProfileResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.profile = UserProfile.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetUserProfileResponse {
    return { profile: isSet(object.profile) ? UserProfile.fromJSON(object.profile) : undefined };
  },

  toJSON(message: GetUserProfileResponse): unknown {
    const obj: any = {};
    if (message.profile !== undefined) {
      obj.profile = UserProfile.toJSON(message.profile);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetUserProfileResponse>, I>>(base?: I): GetUserProfileResponse {
    return GetUserProfileResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetUserProfileResponse>, I>>(object: I): GetUserProfileResponse {
    const message = createBaseGetUserProfileResponse();
    message.profile = (object.profile !== undefined && object.profile !== null)
      ? UserProfile.fromPartial(object.profile)
      : undefined;
    return message;
  },
};

function createBaseUpdateUserProfileRequest(): UpdateUserProfileRequest {
  return { profile: undefined };
}

export const UpdateUserProfileRequest = {
  encode(message: UpdateUserProfileRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.profile !== undefined) {
      UserProfileForUpdate.encode(message.profile, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateUserProfileRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateUserProfileRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.profile = UserProfileForUpdate.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateUserProfileRequest {
    return { profile: isSet(object.profile) ? UserProfileForUpdate.fromJSON(object.profile) : undefined };
  },

  toJSON(message: UpdateUserProfileRequest): unknown {
    const obj: any = {};
    if (message.profile !== undefined) {
      obj.profile = UserProfileForUpdate.toJSON(message.profile);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateUserProfileRequest>, I>>(base?: I): UpdateUserProfileRequest {
    return UpdateUserProfileRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateUserProfileRequest>, I>>(object: I): UpdateUserProfileRequest {
    const message = createBaseUpdateUserProfileRequest();
    message.profile = (object.profile !== undefined && object.profile !== null)
      ? UserProfileForUpdate.fromPartial(object.profile)
      : undefined;
    return message;
  },
};

function createBaseUpdateUserProfileResponse(): UpdateUserProfileResponse {
  return { status: "" };
}

export const UpdateUserProfileResponse = {
  encode(message: UpdateUserProfileResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== "") {
      writer.uint32(10).string(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateUserProfileResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateUserProfileResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.status = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateUserProfileResponse {
    return { status: isSet(object.status) ? globalThis.String(object.status) : "" };
  },

  toJSON(message: UpdateUserProfileResponse): unknown {
    const obj: any = {};
    if (message.status !== "") {
      obj.status = message.status;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateUserProfileResponse>, I>>(base?: I): UpdateUserProfileResponse {
    return UpdateUserProfileResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateUserProfileResponse>, I>>(object: I): UpdateUserProfileResponse {
    const message = createBaseUpdateUserProfileResponse();
    message.status = object.status ?? "";
    return message;
  },
};

export interface UserProfileService {
  getUserProfile(request: GetUserProfileRequest): Promise<GetUserProfileResponse>;
  updateUserProfile(request: UpdateUserProfileRequest): Promise<UpdateUserProfileResponse>;
}

export const UserProfileServiceServiceName = "anythingpet.userprofile.UserProfileService";
export class UserProfileServiceClientImpl implements UserProfileService {
  private readonly rpc: Rpc;
  private readonly service: string;
  constructor(rpc: Rpc, opts?: { service?: string }) {
    this.service = opts?.service || UserProfileServiceServiceName;
    this.rpc = rpc;
    this.getUserProfile = this.getUserProfile.bind(this);
    this.updateUserProfile = this.updateUserProfile.bind(this);
  }
  getUserProfile(request: GetUserProfileRequest): Promise<GetUserProfileResponse> {
    const data = GetUserProfileRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "getUserProfile", data);
    return promise.then((data) => GetUserProfileResponse.decode(_m0.Reader.create(data)));
  }

  updateUserProfile(request: UpdateUserProfileRequest): Promise<UpdateUserProfileResponse> {
    const data = UpdateUserProfileRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "updateUserProfile", data);
    return promise.then((data) => UpdateUserProfileResponse.decode(_m0.Reader.create(data)));
  }
}

interface Rpc {
  request(service: string, method: string, data: Uint8Array): Promise<Uint8Array>;
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
