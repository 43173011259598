import * as vendorAdmin from "./proto/vendorAdmin";

/**
 * 
 * @param hours 
 * @returns 
 */
export function getOfficeHoursAsArray(hours?: vendorAdmin.OfficeHours) {
    if (! hours) {
        return [,,,,,,];
    }

    return [
        hours.sunday,
        hours.monday,
        hours.tuesday,
        hours.wednesday,
        hours.thursday,
        hours.friday,
        hours.saturday
    ];
}