import { booking } from '@anything-pet/grpc-lib'
import { Socket } from 'socket.io-client';
import { emitEvent } from '../components/SocketIO';


/**
 * 
 * @param socket 
 * @returns 
 */
export function getBookingServiceProxy(socket: Socket): booking.BookingService {
    return new BffBookingServiceProxy(socket);
}

/**
 * 
 */
class BffBookingServiceProxy implements booking.BookingService {
    /**
     * 
     * @param socket 
     */
    constructor(private socket: Socket) { }    

    /**
     * 
     * @param request 
     * @returns 
     */
    getAvailableBookingDays(request: booking.GetAvailableBookingDaysRequest): Promise<booking.GetAvailableBookingDaysResponse> {
        return emitEvent<booking.GetAvailableBookingDaysResponse, booking.GetAvailableBookingDaysRequest>(
            this.socket, "booking:getAvailableBookingDays", request
        );
    }

    /**
     * 
     * @param request 
     * @returns 
     */
    getAvailableSlots(request: booking.GetAvailableSlotsRequest): Promise<booking.GetAvailableSlotsResponse> {
        return emitEvent<booking.GetAvailableSlotsResponse, booking.GetAvailableSlotsRequest>(
            this.socket, "booking:getAvailableSlots", request
        );
    }
}
