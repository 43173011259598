/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "anythingpet.staff";

export interface Staff {
  id: string;
  vendorId: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  position: string;
}

export interface VendorAccess {
  vendorId: string;
  role: string;
}

export interface GetStaffsRequest {
  vendorId: string;
}

export interface GetStaffsResponse {
  staff: Staff[];
}

export interface GetStaffsByOfferingRequest {
  vendorId: string;
  offeringId: string;
}

export interface GetStaffsByOfferingResponse {
  staff: Staff[];
}

export interface GetStaffRequest {
  id: string;
  vendorId: string;
}

export interface GetStaffResponse {
  staff: Staff | undefined;
}

export interface GetVendorAccessRequest {
  email: string;
}

export interface GetVendorAccessResponse {
  access: VendorAccess[];
}

export interface GetSupportedOfferingsRequest {
  id: string;
  vendorId: string;
}

export interface GetSupportedOfferingsResponse {
  offeringId: string[];
}

function createBaseStaff(): Staff {
  return { id: "", vendorId: "", firstName: "", lastName: "", emailAddress: "", position: "" };
}

export const Staff = {
  encode(message: Staff, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.vendorId !== "") {
      writer.uint32(18).string(message.vendorId);
    }
    if (message.firstName !== "") {
      writer.uint32(26).string(message.firstName);
    }
    if (message.lastName !== "") {
      writer.uint32(34).string(message.lastName);
    }
    if (message.emailAddress !== "") {
      writer.uint32(42).string(message.emailAddress);
    }
    if (message.position !== "") {
      writer.uint32(50).string(message.position);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Staff {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStaff();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.vendorId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.firstName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.lastName = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.emailAddress = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.position = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Staff {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      vendorId: isSet(object.vendorId) ? globalThis.String(object.vendorId) : "",
      firstName: isSet(object.firstName) ? globalThis.String(object.firstName) : "",
      lastName: isSet(object.lastName) ? globalThis.String(object.lastName) : "",
      emailAddress: isSet(object.emailAddress) ? globalThis.String(object.emailAddress) : "",
      position: isSet(object.position) ? globalThis.String(object.position) : "",
    };
  },

  toJSON(message: Staff): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.vendorId !== "") {
      obj.vendorId = message.vendorId;
    }
    if (message.firstName !== "") {
      obj.firstName = message.firstName;
    }
    if (message.lastName !== "") {
      obj.lastName = message.lastName;
    }
    if (message.emailAddress !== "") {
      obj.emailAddress = message.emailAddress;
    }
    if (message.position !== "") {
      obj.position = message.position;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Staff>, I>>(base?: I): Staff {
    return Staff.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Staff>, I>>(object: I): Staff {
    const message = createBaseStaff();
    message.id = object.id ?? "";
    message.vendorId = object.vendorId ?? "";
    message.firstName = object.firstName ?? "";
    message.lastName = object.lastName ?? "";
    message.emailAddress = object.emailAddress ?? "";
    message.position = object.position ?? "";
    return message;
  },
};

function createBaseVendorAccess(): VendorAccess {
  return { vendorId: "", role: "" };
}

export const VendorAccess = {
  encode(message: VendorAccess, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.vendorId !== "") {
      writer.uint32(10).string(message.vendorId);
    }
    if (message.role !== "") {
      writer.uint32(18).string(message.role);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VendorAccess {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVendorAccess();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.vendorId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.role = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VendorAccess {
    return {
      vendorId: isSet(object.vendorId) ? globalThis.String(object.vendorId) : "",
      role: isSet(object.role) ? globalThis.String(object.role) : "",
    };
  },

  toJSON(message: VendorAccess): unknown {
    const obj: any = {};
    if (message.vendorId !== "") {
      obj.vendorId = message.vendorId;
    }
    if (message.role !== "") {
      obj.role = message.role;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VendorAccess>, I>>(base?: I): VendorAccess {
    return VendorAccess.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VendorAccess>, I>>(object: I): VendorAccess {
    const message = createBaseVendorAccess();
    message.vendorId = object.vendorId ?? "";
    message.role = object.role ?? "";
    return message;
  },
};

function createBaseGetStaffsRequest(): GetStaffsRequest {
  return { vendorId: "" };
}

export const GetStaffsRequest = {
  encode(message: GetStaffsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.vendorId !== "") {
      writer.uint32(10).string(message.vendorId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetStaffsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetStaffsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.vendorId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetStaffsRequest {
    return { vendorId: isSet(object.vendorId) ? globalThis.String(object.vendorId) : "" };
  },

  toJSON(message: GetStaffsRequest): unknown {
    const obj: any = {};
    if (message.vendorId !== "") {
      obj.vendorId = message.vendorId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetStaffsRequest>, I>>(base?: I): GetStaffsRequest {
    return GetStaffsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetStaffsRequest>, I>>(object: I): GetStaffsRequest {
    const message = createBaseGetStaffsRequest();
    message.vendorId = object.vendorId ?? "";
    return message;
  },
};

function createBaseGetStaffsResponse(): GetStaffsResponse {
  return { staff: [] };
}

export const GetStaffsResponse = {
  encode(message: GetStaffsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.staff) {
      Staff.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetStaffsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetStaffsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.staff.push(Staff.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetStaffsResponse {
    return { staff: globalThis.Array.isArray(object?.staff) ? object.staff.map((e: any) => Staff.fromJSON(e)) : [] };
  },

  toJSON(message: GetStaffsResponse): unknown {
    const obj: any = {};
    if (message.staff?.length) {
      obj.staff = message.staff.map((e) => Staff.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetStaffsResponse>, I>>(base?: I): GetStaffsResponse {
    return GetStaffsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetStaffsResponse>, I>>(object: I): GetStaffsResponse {
    const message = createBaseGetStaffsResponse();
    message.staff = object.staff?.map((e) => Staff.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetStaffsByOfferingRequest(): GetStaffsByOfferingRequest {
  return { vendorId: "", offeringId: "" };
}

export const GetStaffsByOfferingRequest = {
  encode(message: GetStaffsByOfferingRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.vendorId !== "") {
      writer.uint32(10).string(message.vendorId);
    }
    if (message.offeringId !== "") {
      writer.uint32(18).string(message.offeringId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetStaffsByOfferingRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetStaffsByOfferingRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.vendorId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.offeringId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetStaffsByOfferingRequest {
    return {
      vendorId: isSet(object.vendorId) ? globalThis.String(object.vendorId) : "",
      offeringId: isSet(object.offeringId) ? globalThis.String(object.offeringId) : "",
    };
  },

  toJSON(message: GetStaffsByOfferingRequest): unknown {
    const obj: any = {};
    if (message.vendorId !== "") {
      obj.vendorId = message.vendorId;
    }
    if (message.offeringId !== "") {
      obj.offeringId = message.offeringId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetStaffsByOfferingRequest>, I>>(base?: I): GetStaffsByOfferingRequest {
    return GetStaffsByOfferingRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetStaffsByOfferingRequest>, I>>(object: I): GetStaffsByOfferingRequest {
    const message = createBaseGetStaffsByOfferingRequest();
    message.vendorId = object.vendorId ?? "";
    message.offeringId = object.offeringId ?? "";
    return message;
  },
};

function createBaseGetStaffsByOfferingResponse(): GetStaffsByOfferingResponse {
  return { staff: [] };
}

export const GetStaffsByOfferingResponse = {
  encode(message: GetStaffsByOfferingResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.staff) {
      Staff.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetStaffsByOfferingResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetStaffsByOfferingResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.staff.push(Staff.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetStaffsByOfferingResponse {
    return { staff: globalThis.Array.isArray(object?.staff) ? object.staff.map((e: any) => Staff.fromJSON(e)) : [] };
  },

  toJSON(message: GetStaffsByOfferingResponse): unknown {
    const obj: any = {};
    if (message.staff?.length) {
      obj.staff = message.staff.map((e) => Staff.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetStaffsByOfferingResponse>, I>>(base?: I): GetStaffsByOfferingResponse {
    return GetStaffsByOfferingResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetStaffsByOfferingResponse>, I>>(object: I): GetStaffsByOfferingResponse {
    const message = createBaseGetStaffsByOfferingResponse();
    message.staff = object.staff?.map((e) => Staff.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetStaffRequest(): GetStaffRequest {
  return { id: "", vendorId: "" };
}

export const GetStaffRequest = {
  encode(message: GetStaffRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.vendorId !== "") {
      writer.uint32(18).string(message.vendorId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetStaffRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetStaffRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.vendorId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetStaffRequest {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      vendorId: isSet(object.vendorId) ? globalThis.String(object.vendorId) : "",
    };
  },

  toJSON(message: GetStaffRequest): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.vendorId !== "") {
      obj.vendorId = message.vendorId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetStaffRequest>, I>>(base?: I): GetStaffRequest {
    return GetStaffRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetStaffRequest>, I>>(object: I): GetStaffRequest {
    const message = createBaseGetStaffRequest();
    message.id = object.id ?? "";
    message.vendorId = object.vendorId ?? "";
    return message;
  },
};

function createBaseGetStaffResponse(): GetStaffResponse {
  return { staff: undefined };
}

export const GetStaffResponse = {
  encode(message: GetStaffResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.staff !== undefined) {
      Staff.encode(message.staff, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetStaffResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetStaffResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.staff = Staff.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetStaffResponse {
    return { staff: isSet(object.staff) ? Staff.fromJSON(object.staff) : undefined };
  },

  toJSON(message: GetStaffResponse): unknown {
    const obj: any = {};
    if (message.staff !== undefined) {
      obj.staff = Staff.toJSON(message.staff);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetStaffResponse>, I>>(base?: I): GetStaffResponse {
    return GetStaffResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetStaffResponse>, I>>(object: I): GetStaffResponse {
    const message = createBaseGetStaffResponse();
    message.staff = (object.staff !== undefined && object.staff !== null) ? Staff.fromPartial(object.staff) : undefined;
    return message;
  },
};

function createBaseGetVendorAccessRequest(): GetVendorAccessRequest {
  return { email: "" };
}

export const GetVendorAccessRequest = {
  encode(message: GetVendorAccessRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.email !== "") {
      writer.uint32(10).string(message.email);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetVendorAccessRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetVendorAccessRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.email = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetVendorAccessRequest {
    return { email: isSet(object.email) ? globalThis.String(object.email) : "" };
  },

  toJSON(message: GetVendorAccessRequest): unknown {
    const obj: any = {};
    if (message.email !== "") {
      obj.email = message.email;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetVendorAccessRequest>, I>>(base?: I): GetVendorAccessRequest {
    return GetVendorAccessRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetVendorAccessRequest>, I>>(object: I): GetVendorAccessRequest {
    const message = createBaseGetVendorAccessRequest();
    message.email = object.email ?? "";
    return message;
  },
};

function createBaseGetVendorAccessResponse(): GetVendorAccessResponse {
  return { access: [] };
}

export const GetVendorAccessResponse = {
  encode(message: GetVendorAccessResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.access) {
      VendorAccess.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetVendorAccessResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetVendorAccessResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.access.push(VendorAccess.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetVendorAccessResponse {
    return {
      access: globalThis.Array.isArray(object?.access) ? object.access.map((e: any) => VendorAccess.fromJSON(e)) : [],
    };
  },

  toJSON(message: GetVendorAccessResponse): unknown {
    const obj: any = {};
    if (message.access?.length) {
      obj.access = message.access.map((e) => VendorAccess.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetVendorAccessResponse>, I>>(base?: I): GetVendorAccessResponse {
    return GetVendorAccessResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetVendorAccessResponse>, I>>(object: I): GetVendorAccessResponse {
    const message = createBaseGetVendorAccessResponse();
    message.access = object.access?.map((e) => VendorAccess.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetSupportedOfferingsRequest(): GetSupportedOfferingsRequest {
  return { id: "", vendorId: "" };
}

export const GetSupportedOfferingsRequest = {
  encode(message: GetSupportedOfferingsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.vendorId !== "") {
      writer.uint32(18).string(message.vendorId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetSupportedOfferingsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetSupportedOfferingsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.vendorId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetSupportedOfferingsRequest {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      vendorId: isSet(object.vendorId) ? globalThis.String(object.vendorId) : "",
    };
  },

  toJSON(message: GetSupportedOfferingsRequest): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.vendorId !== "") {
      obj.vendorId = message.vendorId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetSupportedOfferingsRequest>, I>>(base?: I): GetSupportedOfferingsRequest {
    return GetSupportedOfferingsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetSupportedOfferingsRequest>, I>>(object: I): GetSupportedOfferingsRequest {
    const message = createBaseGetSupportedOfferingsRequest();
    message.id = object.id ?? "";
    message.vendorId = object.vendorId ?? "";
    return message;
  },
};

function createBaseGetSupportedOfferingsResponse(): GetSupportedOfferingsResponse {
  return { offeringId: [] };
}

export const GetSupportedOfferingsResponse = {
  encode(message: GetSupportedOfferingsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.offeringId) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetSupportedOfferingsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetSupportedOfferingsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.offeringId.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetSupportedOfferingsResponse {
    return {
      offeringId: globalThis.Array.isArray(object?.offeringId)
        ? object.offeringId.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: GetSupportedOfferingsResponse): unknown {
    const obj: any = {};
    if (message.offeringId?.length) {
      obj.offeringId = message.offeringId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetSupportedOfferingsResponse>, I>>(base?: I): GetSupportedOfferingsResponse {
    return GetSupportedOfferingsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetSupportedOfferingsResponse>, I>>(
    object: I,
  ): GetSupportedOfferingsResponse {
    const message = createBaseGetSupportedOfferingsResponse();
    message.offeringId = object.offeringId?.map((e) => e) || [];
    return message;
  },
};

export interface StaffService {
  getVendorAccess(request: GetVendorAccessRequest): Promise<GetVendorAccessResponse>;
  getStaffs(request: GetStaffsRequest): Promise<GetStaffsResponse>;
  getStaffsByOffering(request: GetStaffsByOfferingRequest): Promise<GetStaffsByOfferingResponse>;
  getStaff(request: GetStaffRequest): Promise<GetStaffResponse>;
  getSupportedOfferings(request: GetSupportedOfferingsRequest): Promise<GetSupportedOfferingsResponse>;
}

export const StaffServiceServiceName = "anythingpet.staff.StaffService";
export class StaffServiceClientImpl implements StaffService {
  private readonly rpc: Rpc;
  private readonly service: string;
  constructor(rpc: Rpc, opts?: { service?: string }) {
    this.service = opts?.service || StaffServiceServiceName;
    this.rpc = rpc;
    this.getVendorAccess = this.getVendorAccess.bind(this);
    this.getStaffs = this.getStaffs.bind(this);
    this.getStaffsByOffering = this.getStaffsByOffering.bind(this);
    this.getStaff = this.getStaff.bind(this);
    this.getSupportedOfferings = this.getSupportedOfferings.bind(this);
  }
  getVendorAccess(request: GetVendorAccessRequest): Promise<GetVendorAccessResponse> {
    const data = GetVendorAccessRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "getVendorAccess", data);
    return promise.then((data) => GetVendorAccessResponse.decode(_m0.Reader.create(data)));
  }

  getStaffs(request: GetStaffsRequest): Promise<GetStaffsResponse> {
    const data = GetStaffsRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "getStaffs", data);
    return promise.then((data) => GetStaffsResponse.decode(_m0.Reader.create(data)));
  }

  getStaffsByOffering(request: GetStaffsByOfferingRequest): Promise<GetStaffsByOfferingResponse> {
    const data = GetStaffsByOfferingRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "getStaffsByOffering", data);
    return promise.then((data) => GetStaffsByOfferingResponse.decode(_m0.Reader.create(data)));
  }

  getStaff(request: GetStaffRequest): Promise<GetStaffResponse> {
    const data = GetStaffRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "getStaff", data);
    return promise.then((data) => GetStaffResponse.decode(_m0.Reader.create(data)));
  }

  getSupportedOfferings(request: GetSupportedOfferingsRequest): Promise<GetSupportedOfferingsResponse> {
    const data = GetSupportedOfferingsRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "getSupportedOfferings", data);
    return promise.then((data) => GetSupportedOfferingsResponse.decode(_m0.Reader.create(data)));
  }
}

interface Rpc {
  request(service: string, method: string, data: Uint8Array): Promise<Uint8Array>;
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
