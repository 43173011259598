
import { useEffect, useState } from "react";
import { LoginButton, LogoutButton, useAuthManager, AuthenticatedTemplate, UnauthenticatedTemplate } from '../components/Auth'
import { SocketIOProvider, useSocketIO } from "../components/SocketIO";
import { getUserProfileServiceProxy } from '../services/UserProfileServiceProxy'
import {Box, Button, Divider, Typography} from "@mui/material";
const loginScopes = ["openid", "profile", "offline_access"];
const apiScopes = ['https://anythingpet.onmicrosoft.com/consumer-api/ConsumerAPI.Access'];

/**
 * 
 */
function UserProfile() {
    const authService = useAuthManager();
    const socket = useSocketIO();
    const [profileJson, setProfileJson] = useState<string>();
    const [message, setMessage] = useState<string>();

    const userId = authService?.user.userId;

    if (! userId) {
        throw new Error('User is not authenticated');
    }
    
    useEffect(() => {        
        async function load() {
            if (userId) {
                const proxy = getUserProfileServiceProxy(socket);

                const result = await proxy.getUserProfile({
                        id: userId
                    });

                const json = {
                    "firstName" : result.profile?.firstName,
                    "lastName":result.profile?.lastName,
                    "email":result.profile?.email,
                    "phoneNumber":result.profile?.phoneNumber
                }

                setProfileJson(
                    JSON.stringify(json, undefined, 2)
                    );
            }
        }

        load();
    }, [socket, userId]);

    const onSaveProfile = async () => {
        setMessage('');

        if (profileJson) {
            const profile = JSON.parse(profileJson) as {firstName : string, lastName: string, email: string, phoneNumber: string};

            const proxy = getUserProfileServiceProxy(socket);

            const result = await proxy.updateUserProfile({
                    profile: {
                        id: userId,
                        firstName: profile.firstName,
                        lastName: profile.lastName,
                        phoneNumber: profile.phoneNumber
                    }
                });

            setMessage(`Operation: ${result.status}`);
        }
    }

    const getAccessToken = async () => {
        const response = await authService.acquireTokenSilent({
             scopes: apiScopes 
            });

        setMessage(response.accessToken);
    }

    return (
        <>
            <Typography variant="h5" gutterBottom>
                User profile
            </Typography>
            <form>                
                <div className="mb-3">
                    <textarea className="form-control" value={profileJson} rows={10} cols={50} onChange={(evt) => setProfileJson(evt.target.value)} ></textarea>
                </div>
                <Box sx={{my:2}}>
                    <Button variant="contained" onClick={onSaveProfile}>Save</Button>
                </Box>
            </form>
            <Box sx={{my:2}}>
                <Divider sx={{my:2}} />
                <Button variant="contained" onClick={getAccessToken}>get access token</Button>
                <Typography variant="body1" gutterBottom sx={{my:2}}>
                {message}
                </Typography>
            </Box>
        </>
    )
}

export default function TestProfilePage() {
    const authService = useAuthManager();

    if (! authService) {
        return (
            <h1>Logging in </h1>
        )
    }

    return (
        <>
            <Box sx={{my:2}}>
                <Typography variant="h5" gutterBottom>
                    Account Object
                </Typography>
                <pre>
                    {JSON.stringify(authService.user, undefined, 2)}
                </pre>
            </Box>
            <UserProfile />
        </>
    );
}