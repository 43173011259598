/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "anythingpet.staffBooking";

export interface UserBooking {
  id: string;
  vendorId: string;
  userId: string;
  petId: string;
  staffId: string;
  offeringId: string;
  /** How long the booking will take (in minutes) */
  startDateTime: string;
  endDateTime: string;
}

export interface UserBookingReport {
  id: string;
  vendorId: string;
  userId: string;
  petId: string;
  staffId: string;
  offeringId: string;
  /** How long the booking will take (in minutes) */
  startDateTime: string;
  endDateTime: string;
  offeringName: string;
  petName: string;
  userDisplayName: string;
}

export interface UserBookingCount {
  startDateTime: string;
  bookingCount: number;
}

export interface GetBookingsRequest {
  vendorId: string;
  staffId: string;
  fromDateTime: string;
  toDateTime: string;
}

export interface GetBookingsResponse {
  booking: UserBookingReport[];
}

export interface GetBookingCountsByDaysRequest {
  vendorId: string;
  staffId: string;
  fromDateTime: string;
  toDateTime: string;
}

export interface GetBookingCountsByDaysResponse {
  staffId: string;
  booking: UserBookingCount[];
}

function createBaseUserBooking(): UserBooking {
  return {
    id: "",
    vendorId: "",
    userId: "",
    petId: "",
    staffId: "",
    offeringId: "",
    startDateTime: "",
    endDateTime: "",
  };
}

export const UserBooking = {
  encode(message: UserBooking, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.vendorId !== "") {
      writer.uint32(18).string(message.vendorId);
    }
    if (message.userId !== "") {
      writer.uint32(26).string(message.userId);
    }
    if (message.petId !== "") {
      writer.uint32(34).string(message.petId);
    }
    if (message.staffId !== "") {
      writer.uint32(42).string(message.staffId);
    }
    if (message.offeringId !== "") {
      writer.uint32(50).string(message.offeringId);
    }
    if (message.startDateTime !== "") {
      writer.uint32(58).string(message.startDateTime);
    }
    if (message.endDateTime !== "") {
      writer.uint32(66).string(message.endDateTime);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserBooking {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserBooking();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.vendorId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.petId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.staffId = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.offeringId = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.startDateTime = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.endDateTime = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserBooking {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      vendorId: isSet(object.vendorId) ? globalThis.String(object.vendorId) : "",
      userId: isSet(object.userId) ? globalThis.String(object.userId) : "",
      petId: isSet(object.petId) ? globalThis.String(object.petId) : "",
      staffId: isSet(object.staffId) ? globalThis.String(object.staffId) : "",
      offeringId: isSet(object.offeringId) ? globalThis.String(object.offeringId) : "",
      startDateTime: isSet(object.startDateTime) ? globalThis.String(object.startDateTime) : "",
      endDateTime: isSet(object.endDateTime) ? globalThis.String(object.endDateTime) : "",
    };
  },

  toJSON(message: UserBooking): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.vendorId !== "") {
      obj.vendorId = message.vendorId;
    }
    if (message.userId !== "") {
      obj.userId = message.userId;
    }
    if (message.petId !== "") {
      obj.petId = message.petId;
    }
    if (message.staffId !== "") {
      obj.staffId = message.staffId;
    }
    if (message.offeringId !== "") {
      obj.offeringId = message.offeringId;
    }
    if (message.startDateTime !== "") {
      obj.startDateTime = message.startDateTime;
    }
    if (message.endDateTime !== "") {
      obj.endDateTime = message.endDateTime;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserBooking>, I>>(base?: I): UserBooking {
    return UserBooking.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserBooking>, I>>(object: I): UserBooking {
    const message = createBaseUserBooking();
    message.id = object.id ?? "";
    message.vendorId = object.vendorId ?? "";
    message.userId = object.userId ?? "";
    message.petId = object.petId ?? "";
    message.staffId = object.staffId ?? "";
    message.offeringId = object.offeringId ?? "";
    message.startDateTime = object.startDateTime ?? "";
    message.endDateTime = object.endDateTime ?? "";
    return message;
  },
};

function createBaseUserBookingReport(): UserBookingReport {
  return {
    id: "",
    vendorId: "",
    userId: "",
    petId: "",
    staffId: "",
    offeringId: "",
    startDateTime: "",
    endDateTime: "",
    offeringName: "",
    petName: "",
    userDisplayName: "",
  };
}

export const UserBookingReport = {
  encode(message: UserBookingReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.vendorId !== "") {
      writer.uint32(18).string(message.vendorId);
    }
    if (message.userId !== "") {
      writer.uint32(26).string(message.userId);
    }
    if (message.petId !== "") {
      writer.uint32(34).string(message.petId);
    }
    if (message.staffId !== "") {
      writer.uint32(42).string(message.staffId);
    }
    if (message.offeringId !== "") {
      writer.uint32(50).string(message.offeringId);
    }
    if (message.startDateTime !== "") {
      writer.uint32(58).string(message.startDateTime);
    }
    if (message.endDateTime !== "") {
      writer.uint32(66).string(message.endDateTime);
    }
    if (message.offeringName !== "") {
      writer.uint32(170).string(message.offeringName);
    }
    if (message.petName !== "") {
      writer.uint32(178).string(message.petName);
    }
    if (message.userDisplayName !== "") {
      writer.uint32(186).string(message.userDisplayName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserBookingReport {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserBookingReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.vendorId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.petId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.staffId = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.offeringId = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.startDateTime = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.endDateTime = reader.string();
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.offeringName = reader.string();
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.petName = reader.string();
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.userDisplayName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserBookingReport {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      vendorId: isSet(object.vendorId) ? globalThis.String(object.vendorId) : "",
      userId: isSet(object.userId) ? globalThis.String(object.userId) : "",
      petId: isSet(object.petId) ? globalThis.String(object.petId) : "",
      staffId: isSet(object.staffId) ? globalThis.String(object.staffId) : "",
      offeringId: isSet(object.offeringId) ? globalThis.String(object.offeringId) : "",
      startDateTime: isSet(object.startDateTime) ? globalThis.String(object.startDateTime) : "",
      endDateTime: isSet(object.endDateTime) ? globalThis.String(object.endDateTime) : "",
      offeringName: isSet(object.offeringName) ? globalThis.String(object.offeringName) : "",
      petName: isSet(object.petName) ? globalThis.String(object.petName) : "",
      userDisplayName: isSet(object.userDisplayName) ? globalThis.String(object.userDisplayName) : "",
    };
  },

  toJSON(message: UserBookingReport): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.vendorId !== "") {
      obj.vendorId = message.vendorId;
    }
    if (message.userId !== "") {
      obj.userId = message.userId;
    }
    if (message.petId !== "") {
      obj.petId = message.petId;
    }
    if (message.staffId !== "") {
      obj.staffId = message.staffId;
    }
    if (message.offeringId !== "") {
      obj.offeringId = message.offeringId;
    }
    if (message.startDateTime !== "") {
      obj.startDateTime = message.startDateTime;
    }
    if (message.endDateTime !== "") {
      obj.endDateTime = message.endDateTime;
    }
    if (message.offeringName !== "") {
      obj.offeringName = message.offeringName;
    }
    if (message.petName !== "") {
      obj.petName = message.petName;
    }
    if (message.userDisplayName !== "") {
      obj.userDisplayName = message.userDisplayName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserBookingReport>, I>>(base?: I): UserBookingReport {
    return UserBookingReport.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserBookingReport>, I>>(object: I): UserBookingReport {
    const message = createBaseUserBookingReport();
    message.id = object.id ?? "";
    message.vendorId = object.vendorId ?? "";
    message.userId = object.userId ?? "";
    message.petId = object.petId ?? "";
    message.staffId = object.staffId ?? "";
    message.offeringId = object.offeringId ?? "";
    message.startDateTime = object.startDateTime ?? "";
    message.endDateTime = object.endDateTime ?? "";
    message.offeringName = object.offeringName ?? "";
    message.petName = object.petName ?? "";
    message.userDisplayName = object.userDisplayName ?? "";
    return message;
  },
};

function createBaseUserBookingCount(): UserBookingCount {
  return { startDateTime: "", bookingCount: 0 };
}

export const UserBookingCount = {
  encode(message: UserBookingCount, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.startDateTime !== "") {
      writer.uint32(10).string(message.startDateTime);
    }
    if (message.bookingCount !== 0) {
      writer.uint32(16).int32(message.bookingCount);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserBookingCount {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserBookingCount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.startDateTime = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.bookingCount = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserBookingCount {
    return {
      startDateTime: isSet(object.startDateTime) ? globalThis.String(object.startDateTime) : "",
      bookingCount: isSet(object.bookingCount) ? globalThis.Number(object.bookingCount) : 0,
    };
  },

  toJSON(message: UserBookingCount): unknown {
    const obj: any = {};
    if (message.startDateTime !== "") {
      obj.startDateTime = message.startDateTime;
    }
    if (message.bookingCount !== 0) {
      obj.bookingCount = Math.round(message.bookingCount);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserBookingCount>, I>>(base?: I): UserBookingCount {
    return UserBookingCount.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserBookingCount>, I>>(object: I): UserBookingCount {
    const message = createBaseUserBookingCount();
    message.startDateTime = object.startDateTime ?? "";
    message.bookingCount = object.bookingCount ?? 0;
    return message;
  },
};

function createBaseGetBookingsRequest(): GetBookingsRequest {
  return { vendorId: "", staffId: "", fromDateTime: "", toDateTime: "" };
}

export const GetBookingsRequest = {
  encode(message: GetBookingsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.vendorId !== "") {
      writer.uint32(10).string(message.vendorId);
    }
    if (message.staffId !== "") {
      writer.uint32(18).string(message.staffId);
    }
    if (message.fromDateTime !== "") {
      writer.uint32(26).string(message.fromDateTime);
    }
    if (message.toDateTime !== "") {
      writer.uint32(34).string(message.toDateTime);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetBookingsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetBookingsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.vendorId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.staffId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.fromDateTime = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.toDateTime = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetBookingsRequest {
    return {
      vendorId: isSet(object.vendorId) ? globalThis.String(object.vendorId) : "",
      staffId: isSet(object.staffId) ? globalThis.String(object.staffId) : "",
      fromDateTime: isSet(object.fromDateTime) ? globalThis.String(object.fromDateTime) : "",
      toDateTime: isSet(object.toDateTime) ? globalThis.String(object.toDateTime) : "",
    };
  },

  toJSON(message: GetBookingsRequest): unknown {
    const obj: any = {};
    if (message.vendorId !== "") {
      obj.vendorId = message.vendorId;
    }
    if (message.staffId !== "") {
      obj.staffId = message.staffId;
    }
    if (message.fromDateTime !== "") {
      obj.fromDateTime = message.fromDateTime;
    }
    if (message.toDateTime !== "") {
      obj.toDateTime = message.toDateTime;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetBookingsRequest>, I>>(base?: I): GetBookingsRequest {
    return GetBookingsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetBookingsRequest>, I>>(object: I): GetBookingsRequest {
    const message = createBaseGetBookingsRequest();
    message.vendorId = object.vendorId ?? "";
    message.staffId = object.staffId ?? "";
    message.fromDateTime = object.fromDateTime ?? "";
    message.toDateTime = object.toDateTime ?? "";
    return message;
  },
};

function createBaseGetBookingsResponse(): GetBookingsResponse {
  return { booking: [] };
}

export const GetBookingsResponse = {
  encode(message: GetBookingsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.booking) {
      UserBookingReport.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetBookingsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetBookingsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.booking.push(UserBookingReport.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetBookingsResponse {
    return {
      booking: globalThis.Array.isArray(object?.booking)
        ? object.booking.map((e: any) => UserBookingReport.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetBookingsResponse): unknown {
    const obj: any = {};
    if (message.booking?.length) {
      obj.booking = message.booking.map((e) => UserBookingReport.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetBookingsResponse>, I>>(base?: I): GetBookingsResponse {
    return GetBookingsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetBookingsResponse>, I>>(object: I): GetBookingsResponse {
    const message = createBaseGetBookingsResponse();
    message.booking = object.booking?.map((e) => UserBookingReport.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetBookingCountsByDaysRequest(): GetBookingCountsByDaysRequest {
  return { vendorId: "", staffId: "", fromDateTime: "", toDateTime: "" };
}

export const GetBookingCountsByDaysRequest = {
  encode(message: GetBookingCountsByDaysRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.vendorId !== "") {
      writer.uint32(10).string(message.vendorId);
    }
    if (message.staffId !== "") {
      writer.uint32(18).string(message.staffId);
    }
    if (message.fromDateTime !== "") {
      writer.uint32(26).string(message.fromDateTime);
    }
    if (message.toDateTime !== "") {
      writer.uint32(34).string(message.toDateTime);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetBookingCountsByDaysRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetBookingCountsByDaysRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.vendorId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.staffId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.fromDateTime = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.toDateTime = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetBookingCountsByDaysRequest {
    return {
      vendorId: isSet(object.vendorId) ? globalThis.String(object.vendorId) : "",
      staffId: isSet(object.staffId) ? globalThis.String(object.staffId) : "",
      fromDateTime: isSet(object.fromDateTime) ? globalThis.String(object.fromDateTime) : "",
      toDateTime: isSet(object.toDateTime) ? globalThis.String(object.toDateTime) : "",
    };
  },

  toJSON(message: GetBookingCountsByDaysRequest): unknown {
    const obj: any = {};
    if (message.vendorId !== "") {
      obj.vendorId = message.vendorId;
    }
    if (message.staffId !== "") {
      obj.staffId = message.staffId;
    }
    if (message.fromDateTime !== "") {
      obj.fromDateTime = message.fromDateTime;
    }
    if (message.toDateTime !== "") {
      obj.toDateTime = message.toDateTime;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetBookingCountsByDaysRequest>, I>>(base?: I): GetBookingCountsByDaysRequest {
    return GetBookingCountsByDaysRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetBookingCountsByDaysRequest>, I>>(
    object: I,
  ): GetBookingCountsByDaysRequest {
    const message = createBaseGetBookingCountsByDaysRequest();
    message.vendorId = object.vendorId ?? "";
    message.staffId = object.staffId ?? "";
    message.fromDateTime = object.fromDateTime ?? "";
    message.toDateTime = object.toDateTime ?? "";
    return message;
  },
};

function createBaseGetBookingCountsByDaysResponse(): GetBookingCountsByDaysResponse {
  return { staffId: "", booking: [] };
}

export const GetBookingCountsByDaysResponse = {
  encode(message: GetBookingCountsByDaysResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.staffId !== "") {
      writer.uint32(10).string(message.staffId);
    }
    for (const v of message.booking) {
      UserBookingCount.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetBookingCountsByDaysResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetBookingCountsByDaysResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.staffId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.booking.push(UserBookingCount.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetBookingCountsByDaysResponse {
    return {
      staffId: isSet(object.staffId) ? globalThis.String(object.staffId) : "",
      booking: globalThis.Array.isArray(object?.booking)
        ? object.booking.map((e: any) => UserBookingCount.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetBookingCountsByDaysResponse): unknown {
    const obj: any = {};
    if (message.staffId !== "") {
      obj.staffId = message.staffId;
    }
    if (message.booking?.length) {
      obj.booking = message.booking.map((e) => UserBookingCount.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetBookingCountsByDaysResponse>, I>>(base?: I): GetBookingCountsByDaysResponse {
    return GetBookingCountsByDaysResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetBookingCountsByDaysResponse>, I>>(
    object: I,
  ): GetBookingCountsByDaysResponse {
    const message = createBaseGetBookingCountsByDaysResponse();
    message.staffId = object.staffId ?? "";
    message.booking = object.booking?.map((e) => UserBookingCount.fromPartial(e)) || [];
    return message;
  },
};

export interface StaffBookingService {
  getBookings(request: GetBookingsRequest): Promise<GetBookingsResponse>;
  getBookingCountsByDays(request: GetBookingCountsByDaysRequest): Promise<GetBookingCountsByDaysResponse>;
}

export const StaffBookingServiceServiceName = "anythingpet.staffBooking.StaffBookingService";
export class StaffBookingServiceClientImpl implements StaffBookingService {
  private readonly rpc: Rpc;
  private readonly service: string;
  constructor(rpc: Rpc, opts?: { service?: string }) {
    this.service = opts?.service || StaffBookingServiceServiceName;
    this.rpc = rpc;
    this.getBookings = this.getBookings.bind(this);
    this.getBookingCountsByDays = this.getBookingCountsByDays.bind(this);
  }
  getBookings(request: GetBookingsRequest): Promise<GetBookingsResponse> {
    const data = GetBookingsRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "getBookings", data);
    return promise.then((data) => GetBookingsResponse.decode(_m0.Reader.create(data)));
  }

  getBookingCountsByDays(request: GetBookingCountsByDaysRequest): Promise<GetBookingCountsByDaysResponse> {
    const data = GetBookingCountsByDaysRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "getBookingCountsByDays", data);
    return promise.then((data) => GetBookingCountsByDaysResponse.decode(_m0.Reader.create(data)));
  }
}

interface Rpc {
  request(service: string, method: string, data: Uint8Array): Promise<Uint8Array>;
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
