import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Typography,
    TextField,
    FormControl,
    Grid,
    InputLabel,
    Select,
    MenuItem,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Skeleton,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { pet, userProfile } from "@anything-pet/grpc-lib";
import dayjs, { Dayjs } from "dayjs";
import { useSocketIO } from "../components/SocketIO";
import { getUserProfileServiceProxy } from "../services/UserProfileServiceProxy";
import { getPetServiceProxy } from "../services/PetServiceProxy";
import { useAuthUser } from "./Auth";

type UserProfile = userProfile.UserProfile;

type PetProfile = Omit<pet.Pet, "consumerId" | "birthDate"> & { dob: Dayjs };

const toPetProfile = (pet: pet.Pet) => {
    const { birthDate, ...thePet } = pet;

    return {
        ...thePet,
        dob: dayjs(birthDate),
    };
};

const EditPet: React.FC<{
    pet: PetProfile;
    onClose: () => void;
    onUpdate: (pet: PetProfile, mode: "add" | "update" | "delete") => void;
}> = (props) => {
    const socket = useSocketIO();

    const user = useAuthUser();
    const userId = user?.userId;

    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [currentPet, setCurrentPet] = useState<PetProfile>(props.pet);

    const handleChangePet = (name: string, value: string) => {
        setCurrentPet((prev) => ({ ...prev, [name]: value }));
    };

    const handleDateChange = (date: Dayjs | null) => {
        setCurrentPet((prev) => {
            if (!date) {
                return prev;
            }
            return { ...prev, dob: date };
        });
    };

    const handleDeleteOrCancel = () => {
        if (currentPet.id === "") {
            props.onClose();
        } else {
            openConfirmationDialog();
        }
    };

    const openConfirmationDialog = () => {
        setIsConfirmationOpen(true);
    };

    const closeConfirmationDialog = () => {
        setIsConfirmationOpen(false);
    };

    const handleDeleteConfirmed = () => {
        executeDeletePet();
        closeConfirmationDialog();
        props.onClose();
    };

    const executeDeletePet = async () => {
        if (currentPet.id && userId) {
            const proxy = await getPetServiceProxy(socket);

            await proxy.removePet({
                consumerId: userId,
                id: currentPet.id,
            });

            props.onUpdate(currentPet, "delete");
            props.onClose();
        }
    };

    const handleSavePet = async () => {
        const proxy = await getPetServiceProxy(socket);

        if (userId) {
            if (currentPet.id) {
                await proxy.updatePet({
                    pet: {
                        consumerId: userId,
                        birthDate: currentPet.dob.toISOString(),
                        ...currentPet,
                    },
                });

                props.onUpdate(currentPet, "update");
            } else {
                const { pet } = await proxy.addPet({
                    pet: {
                        consumerId: userId,
                        birthDate: currentPet.dob.toISOString(),
                        ...currentPet,
                    },
                });

                props.onUpdate(currentPet, "add");
            }
        }

        props.onClose();
    };

    return (
        <Dialog open={true} onClose={props.onClose}>
            <DialogTitle>{currentPet.id ? "Edit Pet" : "Add Pet"}</DialogTitle>
            <DialogContent>
                <TextField
                    label="Name"
                    name="name"
                    value={currentPet.name || ""}
                    onChange={(e) =>
                        handleChangePet(e.target.name, e.target.value)
                    }
                    fullWidth
                    margin="normal"
                />
                <FormControl fullWidth margin="normal">
                    <InputLabel>Type</InputLabel>
                    <Select
                        name="petType"
                        value={currentPet?.petType || ""}
                        label="Type"
                        onChange={(e) =>
                            handleChangePet(e.target.name, e.target.value)
                        }
                    >
                        <MenuItem value="Dog">Dog</MenuItem>
                        <MenuItem value="Cat">Cat</MenuItem>
                    </Select>
                </FormControl>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label="Date of Birth"
                        value={currentPet?.dob || null}
                        onChange={handleDateChange}
                    />
                </LocalizationProvider>
                <FormControl fullWidth margin="normal">
                    <InputLabel>Gender</InputLabel>
                    <Select
                        name="gender"
                        value={currentPet?.gender || ""}
                        label="Gender"
                        onChange={(e) =>
                            handleChangePet(e.target.name, e.target.value)
                        }
                    >
                        <MenuItem value="Male">Male</MenuItem>
                        <MenuItem value="Female">Female</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    label="Pet Breed"
                    name="breed"
                    value={currentPet?.breed}
                    onChange={(e) =>
                        handleChangePet(e.target.name, e.target.value)
                    }
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Pet Hair Type"
                    name="hairType"
                    value={currentPet?.hairType}
                    onChange={(e) =>
                        handleChangePet(e.target.name, e.target.value)
                    }
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Pet Hair Color"
                    name="hairColor"
                    value={currentPet?.hairColor}
                    onChange={(e) =>
                        handleChangePet(e.target.name, e.target.value)
                    }
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Likes and Dislikes"
                    name="likesDislikes"
                    value={currentPet?.likesDislikes}
                    onChange={(e) =>
                        handleChangePet(e.target.name, e.target.value)
                    }
                    multiline
                    rows={4}
                    fullWidth
                    margin="normal"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDeleteOrCancel} color="error">
                    {currentPet?.id ? "Delete" : "Cancel"}
                </Button>
                <Button onClick={handleSavePet} color="primary">
                    Save
                </Button>
            </DialogActions>

            <Dialog open={isConfirmationOpen} onClose={closeConfirmationDialog}>
                <DialogTitle id="confirmation-dialog-title">
                    Confirm Delete
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirmation-dialog-description">
                        Are you sure you want to delete this pet?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeConfirmationDialog}>Cancel</Button>
                    <Button
                        onClick={handleDeleteConfirmed}
                        color="primary"
                        autoFocus
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Dialog>
    );
};

const PetListing: React.FC = () => {
    const socket = useSocketIO();
    const authUser = useAuthUser();
    const userId = authUser?.userId;

    const [user, setUser] = useState<UserProfile>();
    const [pets, setPets] = useState<PetProfile[]>();
    const [editPet, setEditPet] = useState<PetProfile>();

    useEffect(() => {
        async function loadUserProfile() {
            if (userId) {
                const proxy = getUserProfileServiceProxy(socket);

                const result = await proxy.getUserProfile({
                    id: userId,
                });

                setUser(result.profile);
            }
        }

        async function loadPets() {
            if (userId) {
                const proxy = getPetServiceProxy(socket);

                const { pet: pets } = await proxy.getPets({
                    consumerId: userId,
                });

                setPets(pets.map((pet) => toPetProfile(pet)));
            }
        }

        (async () => {
            await Promise.all([loadUserProfile(), loadPets()]);
        })();
    }, [userId, socket]);

    if (!userId) {
        throw new Error("Not authenticated");
    }

    if (!user || !pets) {
        return (
            <Box sx={{ paddingTop: 1 }}>
                <Skeleton variant="rectangular" height={180} />
            </Box>
        );
    }

    const handleAddPet = () => {
        const newPet: PetProfile = {
            id: "",
            name: "",
            petType: "Dog",
            dob: dayjs(),
            gender: "Male",
            breed: "",
            hairType: "",
            hairColor: "",
            likesDislikes: "",
        };

        setEditPet(newPet);
    };

    const handleEditPet = (petId: string) => {
        const petToEdit = pets.find((pet) => pet.id === petId);

        if (petToEdit) {
            setEditPet(petToEdit);
        }
    };

    const onUpdatePet = (
        updatedPet: PetProfile,
        mode: "add" | "update" | "delete"
    ) => {
        switch (mode) {
            case "delete":
                setPets(pets.filter((pet) => pet.id !== updatedPet.id));
                break;
            case "add":
            case "update": {
                const existingIndex = pets.findIndex(
                    (pet) => pet.id === updatedPet.id
                );

                if (existingIndex > -1) {
                    const updatedPets = [...pets];
                    updatedPets[existingIndex] = updatedPet;
                    setPets(updatedPets);
                } else {
                    setPets([...pets, updatedPet]);
                }
            }
        }
    };

    return (
        <Box sx={{ paddingTop: 1 }}>
            {pets.length === 0 ? (
                <Typography variant="body1">
                    You have not added any pets yet. Please start by adding a
                    pet by using the Add Pet button below.
                </Typography>
            ) : (
                <Box
                    sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 2,
                        marginTop: 0,
                    }}
                >
                    {pets.map((pet) => (
                        <Grid item xs={2} sm={4} md={4} key={pet.id}>
                            <Card sx={{ maxWidth: 345, m: 1 }}>
                                <CardContent>
                                    {/* Pet details here */}
                                    <Typography
                                        gutterBottom
                                        variant="h5"
                                        component="div"
                                    >
                                        {pet.name || "New Pet"}
                                    </Typography>
                                </CardContent>
                                <Button
                                    size="small"
                                    onClick={() => handleEditPet(pet.id)}
                                >
                                    Details
                                </Button>
                            </Card>
                        </Grid>
                    ))}
                </Box>
            )}
            {editPet ? (
                <EditPet
                    pet={editPet}
                    onClose={() => {
                        setEditPet(undefined);
                    }}
                    onUpdate={onUpdatePet}
                />
            ) : null}

            <Box sx={{ padding: 0, marginTop: 2 }}>
                <Button
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={handleAddPet}
                    variant="contained"
                >
                    Add Pet
                </Button>
            </Box>
        </Box>
    );
};

export default PetListing;
