import { AuthenticatedTemplate, UnauthenticatedTemplate, LoginButton, SignUpButton } from "../components/Auth";
import { PropsWithChildren } from "react";
import { Box, Typography} from '@mui/material';
import { Navigate } from "react-router-dom";


export function AnonymousHomePage(props: PropsWithChildren) {
    return (<>
        <AuthenticatedTemplate>
            <Navigate to='/user' />
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
            <Box sx={{ padding: 3 }}>
                <Typography variant="h4" gutterBottom>Welcome to Anything Pet</Typography>
                <p>Anything Pet helps you schedule appointments with your favourite and trusted pet care providers. If you already have an account, please log in using the button below.</p>
                <LoginButton /*loginScopes={loginScopes}*/ />  

                <SignUpButton /*loginScopes={loginScopes}*/ />  
            </Box>                   
        </UnauthenticatedTemplate>    
    </>        
    )
}