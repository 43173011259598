import { useEffect, useState } from "react"
import { useSocketIO } from "../components/SocketIO";
import { getUserBookingServiceProxy } from "../services/UserBookingServiceProxy";
import { userBooking } from "@anything-pet/grpc-lib";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LinearProgress from '@mui/material/LinearProgress';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { IconButton } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { UserBookingReport } from "@anything-pet/grpc-lib/dist/proto/userBooking";
import { servicesVersion } from "typescript";

export function MyBookings() {
    const socket = useSocketIO();
    const [bookings, setBookings] = useState<userBooking.UserBookingReport[]>();

    useEffect(() => {
        (async () => {
            const now = dayjs();
            const service = getUserBookingServiceProxy(socket);

            const { booking : bookings } = await service.getBookings({ 
                userId: '', 
                fromDateTime: now.startOf('day').toISOString(), 
                toDateTime: now.add(1, 'month').endOf('day').toISOString()
            });

            setBookings(bookings);
        })();
    }, [socket]);

    if (! bookings) {
        return(<>
            <Typography variant="h5" gutterBottom>
                Upcoming bookings
            </Typography>
            <LinearProgress />
        </>);
    }

    const onDelete = async (booking: UserBookingReport) => {
        const service = getUserBookingServiceProxy(socket);

        await service.removeBooking({
            id: booking.id,
            userId: '',
            vendorId: booking.vendorId
        })

        const index = bookings.findIndex(b => b.id === booking.id);

        if (index !== -1) {
            const newBookings = [...bookings];
            newBookings.splice(index, 1);

            setBookings(newBookings);
        }
    }

    return (<>

        <Typography variant="h5" gutterBottom>
            Upcoming bookings
        </Typography>
        <Box
            sx={{ display: "flex", flexWrap: "wrap", gap: 2, marginTop: 0 }}
        >
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="user bookings">
                    <TableHead>
                        <TableCell>From</TableCell>
                        <TableCell>To</TableCell>
                        <TableCell>Pet</TableCell>
                        <TableCell>Vendor</TableCell>
                        <TableCell>Service</TableCell>
                        <TableCell></TableCell>
                    </TableHead>
                    <TableBody>
                    {bookings.map(booking => (
                        <TableRow key={booking.id}>
                            <TableCell>{dayjs(booking.startDateTime).format('YYYY-MM-DD HH:mm')}</TableCell>
                            <TableCell>{dayjs(booking.endDateTime).format('YYYY-MM-DD HH:mm')}</TableCell>
                            <TableCell>{booking.petName}</TableCell>
                            <TableCell>{booking.vendorName}</TableCell>
                            <TableCell>{booking.offeringName}</TableCell>
                            <TableCell>
                                <IconButton aria-label="delete" color="primary" onClick={() => onDelete(booking) }>
                                    <DeleteIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    </>);
}