import { useEffect, useState } from "react";
import { useBookingFormStateContext } from "./BookOfferingState";
import { booking, offering } from "@anything-pet/grpc-lib";
import { Box, Card, CardActionArea, CardContent, Grid, Skeleton, Typography } from "@mui/material";
import dayjs from "dayjs";
import { getBookingServiceProxy } from "../services/BookingServiceProxy";
import { useSocketIO } from "../components/SocketIO";
import { useVendorScope } from "../components/VendorScopeProvider";
import { AvailableSlot } from "@anything-pet/grpc-lib/dist/proto/booking";
import { BookingFormSelectStaff } from "./BookingFormSelectStaff";


export function BookingFormSelectSlot(
    props: { offering: offering.Offering }
) {
    const timeFormat = "HH:mm";
    
    const socket = useSocketIO();
    const { id: vendorId } = useVendorScope();
    const context = useBookingFormStateContext();
    
    const [availableSlots, setAvailableSlots] =
        useState<booking.AvailableSlot[]>();
    const [selectedSlot, setSelectedSlot] = useState<string | null>(null);
    
    useEffect(() => {
        (async () => {
            if (context.state.bookingDate) {
                const selectedDayjs = context.state.bookingDate;
                const fromDateTime = selectedDayjs.startOf("day");
                const endDateTime = selectedDayjs.endOf("day");

                const service = getBookingServiceProxy(socket);

                const { slot: slots } = await service.getAvailableSlots({
                    vendorId,
                    offeringId: context.offeringId,
                    startDateTime: fromDateTime.toISOString(),
                    endDateTime: endDateTime.toISOString(),
                });

                setAvailableSlots(slots);
            }
        })();
    }, [context.state.bookingDate, socket, vendorId, context.offeringId]);

    if (! availableSlots || availableSlots.length === 0) {
        return <Skeleton variant="rectangular" height={300} />;
    }

    if (! context.state.bookingDate) {
        context.navigateToState();
    } else {
        if (context.state.bookingTime) {
            const bookingTimeDayJs = dayjs(context.state.bookingTime, 'HH:mm');
            
            const slotDateTime = context.state.bookingDate
                .add(bookingTimeDayJs.hour(), 'hour')
                .add(bookingTimeDayJs.minute(), 'minute');
            
            const available = availableSlots.find(slot => dayjs(slot.fromDateTime).diff(slotDateTime) === 0);

            if (available) {        
                //  Go to next step
                return <BookingFormSelectStaff offering={props.offering} slot={available} />;
            } else {
                //  Time is invalid.  Fix the url
                context.state.bookingTime = undefined;
                context.navigateToState();
            }
        }
    }

    const onSlotSelection = (newSlot : AvailableSlot) => {
        context.state.bookingTime = dayjs(newSlot.fromDateTime).format(timeFormat);
        context.navigateToState();
    }

    return (
        <div>
        <Typography
            variant="h5"
            paddingTop="20px"
            paddingBottom="10px"
            align="center"
        >
            Please select a time slot
        </Typography>
        <Box display="flex" justifyContent="center" mb={2}>
            <Grid container spacing={2} justifyContent="center">
                {availableSlots?.map((slot) => {
                    const text = `${dayjs(slot.fromDateTime).format(
                        timeFormat
                    )} - ${dayjs(slot.toDateTime).format(
                        timeFormat
                    )}`;
                    return (
                        <Grid item key={text}>
                            <Card
                                variant={
                                    selectedSlot === text
                                        ? "outlined"
                                        : "elevation"
                                }
                                sx={{
                                    width: 200,
                                    cursor: "pointer",
                                    backgroundColor:
                                        selectedSlot === text
                                            ? "secondary.light"
                                            : "background.paper",
                                    "&:hover": {
                                        backgroundColor:
                                            "primary.main",
                                    },
                                }}
                                onClick={() =>
                                    onSlotSelection(slot)
                                }
                            >
                                <CardActionArea>
                                    <CardContent>
                                        <Typography
                                            variant="h6"
                                            align="center"
                                            sx={{
                                                fontWeight: "bold",
                                                color:
                                                    selectedSlot ===
                                                    text
                                                        ? "secondary.contrastText"
                                                        : "text.primary",
                                            }}
                                        >
                                            {text}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    );
                })}
            </Grid>
        </Box>
    </div>
    )
}