import { Route, Routes } from "react-router-dom";
import { VendorScopeProvider } from "../components/VendorScopeProvider";
import { BookingForm } from "../components/BookingForm";
import { SocketIOProvider } from "../components/SocketIO";


export function VendorHomePage() {
    return (
        <SocketIOProvider>
            <VendorScopeProvider>
                <Routes>
                    <Route path=":offeringId/:date?/:time?/:staffId?/:petId?" element={<BookingForm />} />
                </Routes>
            </VendorScopeProvider>
        </SocketIOProvider>
    );
}